import { FieldPath, FieldValues, FormState } from 'react-hook-form'

export function fieldError<TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>(
  formState: FormState<TFieldValues>,
  name: TName
) {
  const error = formState.errors[name]

  return {
    error: error != null,
    helperText: error?.message
  }
}
