import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useSuspenseQuery } from '@tanstack/react-query'
import axios from 'axios'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { CompanyInterface } from '../../rescouted-interfaces/src/interfaces/company.interface'
import { Controller, useForm } from 'react-hook-form'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Box, Button, Container, Grid, makeStyles, TextField, Theme } from '@material-ui/core'
import { RTEditor } from '../RTEditor/RTEditor.component'
import { toast } from 'react-toastify'
import { fieldError } from '../../utils/form'
import { Video } from 'react-feather'
import { VideoField } from '../../components/Form/VideoField'
import { ImageField } from '../../components/Form/ImageField'

export const CompanyPage = () => {
  const { companyId } = useParams<{ companyId?: string }>()
  const history = useHistory()
  const editMode = companyId != null

  const { data, refetch } = useSuspenseQuery({
    queryKey: ['company', companyId],
    queryFn: async () => {
      if (companyId == null) {
        return null
      }

      const response = await axios.get(`/my/companies/${companyId}`)
      return response.data
    }
  })

  const onSubmit = async (data: CompanyFormData) => {
    const requestUrl = companyId != null ? `/my/companies/${companyId}/update` : '/my/companies/create'
    try {
      const response = await axios.post(requestUrl, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })

      if (response.status === 200) {
        toast.success(editMode ? 'Company updated successfully.' : 'Company created successfully.')
        refetch()
        history.push('/')
      }
    } catch (error) {
      toast.error(editMode ? 'Failed to update company. Please try again.' : 'Failed to create company. Please try again.')
    }
  }

  return (
    <PerfectScrollbar>
      <Container maxWidth="md">
        <Box my={4}>
          <CompanyForm company={data} onSubmit={onSubmit} />
        </Box>
      </Container>
    </PerfectScrollbar>
  )
}

const useFormStyles = makeStyles((theme: Theme) => ({
  title: {
    fontWeight: 700,
    '& .MuiFormLabel-root:not(.MuiFormLabel-shrink)': {
      fontWeight: 700
    }
  },
  inputGroup: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1)
  }
}))

type CompanyFormData = Pick<CompanyInterface, 'name' | 'web' | 'logo' | 'shareImage' | 'video' | 'headline' | 'description' | 'language'>

interface CompanyFormProps {
  company?: CompanyInterface
  onSubmit: (data: CompanyFormData) => Promise<void>
}

export const CompanyForm = (props: CompanyFormProps) => {
  const classes = useFormStyles()
  const form = useForm<CompanyFormData>({
    defaultValues: {
      description: '',
      name: '',
      web: '',
      logo: '',
      headline: '',
      shareImage: '',
      video: '',
      language: 'en',
      ...props.company
    },
    resolver: zodResolver(
      z.object({
        name: z.string().min(2, { message: 'Company name must be at least 2 characters long' }),
        web: z.string().url({ message: 'Invalid URL' }),
        logo: z.string().url({ message: 'Invalid URL' }).optional().or(z.literal('')),
        shareImage: z.string().url({ message: 'Invalid URL' }).optional().or(z.literal('')),
        video: z.string().url({ message: 'Invalid URL' }).optional().or(z.literal('')),
        headline: z.string().optional(),
        description: z.string().optional(),
        language: z.string()
      })
    )
  })

  return (
    <form onSubmit={form.handleSubmit((data) => props.onSubmit(data))}>
      <Box display="flex" justifyContent="flex-end" mb={4}>
        <Button type="submit" variant="contained" color="primary">
          Publish
        </Button>
      </Box>
      <Grid container>
        <Grid item sm={12}>
          <div className={classes.inputGroup}>
            <TextField
              name="company"
              fullWidth
              label="Company Name"
              className={classes.title}
              {...form.register('name')}
              {...fieldError(form.formState, 'name')}
            />
          </div>
          <div className={classes.inputGroup}>
            <TextField
              name="website"
              fullWidth
              label="Company Website"
              placeholder="https://example.com"
              {...form.register('web')}
              {...fieldError(form.formState, 'web')}
            />
          </div>
        </Grid>
        <Box my={3} display="flex" />

        {props.company == null ? null : (
          <Grid container>
            <Grid item sm={12}>
              <Controller
                control={form.control}
                name="video"
                render={({ field }) => (
                  <VideoField companyId={props.company?._id} value={field.value} onChange={field.onChange} label="Company Video" />
                )}
              />
            </Grid>
            <Grid item sm={12}>
              <Controller
                control={form.control}
                name="logo"
                render={({ field }) => (
                  <ImageField companyId={props.company?._id} value={field.value} onChange={field.onChange} label="Company Logo Image" />
                )}
              />
            </Grid>
            <Grid item sm={12}>
              <Controller
                control={form.control}
                name="shareImage"
                render={({ field }) => (
                  <ImageField
                    companyId={props.company?._id}
                    value={field.value}
                    onChange={field.onChange}
                    label="Picture that is visible when link is shared (on Facebook for example)"
                  />
                )}
              />
            </Grid>
          </Grid>
        )}

        <Box className={classes.inputGroup}>
          <TextField name="headline" fullWidth label="Company Headline" {...form.register('headline')} {...fieldError(form.formState, 'headline')} />
        </Box>
        <div className={classes.inputGroup}>
          <Controller
            name="description"
            control={form.control}
            render={({ field }) => (
              <RTEditor
                keyId={`rt-${props.company?._id}`}
                value={field.value}
                onChange={(value) => field.onChange(value)}
                label="Company Description"
              />
            )}
          />
        </div>
      </Grid>
    </form>
  )
}
