const seniorityLevel = [
  { key: 'entry', title: 'Entry' },
  { key: 'junior', title: 'Junior' },
  { key: 'mid-level', title: 'Mid-level' },
  { key: 'senior', title: 'Senior' },
  { key: 'lead', title: 'Lead' },
  { key: 'executive', title: 'Executive' },
];

const revenue = [
  { key: 'lt1m', title: 'Below 1M' },
  { key: '1m-3m', title: '1M to 3M' },
  { key: '3m-5m', title: '3M to 5M' },
  { key: '5m-10m', title: '5M to 10M' },
  { key: '10m-50m', title: '10M to 50M' },
  { key: '50m-100m', title: '50M to 100M' },
  { key: '100m-500m', title: '100M to 500M' },
  { key: 'gt500m', title: '500M+' },
];

const companySize = [
  { key: '1', title: 'Self-employed' },
  { key: '2-10', title: '2 - 10' },
  { key: '11-50', title: '11 - 50' },
  { key: '51-200', title: '51 - 200' },
  { key: '201-500', title: '201 - 500' },
  { key: '501-1000', title: '501 - 1000' },
  { key: 'gt1000', title: '1000+' },
];

const languages = [
  { key: 'abaza', title: 'Abaza' },
  { key: 'abellen', title: 'Abellen' },
  { key: 'adyghe', title: 'Adyghe' },
  { key: 'afar', title: 'Afar' },
  { key: 'ainu', title: 'Ainu' },
  { key: 'ajie', title: 'Ajië' },
  { key: 'akan', title: 'Akan' },
  { key: 'albanian', title: 'Albanian' },
  { key: 'aleut', title: 'Aleut' },
  { key: 'amdang', title: 'Amdang' },
  { key: 'ancient-egyptian-language', title: 'Ancient Egyptian Language' },
  { key: 'aragonese', title: 'Aragonese' },
  { key: 'arapaho', title: 'Arapaho' },
  { key: 'arrernte', title: 'Arrernte' },
  { key: 'asturian', title: 'Asturian' },
  { key: 'avar', title: 'Avar' },
  { key: 'aynu', title: 'Äynu' },
  { key: 'azerbaijani', title: 'Azerbaijani' },
  { key: 'baga', title: 'Baga' },
  { key: 'bai', title: 'Bai' },
  { key: 'balinese', title: 'Balinese' },
  { key: 'balti', title: 'Balti' },
  { key: 'bambara', title: 'Bambara' },
  { key: 'bantu', title: 'Bantu' },
  { key: 'basa-gumna', title: 'Basa-Gumna' },
  { key: 'bashkir', title: 'Bashkir' },
  { key: 'basque', title: 'Basque' },
  { key: 'bassa', title: 'Bassa' },
  { key: 'batak', title: 'Batak' },
  { key: 'belarusian', title: 'Belarusian' },
  { key: 'bemba', title: 'Bemba' },
  { key: 'bengali', title: 'Bengali' },
  { key: 'berber', title: 'Berber' },
  { key: 'berta', title: 'Berta' },
  { key: 'bhojpuri', title: 'Bhojpuri' },
  { key: 'bislama', title: 'Bislama' },
  { key: 'blackfoot', title: 'Blackfoot' },
  { key: 'bodo', title: 'Bodo' },
  { key: 'bosnian', title: 'Bosnian' },
  { key: 'breton', title: 'Breton' },
  { key: 'buginese', title: 'Buginese' },
  { key: 'buhid', title: 'Buhid' },
  { key: 'bulgarian', title: 'Bulgarian' },
  { key: 'burmese', title: 'Burmese' },
  { key: 'cahuilla', title: 'Cahuilla' },
  { key: 'cantonese', title: 'Cantonese' },
  { key: 'carrier', title: 'Carrier' },
  { key: 'catalan', title: 'Catalan' },
  { key: 'cayuga', title: 'Cayuga' },
  { key: 'cebuano', title: 'Cebuano' },
  { key: 'chamorro', title: 'Chamorro' },
  { key: 'chechen', title: 'Chechen' },
  { key: 'cherokee', title: 'Cherokee' },
  { key: 'chewa', title: 'Chewa' },
  { key: 'cheyenne', title: 'Cheyenne' },
  { key: 'chhattisgarhi', title: 'Chhattisgarhi' },
  { key: 'chickasaw', title: 'Chickasaw' },
  { key: 'chinese', title: 'Chinese' },
  { key: 'chipewyan', title: 'Chipewyan' },
  { key: 'choctaw', title: 'Choctaw' },
  { key: 'chuvash', title: 'Chuvash' },
  { key: 'cimbrian', title: 'Cimbrian' },
  { key: 'comanche', title: 'Comanche' },
  { key: 'coptic', title: 'Coptic' },
  { key: 'cornish', title: 'Cornish' },
  { key: 'corsican', title: 'Corsican' },
  { key: 'cree', title: 'Cree' },
  { key: 'croatian', title: 'Croatian' },
  { key: 'cupeno', title: 'Cupeño' },
  { key: 'czech', title: 'Czech' },
  { key: 'dakhini', title: 'Dakhini' },
  { key: 'dakota', title: 'Dakota' },
  { key: 'dalecarlian', title: 'Dalecarlian' },
  { key: 'danish', title: 'Danish' },
  { key: 'dargwa', title: 'Dargwa' },
  { key: 'drehu', title: 'Drehu' },
  { key: 'dungan', title: 'Dungan' },
  { key: 'dutch', title: 'Dutch' },
  { key: 'egyptian-arabic', title: 'Egyptian Arabic' },
  { key: 'english', title: 'English' },
  { key: 'erzya', title: 'Erzya' },
  { key: 'estonian', title: 'Estonian' },
  { key: 'evenki', title: 'Evenki' },
  { key: 'ewe', title: 'Ewe' },
  { key: 'eyak', title: 'Eyak' },
  { key: 'faroese', title: 'Faroese' },
  { key: 'fijian', title: 'Fijian' },
  { key: 'filipino', title: 'Filipino' },
  { key: 'finnish', title: 'Finnish' },
  { key: 'fon', title: 'Fon' },
  { key: 'french', title: 'French' },
  { key: 'frisian-north', title: 'Frisian (North)' },
  { key: 'frisian-west', title: 'Frisian (West)' },
  { key: 'friulian', title: 'Friulian' },
  { key: 'fula', title: 'Fula' },
  { key: 'fur', title: 'Fur' },
  { key: 'ga', title: 'Ga' },
  { key: 'galician', title: 'Galician' },
  { key: 'gan', title: 'Gan' },
  { key: 'georgian', title: 'Georgian' },
  { key: 'german', title: 'German' },
  { key: 'gilbertese', title: 'Gilbertese' },
  { key: 'godoberi', title: 'Godoberi' },
  { key: 'gothic', title: 'Gothic' },
  { key: 'greek', title: 'Greek' },
  { key: 'greenlandic', title: 'Greenlandic' },
  { key: 'gujarati', title: 'Gujarati' },
  { key: 'gumuz', title: 'Gumuz' },
  { key: 'gurung', title: 'Gurung' },
  { key: 'haida', title: 'Haida' },
  { key: 'hainanese', title: 'Hainanese' },
  { key: 'hakka', title: 'Hakka' },
  { key: 'harari', title: 'Harari' },
  { key: 'hausa', title: 'Hausa' },
  { key: 'hawaiian', title: 'Hawaiian' },
  { key: 'hebrew', title: 'Hebrew' },
  { key: 'herero', title: 'Herero' },
  { key: 'himba', title: 'Himba' },
  { key: 'hindustani', title: 'Hindustani' },
  { key: 'hmong', title: 'Hmong' },
  { key: 'hopi', title: 'Hopi' },
  { key: 'hungarian', title: 'Hungarian' },
  { key: 'icelandic', title: 'Icelandic' },
  { key: 'ido', title: 'Ido' },
  { key: 'igbo', title: 'Igbo' },
  { key: 'ik', title: 'Ik' },
  { key: 'ilocano', title: 'Ilocano' },
  { key: 'indonesian', title: 'Indonesian' },
  { key: 'ingush', title: 'Ingush' },
  { key: 'inuktitut', title: 'Inuktitut' },
  { key: 'irish', title: 'Irish' },
  { key: 'isthmus-nahuatl', title: 'Isthmus Nahuatl' },
  { key: 'italian', title: 'Italian' },
  { key: 'japanese', title: 'Japanese' },
  { key: 'javanese', title: 'Javanese' },
  { key: 'jerriais', title: 'Jèrriais' },
  { key: 'kabardian', title: 'Kabardian' },
  { key: 'kabyle', title: 'Kabyle' },
  { key: 'kaingang', title: 'Kaingang' },
  { key: 'kanuri', title: 'Kanuri' },
  { key: 'karakalpak', title: 'Karakalpak' },
  { key: 'karamojong', title: 'Karamojong' },
  { key: 'karelian', title: 'Karelian' },
  { key: 'kashmiri', title: 'Kashmiri' },
  { key: 'kashubian', title: 'Kashubian' },
  { key: 'kazakh', title: 'Kazakh' },
  { key: 'kendeje', title: 'Kendeje' },
  { key: 'khakas', title: 'Khakas' },
  { key: 'khmer', title: 'Khmer' },
  { key: 'khoekhoe', title: 'Khoekhoe' },
  { key: 'khowar', title: 'Khowar' },
  { key: 'kinyarwanda', title: 'Kinyarwanda' },
  { key: 'konkani', title: 'Konkani' },
  { key: 'korean', title: 'Korean' },
  { key: 'kuliak', title: 'Kuliak' },
  { key: 'kumyk', title: 'Kumyk' },
  { key: 'kurdish-southern', title: 'Kurdish (Southern)' },
  { key: 'kutchi', title: 'Kutchi' },
  { key: 'kwadi', title: 'Kwadi' },
  { key: 'ladakhi', title: 'Ladakhi' },
  { key: 'ladin', title: 'Ladin' },
  { key: 'lakota', title: 'Lakota' },
  { key: 'lao', title: 'Lao' },
  { key: 'latin', title: 'Latin' },
  { key: 'latvian', title: 'Latvian' },
  { key: 'laz', title: 'Laz' },
  { key: 'lepcha', title: 'Lepcha' },
  { key: 'limbu', title: 'Limbu' },
  { key: 'lisu', title: 'Lisu' },
  { key: 'lithuanian', title: 'Lithuanian' },
  { key: 'livonian', title: 'Livonian' },
  { key: 'luba-kasai', title: 'Luba-Kasai' },
  { key: 'luiseno', title: 'Luiseño' },
  { key: 'luri', title: 'Luri' },
  { key: 'maasai', title: 'Maasai' },
  { key: 'macedonian', title: 'Macedonian' },
  { key: 'magahi', title: 'Magahi' },
  { key: 'magar', title: 'Magar' },
  { key: 'maithili', title: 'Maithili' },
  { key: 'makassarese', title: 'Makassarese' },
  { key: 'malay', title: 'Malay' },
  { key: 'maltese', title: 'Maltese' },
  { key: 'manchu', title: 'Manchu' },
  { key: 'mandarin', title: 'Mandarin' },
  { key: 'manx', title: 'Manx' },
  { key: 'marathi', title: 'Marathi' },
  { key: 'marshallese', title: 'Marshallese' },
  { key: 'masalit', title: 'Masalit' },
  { key: 'meitei', title: 'Meitei' },
  { key: 'miami-illinois', title: 'Miami-Illinois' },
  { key: 'michoacan-nahuatl', title: 'Michoacán Nahuatl' },
  { key: 'min', title: 'Min' },
  { key: 'mon', title: 'Mon' },
  { key: 'mongolian', title: 'Mongolian' },
  { key: 'montenegrin', title: 'Montenegrin' },
  { key: 'musgu', title: 'Musgu' },
  { key: 'muskum', title: 'Muskum' },
  { key: 'mori', title: 'Māori' },
  { key: 'nagumi', title: 'Nagumi' },
  { key: 'navajo', title: 'Navajo' },
  { key: 'ndyuka', title: 'Ndyuka' },
  { key: 'nepali', title: 'Nepali' },
  { key: 'ngbee', title: 'Ngbee' },
  { key: 'northern-thai', title: 'Northern Thai' },
  { key: 'norwegian', title: 'Norwegian' },
  { key: 'nuosu', title: 'Nuosu' },
  { key: 'occitan', title: 'Occitan' },
  { key: 'ojibwe', title: 'Ojibwe' },
  { key: 'okinawan-japanese', title: 'Okinawan Japanese' },
  { key: 'old-church-slavonic', title: 'Old Church Slavonic' },
  { key: 'omotik', title: 'Omotik' },
  { key: 'odia', title: 'Odia' },
  { key: 'palula', title: 'Palula' },
  { key: 'pashto', title: 'Pashto' },
  { key: 'persian', title: 'Persian' },
  { key: 'phoenician', title: 'Phoenician' },
  { key: 'piman', title: 'Piman' },
  { key: 'plautdietsch', title: 'Plautdietsch' },
  { key: 'polish', title: 'Polish' },
  { key: 'portuguese', title: 'Portuguese' },
  { key: 'punjabi-language', title: 'Punjabi language' },
  { key: 'pu-xian-min', title: 'Pu-Xian Min' },
  { key: 'raga', title: 'Raga' },
  { key: 'rajasthani', title: 'Rajasthani' },
  { key: 'rohingya', title: 'Rohingya' },
  { key: 'romani', title: 'Romani' },
  { key: 'romanian', title: 'Romanian' },
  { key: 'romansh', title: 'Romansh' },
  { key: 'russian', title: 'Russian' },
  { key: 'rusyn', title: 'Rusyn' },
  { key: 'ruthenian', title: 'Ruthenian' },
  { key: 'sami-inari', title: 'Sami (Inari)' },
  { key: 'sami-kildin', title: 'Sami (Kildin)' },
  { key: 'sami-lule', title: 'Sami (Lule)' },
  { key: 'sami-pite', title: 'Sami (Pite)' },
  { key: 'sami-skolt', title: 'Sami (Skolt)' },
  { key: 'sami-southern', title: 'Sami (Southern)' },
  { key: 'sami-ume', title: 'Sami (Ume)' },
  { key: 'samoan', title: 'Samoan' },
  { key: 'sardinian', title: 'Sardinian' },
  { key: 'saurashtra', title: 'Saurashtra' },
  { key: 'scots', title: 'Scots' },
  { key: 'scottish-gaelic', title: 'Scottish Gaelic' },
  { key: 'serbian', title: 'Serbian' },
  { key: 'serer', title: 'Serer' },
  { key: 'shan', title: 'Shan' },
  { key: 'sherpa-language', title: 'Sherpa language' },
  { key: 'shona', title: 'Shona' },
  { key: 'sicilian', title: 'Sicilian' },
  { key: 'sikkimese', title: 'Sikkimese' },
  { key: 'sindhi', title: 'Sindhi' },
  { key: 'sinhala', title: 'Sinhala' },
  { key: 'slovak', title: 'Slovak' },
  { key: 'slovene', title: 'Slovene' },
  { key: 'somali', title: 'Somali' },
  { key: 'sotho', title: 'Sotho' },
  { key: 'spanish', title: 'Spanish' },
  { key: 'sundanese', title: 'Sundanese' },
  { key: 'swahili', title: 'Swahili' },
  { key: 'swedish', title: 'Swedish' },
  { key: 'sylheti', title: 'Sylheti' },
  { key: 'tagalog', title: 'Tagalog' },
  { key: 'tahitian', title: 'Tahitian' },
  { key: 'tai-dam', title: 'Tai Dam' },
  { key: 'tai-khun', title: 'Tai Khün' },
  { key: 'tamang', title: 'Tamang' },
  { key: 'tamil', title: 'Tamil' },
  { key: 'tangut', title: 'Tangut' },
  { key: 'tatar', title: 'Tatar' },
  { key: 'telugu', title: 'Telugu' },
  { key: 'teribe', title: 'Teribe' },
  { key: 'tetum', title: 'Tetum' },
  { key: 'thai', title: 'Thai' },
  { key: 'tibetan', title: 'Tibetan' },
  { key: 'tigrinya', title: 'Tigrinya' },
  { key: 'tok-pisin', title: 'Tok Pisin' },
  { key: 'tongan', title: 'Tongan' },
  { key: 'tongva', title: 'Tongva' },
  { key: 'tsez', title: 'Tsez' },
  { key: 'tswana', title: 'Tswana' },
  { key: 'tunica', title: 'Tunica' },
  { key: 'tulu-language', title: 'Tulu language' },
  { key: 'uduk', title: 'Uduk' },
  { key: 'ukrainian', title: 'Ukrainian' },
  { key: 'uyghur', title: 'Uyghur' },
  { key: 'uzbek', title: 'Uzbek' },
  { key: 'valencian', title: 'Valencian' },
  { key: 'venda', title: 'Venda' },
  { key: 'venetian', title: 'Venetian' },
  { key: 'vietnamese', title: 'Vietnamese' },
  { key: 'vro', title: 'Võro' },
  { key: 'walloon', title: 'Walloon' },
  { key: 'warlpiri', title: 'Warlpiri' },
  { key: 'welsh', title: 'Welsh' },
  { key: 'west-frisian', title: 'West Frisian' },
  { key: 'wolof', title: 'Wolof' },
  { key: 'wu', title: 'Wu' },
  { key: 'xhosa', title: 'Xhosa' },
  { key: 'xiang', title: 'Xiang' },
  { key: 'xibe', title: 'Xibe' },
  { key: 'yapese', title: 'Yapese' },
  { key: 'yoruba', title: 'Yoruba' },
  { key: 'yue', title: 'Yue' },
  { key: 'zulu', title: 'Zulu' },
  { key: 'abkhaz', title: 'Abkhaz' },
  { key: 'anglo-saxon', title: 'Anglo-Saxon' },
  { key: 'avestan', title: 'Avestan' },
  { key: 'bopomofo', title: 'Bopomofo' },
  { key: 'braille', title: 'Braille' },
  { key: 'carian', title: 'Carian' },
  { key: 'deseret', title: 'Deseret' },
  { key: 'kayah-li', title: 'Kayah Li' },
  { key: 'kulitan', title: 'Kulitan' },
  { key: 'runic', title: 'Runic' },
  { key: 'shavian', title: 'Shavian' },
  { key: 'amoy', title: 'Amoy' },
  { key: 'fuzhou', title: 'Fuzhou' },
  { key: 'hazrag', title: 'Hazāragī' },
  { key: 'kwanyama', title: 'Kwanyama' },
  { key: 'hokkien', title: 'Hokkien' },
  { key: 'meankieli', title: 'Meänkieli' },
  { key: 'monegasque', title: 'Monégasque' },
  { key: 'ningbo', title: 'Ningbo' },
  { key: 'quanzhou', title: 'Quanzhou' },
  { key: 'saraiki', title: 'Saraiki' },
  { key: 'teochew', title: 'Teochew' },
];

export const programmingTools = [
  { key: 'angular', title: 'Angular', group: 'JavaScript' },
  { key: 'ember', title: 'Ember', group: 'JavaScript' },
  { key: 'react-native', title: 'React Native', group: 'JavaScript' },
  { key: 'jquery', title: 'jQuery', group: 'JavaScript' },
  { key: 'node-js', title: 'Node.js', group: 'JavaScript' },
  { key: 'react', title: 'React', group: 'JavaScript' },
  { key: 'typescript', title: 'TypeScript', group: 'JavaScript' },
  { key: 'aurelia', title: 'Aurelia', group: 'JavaScript' },
  { key: 'ionic', title: 'Ionic', group: 'JavaScript' },
  { key: 'nativescript', title: 'NativeScript', group: 'JavaScript' },
  { key: 'cordova', title: 'Cordova', group: 'JavaScript' },
  {
    key: 'appcelerator Titanium',
    title: 'Appcelerator Titanium',
    group: 'JavaScript',
  },
  { key: 'sencha-touch', title: 'Sencha Touch', group: 'JavaScript' },
  { key: 'vue', title: 'Vue', group: 'JavaScript' },
  { key: 'backbone', title: 'Backbone', group: 'JavaScript' },
  { key: 'knockout-js', title: 'Knockout.js', group: 'JavaScript' },
  { key: 'express', title: 'Express', group: 'JavaScript' },
  { key: 'meteor', title: 'Meteor', group: 'JavaScript' },
  { key: 'nest-js', title: 'Nest.js', group: 'JavaScript' },

  { key: 'django', title: 'Django', group: 'Python' },
  { key: 'cubicweb', title: 'CubicWeb', group: 'Python' },
  { key: 'web2py', title: 'Web2py', group: 'Python' },
  { key: 'pyramid', title: 'Pyramid', group: 'Python' },

  {
    key: 'android-native-development',
    title: 'Android Native Development',
    group: 'Java',
  },
  { key: 'ply', title: 'Ply', group: 'Java' },
  { key: 'spring', title: 'Spring', group: 'Java' },
  { key: 'hibernate', title: 'Hibernate', group: 'Java' },
  { key: 'gwt', title: 'GWT', group: 'Java' },
  { key: 'jsf', title: 'JSF', group: 'Java' },
  { key: 'struts', title: 'Struts', group: 'Java' },
  { key: 'vaadin', title: 'Vaadin', group: 'Java' },

  { key: 'sql', title: 'SQL' },
  { key: 'nosql', title: 'NoSQL' },
  { key: 'mongodb', title: 'MongoDB' },
  { key: 'elasticsearch', title: 'ElasticSearch' },
  { key: 'apache-hadoop', title: 'Apache Hadoop' },
  { key: 'apache-spark', title: 'Apache Spark' },
  { key: 'c-c', title: 'C / C++' },
  { key: 'c', title: 'C#' },
  { key: 'mysql', title: 'MySQL' },
  { key: 'postgresql', title: 'PostgreSQL' },
  { key: 'sap', title: 'SAP' },
  { key: 'oracle', title: 'Oracle' },
  { key: 'redis', title: 'Redis' },
  { key: 'rest-api', title: 'REST API' },
  { key: 'ruby', title: 'Ruby' },
  { key: 'scala', title: 'Scala' },
  { key: 'html-css', title: 'HTML & CSS' },
  { key: 'bash', title: 'Bash' },
  { key: 'matlab', title: 'MATLAB' },
  { key: 'amazon-aws', title: 'Amazon AWS' },
  { key: 'google-cloud', title: 'Google Cloud' },
  { key: 'azure', title: 'Azure' },
  { key: 'openstack', title: 'OpenStack' },
  { key: 'r', title: 'R' },
  { key: 'docker', title: 'Docker' },
  { key: 'linux', title: 'Linux' },
  { key: 'windows', title: 'Windows' },
  { key: 'visual-basic-net', title: 'Visual Basic .NET' },
  { key: 'couchbase', title: 'Couchbase' },
  { key: 'google-apis', title: 'Google APIs' },
  { key: 'ansible', title: 'Ansible' },
  { key: 'chef', title: 'Chef' },
  { key: 'puppet', title: 'Puppet' },
  { key: 'vagrant', title: 'Vagrant' },
  { key: 'objective-c', title: 'Objective-C' },
  { key: 'jenkins', title: 'Jenkins' },
  { key: 'tensorflow', title: 'Tensorflow' },
  { key: 'php', title: 'PHP' },
  { key: 'laravel', title: 'Laravel' },
  { key: 'chatgpt', title: 'ChatGPT' },
  { key: 'openai', title: 'OpenAI' },
  { key: 'python', title: 'Python' },
  { key: 'swift', title: 'Swift' },
  { key: 'groovy', title: 'Groovy' },
  { key: 'kotlin', title: 'Kotlin' },
  { key: 'selenium', title: 'Selenium' },
  { key: 'cassandra', title: 'Cassandra' },
  { key: 'riak', title: 'Riak' },
  { key: 'neo4j', title: 'Neo4j' },
  { key: 'hive', title: 'Hive' },
  { key: 'sqoop', title: 'Sqoop' },
  { key: 'perl', title: 'Perl' },
  { key: 'sas', title: 'SAS' },
  { key: 'lua', title: 'Lua' },
  { key: 'firebase', title: 'Firebase' },
  { key: 'llm', title: 'LLM' },
  { key: 'apache-solr', title: 'Apache Solr' },
  { key: 'julia', title: 'Julia' },
  { key: 'kubernetes', title: 'Kubernetes' },
  { key: 'powershell', title: 'Powershell' },
  { key: 'microsoft-dmtk', title: 'Microsoft DMTK' },
  { key: 'bigml', title: 'BigML' },
  { key: 'singa', title: 'Singa' },
  { key: 'veles', title: 'Veles' },
  { key: 'h2o', title: 'H2O' },
  { key: 'torch', title: 'Torch' },
  { key: 'amazon-ml', title: 'Amazon ML' },
  { key: 'caffe', title: 'Caffe' },
  { key: 'f', title: 'F#' },
  { key: 'clojure', title: 'Clojure' },
  { key: 'lisp', title: 'Lisp' },
  { key: 'dart', title: 'Dart' },
  { key: 'haskell', title: 'Haskell' },
  { key: 'd', title: 'D' },
  { key: 'graph-ql', title: 'Graph QL' },
  { key: 'elixir', title: 'Elixir' },
  { key: 'go', title: 'Go' },
  { key: 'rust', title: 'Rust' },
  { key: 'libgdx', title: 'Libgdx' },
  { key: 'cocos2d', title: 'Cocos2d' },
  { key: 'blender', title: 'Blender' },
  { key: 'construct', title: 'Construct' },
  { key: 'cryengine', title: 'CryEngine' },
  { key: 'heroengine', title: 'HeroEngine' },
  { key: 'erlang', title: 'Erlang' },
  { key: 'unity', title: 'Unity' },
  { key: 'unreal-engine', title: 'Unreal Engine' },
  { key: 'gamemaker', title: 'GameMaker' },
  { key: 'ocaml', title: 'OCaml' },
  { key: 'abap', title: 'ABAP' },
  { key: 'openedge-abl', title: 'OpenEdge ABL' },
  { key: 'sharepoint', title: 'Sharepoint' },
  { key: 'bash', title: 'Bash' },
];

const designTools = [
  { key: 'other', title: 'Other' },
  programmingTools.filter((e) => e.key === 'javascript')[0],
  { key: 'html-css', title: 'HTML & CSS' },
  { key: 'illustrator', title: 'Illustrator' },
  { key: 'indesign', title: 'InDesign' },
  { key: 'photoshop', title: 'Photoshop' },
  { key: 'sketch', title: 'Sketch' },
  { key: 'figma', title: 'Figma' },
  { key: 'framer', title: 'Framer' },
  { key: 'invision', title: 'InVision' },
  { key: 'marvel', title: 'Marvel' },
  { key: 'after-effects', title: 'After Effects' },
  { key: 'audition', title: 'Audition' },
  { key: 'premiere', title: 'Premiere' },
];

export const institutionTypes = [
  { key: 'banking', title: 'Banking' },
  { key: 'consultancy', title: 'Consultancy' },
  { key: 'corporate', title: 'Corporate' },
  { key: 'financial-institution', title: 'Financial Institution' },
  { key: 'investment-fund', title: 'Investment Fund' },
  { key: 'manufacturing', title: 'Manufacturing' },
  { key: 'private-equity', title: 'Private Equity' },
  { key: 'sme', title: 'SME' },
  { key: 'start-up-or-scale-up', title: 'Start-up / Scale-up' },
];

export const businessAreas = [
  { key: 'b2b', title: 'B2B' },
  { key: 'b2c', title: 'B2C' },
];

const averageDealValue = [
  { key: '0-100', title: '0 - 100€' },
  { key: '100-500', title: '100 - 500€' },
  { key: '500-1000', title: '500 - 1000€' },
  { key: '1000-5000', title: '1000 - 5000€' },
  { key: '5000-10000', title: '5000 - 10000€' },
  { key: '10000-50000', title: '10000 - 50000€' },
  { key: '50000-250000', title: '50000 - 250000€' },
  { key: '250000€-1m', title: '250000€ - 1M' },
  { key: 'gt1m', title: '1M+' },
];

export const industries = [
  { key: 'ar-vr', title: 'AR/VR' },
  { key: 'adtech', title: 'AdTech' },
  { key: 'advertising', title: 'Advertising' },
  { key: 'agriculture', title: 'Agriculture' },
  { key: 'analytics-big-data', title: 'Analytics & Big Data' },
  { key: 'artificial-intelligence', title: 'Artificial Intelligence' },
  { key: 'automotive', title: 'Automotive' },
  { key: 'banking', title: 'Banking' },
  {
    key: 'banking-finance-insurance',
    title: 'Banking, Finance, Insurance',
  },
  { key: 'biotechnology', title: 'Biotechnology' },
  { key: 'blockchain', title: 'Blockchain' },
  { key: 'business-consulting', title: 'Business Consulting' },
  { key: 'cleantech', title: 'Cleantech' },
  { key: 'cloud-computing', title: 'Cloud Computing' },
  {
    key: 'construction-and-real-estate',
    title: 'Construction and Real Estate',
  },
  { key: 'consultancy', title: 'Consultancy' },
  { key: 'consumer-mobile', title: 'Consumer Mobile' },
  {
    key: 'consumer-retail-fashion',
    title: 'Consumer, Retail, Fashion',
  },
  { key: 'cryptocurrencies', title: 'Cryptocurrencies' },
  { key: 'cyber-security', title: 'Cyber Security' },
  { key: 'design-and-creative', title: 'Design and Creative' },
  {
    key: 'e-commerce-online-marketplaces',
    title: 'E-Commerce & Online Marketplaces',
  },
  { key: 'education', title: 'Education' },
  {
    key: 'electronics-and-hardware',
    title: 'Electronics and Hardware',
  },
  { key: 'energy-and-utilities', title: 'Energy and Utilities' },
  { key: 'enterprise-software', title: 'Enterprise Software' },
  { key: 'fintech', title: 'FinTech' },
  { key: 'food-and-beverage', title: 'Food and Beverage' },
  { key: 'gaming', title: 'Gaming' },
  {
    key: 'government-and-public-sector',
    title: 'Government and Public Sector',
  },
  { key: 'hr-and-recruiting', title: 'HR and Recruiting' },
  { key: 'healthcare-and-medical', title: 'Healthcare and Medical' },
  {
    key: 'industrial-internet-iot',
    title: 'Industrial Internet & IoT',
  },
  { key: 'insurance', title: 'Insurance' },
  { key: 'investing', title: 'Investing' },
  { key: 'legal', title: 'Legal' },
  { key: 'logistics', title: 'Logistics' },
  { key: 'manufacturing', title: 'Manufacturing' },
  { key: 'marketing', title: 'Marketing' },
  {
    key: 'marketing-advertising-pr',
    title: 'Marketing, Advertising, PR',
  },
  { key: 'med-tech-pharma', title: 'Med-tech & Pharma' },
  { key: 'media-and-content', title: 'Media and Content' },
  { key: 'non-profit', title: 'Non-profit' },
  { key: 'on-demand-services', title: 'On-demand Services' },
  { key: 'pr-communications', title: 'PR & Communications' },
  { key: 'robotics', title: 'Robotics' },
  { key: 'saas', title: 'SaaS' },
  { key: 'sales-marketing', title: 'Sales & Marketing' },
  { key: 'services-consulting', title: 'Services & Consulting' },
  { key: 'software-development', title: 'Software Development' },
  { key: 'space-technology', title: 'Space Technology' },
  {
    key: 'sports-recreation-wellness',
    title: 'Sports, Recreation, Wellness',
  },
  { key: 'technology', title: 'Technology' },
  { key: 'telecommunications', title: 'Telecommunications' },
  { key: 'transport', title: 'Transport' },
  {
    key: 'transport-logistics-automotive',
    title: 'Transport, Logistics & Automotive',
  },
  { key: 'travel-tourism', title: 'Travel, Tourism' },
  { key: 'wearable-technology', title: 'Wearable Technology' },
];

const department = [
  { key: 'accounting', title: 'Accounting' },
  { key: 'administrative', title: 'Administrative' },
  { key: 'chief-executive-officer', title: 'Chief Executive Officer' },
  { key: 'communications', title: 'Communications' },
  { key: 'compliance', title: 'Compliance' },
  { key: 'country-manager', title: 'Country Manager' },
  { key: 'customer', title: 'Customer' },
  { key: 'data', title: 'Data' },
  { key: 'finance', title: 'Finance' },
  { key: 'information', title: 'Information' },
  { key: 'information-officer', title: 'Information Officer' },
  { key: 'investment', title: 'Investment' },
  { key: 'legal', title: 'Legal' },
  { key: 'marketing', title: 'Marketing' },
  { key: 'operations', title: 'Operations' },
  { key: 'procurement', title: 'Procurement' },
  { key: 'product', title: 'Product' },
  { key: 'revenue', title: 'Revenue' },
  { key: 'risk', title: 'Risk' },
  { key: 'sales', title: 'Sales' },
  { key: 'technology', title: 'Technology' },
];

export const skills = [
  {
    key: '',
    title: '',
  },
  {
    key: 'administration-and-office-support',
    title: 'Administration & Office Support',
    skills: [
      { key: 'accounting-assistant', title: 'Accounting Assistant' },
      {
        key: 'administrative-assistant',
        title: 'Administrative Assistant',
      },
      {
        key: 'client-sales-administration',
        title: 'Client & Sales Administration',
      },
      {
        key: 'contracts-administration',
        title: 'Contracts Administration',
      },
      {
        key: 'data-entry-word-processing',
        title: 'Data Entry & Word Processing',
      },
      { key: 'executive-assistant', title: 'Executive Assistant' },
      { key: 'hr-people-operations', title: 'HR & People Operations' },
      { key: 'office-assistant', title: 'Office Assistant' },
      { key: 'office-management', title: 'Office Management' },
      { key: 'personal-assistant', title: 'Personal Assistant' },
      { key: 'procurement', title: 'Procurement' },
      {
        key: 'receptionist-front-desk',
        title: 'Receptionist & Front-desk',
      },
      {
        key: 'records-management-document-control',
        title: 'Records Management & Document Control',
      },
    ],
  },
  {
    key: 'C-and-top-level-management',
    title: 'C & Top-level Management',
    managerialLevel: [
      { key: 'executive', title: 'Executive' },
      { key: 'head-of-department', title: 'Head of Department' },
      { key: 'junior-vice-president', title: 'Junior Vice President' },
      { key: 'vice-president', title: 'Vice President' },
    ],
    department,
    currentCompanyRevenue: revenue,
    companySize,
    industries,
  },
  {
    key: 'construction',
    title: 'Construction',
    skills: [
      { key: 'construction-worker', title: 'Construction Worker' },
      { key: 'contracts-management', title: 'Contracts Management' },
      { key: 'crew-leader-manager', title: 'Crew Leader, Manager' },
      { key: 'estimating', title: 'Estimating' },
      { key: 'field-supervising', title: 'Field Supervising' },
      {
        key: 'health-safety-environment',
        title: 'Health, Safety & Environment',
      },
      { key: 'planning-scheduling', title: 'Planning & Scheduling' },
      { key: 'project-management', title: 'Project Management' },
      { key: 'purchasing', title: 'Purchasing' },
      {
        key: 'quality-assurance-control',
        title: 'Quality Assurance & Control',
      },
      { key: 'supervising-foreperson', title: 'Supervising, Foreperson' },
    ],
    languages,
    seniorityLevel,
  },
  {
    key: 'customer-support',
    title: 'Customer Support',
    skills: [
      { key: 'account-management', title: 'Account Management' },
      { key: 'call-center', title: 'Call Center' },
      {
        key: 'customer-service-support',
        title: 'Customer Service & Support',
      },
      {
        key: 'customer-success-management',
        title: 'Customer Success Management',
      },
      { key: 'front-desk', title: 'Front Desk' },
      { key: 'help-desk', title: 'Help Desk' },
      { key: 'it-help-desk', title: 'IT Help Desk' },
      { key: 'inbound-sales', title: 'Inbound Sales' },
      {
        key: 'supervising-team-leading',
        title: 'Supervising & Team Leading',
      },
      { key: 'tech-support', title: 'Tech Support' },
      { key: 'technical-help-desk', title: 'Technical Help Desk' },
      { key: 'technical-support', title: 'Technical Support' },
    ],
    languages,
    seniorityLevel,
  },
  {
    key: 'data-and-analytics',
    title: 'Data & Analytics',
    skills: [
      { key: 'analytics', title: 'Analytics' },
      { key: 'bi-consultant', title: 'BI Consultant' },
      { key: 'business-analyst', title: 'Business Analyst' },
      { key: 'data-analyst', title: 'Data Analyst' },
      { key: 'data-engineering', title: 'Data Engineering' },
      { key: 'data-mining', title: 'Data Mining' },
      { key: 'data-science', title: 'Data Science' },
      { key: 'data-warehousing', title: 'Data Warehousing' },
      { key: 'deep-learning', title: 'Deep Learning' },
      { key: 'etl', title: 'ETL' },
      { key: 'machine-learning', title: 'Machine Learning' },
      { key: 'operations-analyst', title: 'Operations Analyst' },
      { key: 'process-analyst', title: 'Process Analyst' },
      { key: 'research', title: 'Research' },
      { key: 'statistical-modelling', title: 'Statistical Modelling' },
      { key: 'statistics', title: 'Statistics' },
      { key: 'systems-analyst', title: 'Systems Analyst' },
      { key: 'team-leading', title: 'Team Leading' },
    ],
    programmingTools,
    seniorityLevel,
  },
  {
    key: 'design',
    title: 'Design',
    skills: [
      { key: 'other', title: 'Other' },
      { key: 'animation', title: 'Animation' },
      { key: 'art-direction', title: 'Art Direction' },
      { key: 'brand-graphic-design', title: 'Brand & Graphic Design' },
      { key: 'fashion-design', title: 'Fashion Design' },
      { key: 'game-design', title: 'Game Design' },
      {
        key: 'industrial-product-design',
        title: 'Industrial / Product Design',
      },
      { key: 'marketing-design', title: 'Marketing Design' },
      { key: 'mobile-app-design', title: 'Mobile App Design' },
      { key: 'motion-design', title: 'Motion Design' },
      { key: 'photography', title: 'Photography' },
      { key: 'service-design', title: 'Service Design' },
      { key: 'team-leading', title: 'Team Leading' },
      { key: 'ui-visual-design', title: 'UI & Visual Design' },
      { key: 'ux-design', title: 'UX Design' },
      { key: 'ux-research', title: 'UX Research' },
      { key: 'video-film-production', title: 'Video & Film Production' },
      { key: '3d-modelling', title: '3D Modelling' },
    ],
    tools: designTools,
    seniorityLevel,
  },
  {
    key: 'engineering',
    title: 'Engineering',
    skills: [
      { key: 'aerospace-engineering', title: 'Aerospace Engineering' },
      { key: 'architecture', title: 'Architecture' },
      { key: 'automotive-engineering', title: 'Automotive Engineering' },
      { key: 'biomedical-engineering', title: 'Biomedical Engineering' },
      { key: 'chemical-engineering', title: 'Chemical Engineering' },
      { key: 'civil-engineering', title: 'Civil Engineering' },
      { key: 'electrical-engineering', title: 'Electrical Engineering' },
      {
        key: 'environmental-engineering',
        title: 'Environmental Engineering',
      },
      {
        key: 'geotechnical-engineering',
        title: 'Geotechnical Engineering',
      },
      { key: 'hardware-engineering', title: 'Hardware Engineering' },
      { key: 'mechanical-engineering', title: 'Mechanical Engineering' },
      {
        key: 'water-waste-engineering',
        title: 'Water & Waste Engineering',
      },
    ],
  },
  {
    key: 'finance',
    title: 'Finance',
    skills: [
      { key: 'accounting', title: 'Accounting' },
      { key: 'bank-teller', title: 'Bank Teller' },
      { key: 'bookkeeping', title: 'Bookkeeping' },
      { key: 'compliance-risk', title: 'Compliance & Risk' },
      { key: 'corporate-finance', title: 'Corporate Finance' },
      { key: 'credit-analysis', title: 'Credit Analysis' },
      { key: 'financial-auditing', title: 'Financial Auditing' },
      { key: 'financial-consultation', title: 'Financial Consultation' },
      { key: 'financial-controlling', title: 'Financial Controlling' },
      { key: 'financial-management', title: 'Financial Management' },
      { key: 'financial-planning', title: 'Financial Planning' },
      { key: 'investment-analysis', title: 'Investment Analysis' },
      { key: 'investments-management', title: 'Investments Management' },
      { key: 'kyc', title: 'KYC' },
      { key: 'loan-officer', title: 'Loan Officer' },
      { key: 'ma', title: 'M&A' },
      { key: 'payroll-management', title: 'Payroll Management' },
      { key: 'risk-analysis', title: 'Risk Analysis' },
      { key: 'stockbroking-trading', title: 'Stockbroking & trading' },
      { key: 'tax-advising', title: 'Tax Advising' },
      { key: 'taxation', title: 'Taxation' },
      { key: 'team-leading', title: 'Team Leading' },
      { key: 'underwriting', title: 'Underwriting' },
    ],
    institutionTypes,
    languages,
    seniorityLevel,
  },
  {
    key: 'hr-and-recruitment',
    title: 'HR & Recruitment',
    skills: [
      { key: 'other', title: 'Other' },
      { key: 'compensation-benefits', title: 'Compensation & Benefits' },
      { key: 'employer-branding', title: 'Employer Branding' },
      { key: 'r-deployment', title: 'R Deployment' },
      { key: 'hr-management', title: 'HR Management' },
      { key: 'hr-planning', title: 'HR Planning' },
      { key: 'headhunting', title: 'Headhunting' },
      {
        key: 'organisation-development',
        title: 'Organisation Development',
      },
      { key: 'recruitment', title: 'Recruitment' },
      { key: 'sourcing', title: 'Sourcing' },
      { key: 'team-leading', title: 'Team Leading' },
      { key: 'training-development', title: 'Training & Development' },
    ],
    seniorityLevel,
  },
  {
    key: 'hospitality-and-restaurant',
    title: 'Hospitality & Restaurant',
    skills: [
      { key: 'assistant-manager', title: 'Assistant Manager' },
      { key: 'barista', title: 'Barista' },
      { key: 'bartender', title: 'Bartender' },
      { key: 'chef-cook', title: 'Chef, Cook' },
      { key: 'cleaning', title: 'Cleaning' },
      { key: 'concierge', title: 'Concierge' },
      { key: 'hotel-receptionist', title: 'Hotel Receptionist' },
      { key: 'housekeeping', title: 'Housekeeping' },
      { key: 'manager', title: 'Manager' },
      { key: 'prep-cook', title: 'Prep Cook' },
      { key: 'restaurant-host-hostess', title: 'Restaurant Host, Hostess' },
      { key: 'shift-manager', title: 'Shift Manager' },
      { key: 'waiter-waitress', title: 'Waiter, Waitress' },
    ],
    languages,
    seniorityLevel,
  },
  {
    key: 'it-and-sysadmin',
    title: 'IT & Sysadmin',
    skills: [
      { key: 'other', title: 'Other' },
      { key: 'ci-and-cd', title: 'CI and CD' },
      { key: 'cloud-administration', title: 'Cloud Administration' },
      { key: 'computer-user-support', title: 'Computer User Support' },
      { key: 'containers', title: 'Containers' },
      { key: 'cyber-security', title: 'Cyber Security' },
      { key: 'database-administration', title: 'Database Administration' },
      { key: 'devops', title: 'DevOps' },
      { key: 'it-management', title: 'IT Management' },
      { key: 'microservices', title: 'Microservices' },
      { key: 'network-administration', title: 'Network Administration' },
      { key: 'network-engineering', title: 'Network Engineering' },
      { key: 'systems-administration', title: 'Systems Administration' },
      { key: 'systems-analysis', title: 'Systems Analysis' },
      { key: 'systems-deployment', title: 'Systems Deployment' },
      { key: 'systems-operations', title: 'Systems Operations' },
    ],
    tools: programmingTools,
    seniorityLevel,
  },
  {
    key: 'legal',
    title: 'Legal',
    skills: [
      { key: 'banking-finance', title: 'Banking & Finance' },
      { key: 'capital-markets', title: 'Capital Markets' },
      { key: 'commercial-law', title: 'Commercial Law' },
      { key: 'competition-eu-law', title: 'Competition & EU Law' },
      { key: 'corporate-law', title: 'Corporate Law' },
      { key: 'criminal-law', title: 'Criminal Law' },
      { key: 'data-protection', title: 'Data Protection' },
      { key: 'disputes-and-litigation', title: 'Disputes and Litigation' },
      { key: 'employment', title: 'Employment' },
      { key: 'energy', title: 'Energy' },
      { key: 'environmental-law', title: 'Environmental Law' },
      {
        key: 'healthcare-life-science',
        title: 'Healthcare & Life science',
      },
      { key: 'immigration-law', title: 'Immigration Law' },
      { key: 'isurance', title: 'Isurance' },
      { key: 'intellectual-property', title: 'Intellectual Property' },
      { key: 'mergers-acquisitions', title: 'Mergers & Acquisitions' },
      {
        key: 'private-equity-venture-capital',
        title: 'Private Equity, Venture Capital',
      },
      { key: 'project-finance', title: 'Project Finance' },
      {
        key: 'public-law-government-relations',
        title: 'Public Law & Government Relations',
      },
      { key: 'public-procurement', title: 'Public Procurement' },
      {
        key: 'real-estate-and-construction',
        title: 'Real Estate and Construction',
      },
      {
        key: 'restructuring-insolvency',
        title: 'Restructuring & Insolvency',
      },
      { key: 'tax-customs', title: 'Tax & Customs' },
      { key: 'transport-maritime', title: 'Transport & Maritime' },
    ],
    seniorityLevel,
  },
  {
    key: 'logistics-and-supply-chain',
    title: 'Logistics & Supply Chain',
    skills: [
      { key: 'demand-planning', title: 'Demand Planning' },
      { key: 'fleet-management', title: 'Fleet Management' },
      {
        key: 'freight-cargo-forwarding',
        title: 'Freight & Cargo Forwarding',
      },
      { key: 'import-export-customs', title: 'Import, Export, & Customs' },
      { key: 'inventory-management', title: 'Inventory Management' },
      { key: 'logistics-analyst', title: 'Logistics Analyst' },
      { key: 'logistics-coordinator', title: 'Logistics Coordinator' },
      {
        key: 'purchasing-procurement-inventory',
        title: 'Purchasing, Procurement & Inventory',
      },
      {
        key: 'quality-assurance-control',
        title: 'Quality Assurance & Control',
      },
      {
        key: 'rail-maritime-transport',
        title: 'Rail & Maritime Transport',
      },
      { key: 'transport-manager', title: 'Transport Manager' },
      { key: 'truck-driver', title: 'Truck Driver' },
      { key: 'warehouse-manager', title: 'Warehouse Manager' },
      { key: 'warehouse-worker', title: 'Warehouse Worker' },
      {
        key: 'warehousing-storage-distribution',
        title: 'Warehousing, Storage & Distribution',
      },
    ],
    languages,
    seniorityLevel,
  },
  {
    key: 'marketing-and-pr-and-media',
    title: 'Marketing & PR & Media',
    skills: [
      { key: 'other', title: 'Other' },
      { key: 'art-director', title: 'Art Director' },
      { key: 'branding', title: 'Branding' },
      { key: 'community-management', title: 'Community Management' },
      { key: 'content-marketing', title: 'Content Marketing' },
      { key: 'copywriting', title: 'Copywriting' },
      { key: 'email-marketing', title: 'Email Marketing' },
      { key: 'event-management', title: 'Event Management' },
      { key: 'facebook-ads', title: 'Facebook Ads' },
      { key: 'google-ads', title: 'Google Ads' },
      { key: 'journalism', title: 'Journalism' },
      { key: 'marketing-strategy', title: 'Marketing Strategy' },
      { key: 'media-planning-buying', title: 'Media Planning & Buying' },
      { key: 'media-strategy', title: 'Media Strategy' },
      { key: 'pr-communication', title: 'PR & Communication' },
      {
        key: 'pay-per-click-advertising',
        title: 'Pay-per-click Advertising',
      },
      { key: 'product-marketing', title: 'Product Marketing' },
      { key: 'seo-sem', title: 'SEO & SEM' },
      { key: 'social-media-management', title: 'Social Media Management' },
      { key: 'team-leading', title: 'Team Leading' },
      { key: 'web-analytics', title: 'Web Analytics' },
    ],
    languages,
    seniorityLevel,
  },
  {
    key: 'tech-project-management',
    title: '(Tech) Project Management',
    skills: [
      { key: 'analytics-bi', title: 'Analytics, BI' },
      { key: 'business-development', title: 'Business Development' },
      { key: 'customer-support', title: 'Customer Support' },
      { key: 'it-project-management', title: 'IT Project Management' },
      { key: 'product-owner', title: 'Product Owner' },
      { key: 'project-management', title: 'Project Management' },
      { key: 'quality-assurance', title: 'Quality Assurance' },
      { key: 'sql-programming', title: 'SQL, Programming' },
      { key: 'scrum-master', title: 'Scrum Master' },
      { key: 'scrum-kanban-agile', title: 'Scrum, Kanban, Agile' },
      { key: 'system-analysis', title: 'System Analysis' },
      { key: 'team-leading', title: 'Team Leading' },
      { key: 'technical-writing', title: 'Technical Writing' },
    ],
    seniorityLevel,
  },
  {
    key: 'real-estate',
    title: 'Real Estate',
    skills: [
      { key: 'commercial-leasing', title: 'Commercial Leasing' },
      { key: 'commercial-sales', title: 'Commercial Sales' },
      { key: 'property-development', title: 'Property Development' },
      { key: 'property-management', title: 'Property Management' },
      { key: 'real-estate-appraiser', title: 'Real Estate Appraiser' },
      { key: 'residential-leasing', title: 'Residential Leasing' },
      { key: 'residential-sales', title: 'Residential Sales' },
    ],
    languages,
    seniorityLevel,
  },
  {
    key: 'sales-and-business-development',
    title: 'Sales & Business Development',
    skills: [
      { key: 'other', title: 'Other' },
      { key: 'account-management', title: 'Account Management' },
      { key: 'active-sales', title: 'Active Sales' },
      { key: 'business-development', title: 'Business Development' },
      { key: 'cold-contacting', title: 'Cold Contacting' },
      {
        key: 'customer-success-management',
        title: 'Customer Success Management',
      },
      { key: 'data-analysis', title: 'Data Analysis' },
      { key: 'key-account-management', title: 'Key-account Management' },
      { key: 'lead-generation', title: 'Lead Generation' },
      { key: 'partnerships', title: 'Partnerships' },
      { key: 'prospect-research', title: 'Prospect Research' },
      { key: 'sales-analysis', title: 'Sales Analysis' },
      { key: 'sales-management', title: 'Sales Management' },
      { key: 'sales-representation', title: 'Sales Representation' },
      { key: 'team-leading', title: 'Team Leading' },
      { key: 'telesales', title: 'Telesales' },
    ],
    businessAreas,
    averageDealValue,
    industries,
    languages,
    seniorityLevel,
  },
  {
    key: 'retail',
    title: 'Retail',
    skills: [
      {
        key: 'assistant-store-management',
        title: 'Assistant Store Management',
      },
      { key: 'buying-planning', title: 'Buying & Planning' },
      { key: 'cashier', title: 'Cashier' },
      { key: 'category-management', title: 'Category Management' },
      { key: 'department-management', title: 'Department Management' },
      { key: 'sales-assistant', title: 'Sales Assistant' },
      { key: 'sales-management', title: 'Sales Management' },
      { key: 'stock-management', title: 'Stock Management' },
      { key: 'store-management', title: 'Store Management' },
      { key: 'training-management', title: 'Training Management' },
    ],
    storeTypes: [
      { key: 'other', title: 'Other' },
      { key: 'department-store', title: 'Department Store' },
      { key: 'electronics', title: 'Electronics' },
      { key: 'fashion', title: 'Fashion' },
      { key: 'specialty-store', title: 'Specialty Store' },
      { key: 'supermarket', title: 'Supermarket' },
    ],
    languages,
    seniorityLevel,
  },
  {
    key: 'software-engineering',
    title: 'Software Engineering',
    skills: [
      { key: 'backend-development', title: 'Backend Development' },
      { key: 'data-engineering', title: 'Data Engineering' },
      { key: 'database-administration', title: 'Database Administration' },
      { key: 'devops-engineering', title: 'DevOps Engineering' },
      { key: 'embedded-engineering', title: 'Embedded Engineering' },
      { key: 'engineering-management', title: 'Engineering Management' },
      { key: 'frontend-development', title: 'Frontend Development' },
      { key: 'full-stack-development', title: 'Full-Stack Development' },
      { key: 'game-development', title: 'Game Development' },
      { key: 'iot-engineering', title: 'IoT Engineering' },
      { key: 'machine-learning', title: 'Machine Learning' },
      { key: 'mobile-development', title: 'Mobile Development' },
      { key: 'product-management', title: 'Product Management' },
      { key: 'project-management', title: 'Project Management' },
      { key: 'qa-testing', title: 'QA & Testing' },
      {
        key: 'qa-test-automation-engineering',
        title: 'QA Test Automation Engineering',
      },
      {
        key: 'robotic-process-automation',
        title: 'Robotic Process Automation',
      },
      { key: 'security-engineering', title: 'Security Engineering' },
      { key: 'systems-analysis', title: 'Systems Analysis' },
      { key: 'systems-architecture', title: 'Systems Architecture' },
      { key: 'team-leading', title: 'Team Leading' },
    ],
    programmingTools,
    seniorityLevel,
  },
];

export const qualificationsData = {
  version: '',
  data: skills,
};
