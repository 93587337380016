import * as React from 'react'
import { Box, Button, TextField, Typography } from '@material-ui/core'
import { createRef, useState } from 'react'
import ReactPlayer from 'react-player'
import axios, { AxiosResponse } from 'axios'
import { useParams } from 'react-router-dom'
import { useCompany } from '../../hooks/useCompany'
import { QuestionInterface } from '../../rescouted-interfaces/src/interfaces/question.interface'
import { MediaInterface } from '../../rescouted-interfaces/src/interfaces/media.interface'
import { LoadingSpinner } from '../../components/loadingSpinner/LoadingSpinner.component'
import { toast } from 'react-toastify'

interface Props {
  onSubmit: (question: QuestionInterface, questionId: string) => void,
  onCancel: () => void,
  currentQuestion: QuestionInterface,
}

export const QuestionUpdate: React.FC<Props> = ({ onSubmit, onCancel, currentQuestion }) => {
  const videoInputRef = createRef<HTMLInputElement>()
  const [questionText, setQuestionText] = useState(currentQuestion.question ?? '')
  const [lookingFor, setLookingFor] = useState(currentQuestion.what_youre_looking_for ?? '')
  const [questionVideoURL, setQuestionVideoURL] = useState(currentQuestion.video ?? '')
  const [questionId, setQuestionId] = useState(currentQuestion.id ?? '')

  const [isLoading, setIsLoading] = useState(false)

  const { company } = useCompany()
  const { jobId } = useParams<{ jobId?: string }>()

  const handleUpdateClick = async () => {
    if (!questionText) {
      toast.error('Question cannot be empty.')
      return
    }

    await onSubmit({
      question: questionText,
      video: questionVideoURL,
      enabled: true,
      what_youre_looking_for: lookingFor
    }, questionId)

    onCancel()
  }

  const handleCancelClick = () => {
    setQuestionText(currentQuestion.question ?? '')
    setLookingFor(currentQuestion.what_youre_looking_for ?? '')
    setQuestionVideoURL(currentQuestion.video ?? '')
    onCancel()
  }

  const handleFileInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuestionVideoURL('')
    if (event.target.files && event.target.files.length > 0) {
      setIsLoading(true)
      const video = event.target.files[0]
      const formData = new FormData()
      formData.append('video', video)
      const response = await axios.post<any, AxiosResponse<MediaInterface>>(`/media/video/${company._id}/${jobId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      if (response && response.data) {
        setQuestionVideoURL(response.data.hlsUrl || response.data.cdnUrl)
        setIsLoading(false)
      }
    }
  }

  return (
    <Box mt={4}>
      <Box mb={3}>
        <TextField fullWidth label="Question in text" value={questionText} onChange={(e) => setQuestionText(e.target.value)} />
      </Box>
      <Box mb={3}>
        <TextField
          fullWidth
          label="What are you looking for in your answer?"
          value={lookingFor}
          onChange={(e) => setLookingFor(e.target.value)}
          multiline
        />
      </Box>
      <Box mb={3}>
        <TextField
          style={{ display: 'none' }}
          fullWidth
          label="Video question URL"
          value={questionVideoURL}
          helperText="You can add Youtube, Vimeo, direct links or upload video."
          onChange={(e) => setQuestionVideoURL(e.target.value)}
        />

        <input style={{ marginBottom: 16 }} type="file" ref={videoInputRef} onChange={handleFileInputChange} multiple={false} accept="video/mp4" />

        {isLoading && <LoadingSpinner />}

        {questionVideoURL ? <ReactPlayer url={questionVideoURL} playing loop controls /> : undefined}
      </Box>
      <Box>
        <Button variant="contained" onClick={handleUpdateClick} disabled={isLoading}>
          Update
        </Button>

        <Button variant="contained" onClick={handleCancelClick} disabled={isLoading} style={{ marginLeft: 16 }}>
          Cancel Editing
        </Button>
      </Box>
    </Box>
  )
}
