import * as React from 'react'
import { useCallback, useEffect, useState } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Box, Button, IconButton, List, ListItem, ListItemText, Tooltip, Typography } from '@material-ui/core'
import axios from 'axios'
import { useHistory } from 'react-router'
import {
  Link as LinkIcon,
  SupervisorAccount as ApplicantsIcon,
  Settings as SettingsIcon,
  Visibility as PublishedIcon,
  VisibilityOff as UnpublishedIcon,
  FiberManualRecord
} from '@material-ui/icons'
import { Link } from 'react-router-dom'
import { useCompany } from '../../hooks/useCompany'
import { JobInterface } from '../../rescouted-interfaces/src/interfaces/job.interface'
import { JobStatusTypes } from '../../rescouted-interfaces/src/types/JobStatus.types'
import PerfectScrollbar from 'react-perfect-scrollbar'

interface Props {}

export const JobsList: React.FC<Props> = () => {
  const [jobs, setJobs] = useState<JobInterface[]>([])

  const [status, setStatus] = useState<string | undefined>(undefined)

  const listStatusFilter = [
    {
      name: 'All',
      status: undefined,
      tooltip: 'All jobs'
    },
    {
      name: 'Published',
      status: 'published',
      tooltip: 'Published jobs'
    },
    {
      name: 'Draft',
      status: 'draft',
      tooltip: 'Draft jobs'
    }
  ]

  const { company } = useCompany()

  const history = useHistory()

  const fetchJobs = useCallback(
    async (companyId: string, status: string) => {
      const response = await axios.get(`jobs/${companyId}${status ? `?status=${status}` : ''}`, { baseURL: process.env.REACT_APP_API_BASE_URL })
      if (response.data) {
        setJobs(response.data)
      }
    },
    [company]
  )

  useEffect(() => {
    if (company?._id) {
      void fetchJobs(company._id, status)
    }
  }, [company, fetchJobs, status])

  const classes = useStyles()

  return (
    <PerfectScrollbar>
      <Box>
        <Box display="flex" justifyContent="space-between" p={3}>
          <Box display="flex" alignItems="center">
            <Box display="flex" alignItems="center">
              <Box fontWeight="bold" height="21px">
                Filter:
              </Box>
            </Box>
            {listStatusFilter.map((statusFilter) => (
              <Tooltip title={statusFilter.tooltip}>
                <Button
                  variant="contained"
                  style={{ maxHeight: '30px', boxShadow: 'none', paddingTop: '9px' }}
                  className={status === statusFilter.status ? classes.activeFilterButton : classes.filterButton}
                  onClick={() => setStatus(statusFilter.status)}
                >
                  {statusFilter.name}
                </Button>
              </Tooltip>
            ))}
          </Box>
          <Button component={Link} to="/job" variant="contained" color="primary" disabled={!company}>
            Create new
          </Button>
        </Box>
        <List>
          {jobs !== null &&
            jobs?.map((job) => (
              <ListItem button key={job._id} onClick={(e) => history.push(`/candidates/${job?._id}`)}>
                <Box pl={1} pr={2} pt={0.5}>
                  <Tooltip title={job?.publishStatus === JobStatusTypes.PUBLISHED ? 'Published job' : 'Not published job'}>
                    <FiberManualRecord style={{ color: job?.publishStatus === JobStatusTypes.PUBLISHED ? '#69F290' : '#DFE3E8' }} />
                  </Tooltip>
                </Box>
                <ListItemText primary={job.title} />
                <Box display="flex">
                  <Box>
                    <a href={`https://apply.rescouted.com/job/${job?._id}`} rel="noreferrer" target="_blank">
                      <Tooltip title="Job application link">
                        <IconButton>
                          <LinkIcon />
                        </IconButton>
                      </Tooltip>
                    </a>
                  </Box>
                  <Box ml={2}>
                    <Tooltip title="Job settings">
                      <IconButton component={Link} to={`/job/${job?._id}`} onClick={(e) => e.stopPropagation()}>
                        <SettingsIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <Box ml={2}>
                    <Tooltip title="Applicants list">
                      <IconButton component={Link} to={`/candidates/${job?._id}`} onClick={(e) => e.stopPropagation()}>
                        <ApplicantsIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
              </ListItem>
            ))}
        </List>
      </Box>
    </PerfectScrollbar>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  filterButton: {
    textTransform: 'none',
    maxHeight: '30px',
    boxShadow: 'none',
    paddingTop: '9px',
    marginLeft: '20px',
    borderRadius: '8px',
    backgroundColor: 'transparent',
    color: '#637381',
    '&:hover': {
      color: 'white',
      background: '#637381'
    }
  },
  activeFilterButton: {
    textTransform: 'none',
    maxHeight: '30px',
    boxShadow: 'none',
    paddingTop: '9px',
    marginLeft: '20px',
    borderRadius: '8px',
    color: 'white',
    background: '#637381',
    '&:hover': {
      color: 'white',
      background: '#637381'
    }
  }
}))
