import React from 'react'
import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  SvgIcon,
  Switch,
  Typography
} from '@material-ui/core'
import { XCircle as XIcon } from 'react-feather'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Add as AddIcon } from '@material-ui/icons'

interface Props {
  isOpen: boolean
  onClose: () => void
  onSelect: (videoURL: string, question: string) => void
}

export const QuestionsPicker: React.FC<Props> = ({ isOpen, onClose, onSelect }) => {
  const classes = useStyles()

  const handleClose = () => onClose()

  return (
    <Drawer
      anchor="right"
      classes={{ paper: classes.drawer }}
      ModalProps={{ BackdropProps: { invisible: false } }}
      onClose={handleClose}
      open={isOpen}
      variant="temporary"
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box p={3}>
          <Box display="flex" justifyContent="space-between" alignItems="center" px={2}>
            <Typography variant="h5" color="textPrimary">
              Predefined questions
            </Typography>
            <IconButton onClick={handleClose}>
              <SvgIcon fontSize="small">
                <XIcon />
              </SvgIcon>
            </IconButton>
          </Box>
          <Box mt={2}>
            <List>
              {predefinedQuestions.map((q) => (
                <ListItem button style={{ marginBottom: 4 }} key={q.video}>
                  {/*<ListItemText primary={q.question} />*/}
                  <Box pr={3}>
                    <Typography>{q.question}</Typography>
                  </Box>
                  <ListItemSecondaryAction>
                    <IconButton onClick={() => onSelect(q.video, q.question)}>
                      <AddIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </Box>
        </Box>
      </PerfectScrollbar>
    </Drawer>
  )
}

const useStyles = makeStyles((theme) => ({
  drawer: {}
}))

const predefinedQuestions = [
  { video: 'https://cdn.rescouted.com/videos/questions/hls/01/playlist.m3u8', question: 'What are your best skills?' },
  { video: 'https://cdn.rescouted.com/videos/questions/hls/02/playlist.m3u8', question: 'How would you describe yourself?' },
  { video: 'https://cdn.rescouted.com/videos/questions/hls/03/playlist.m3u8', question: 'What makes you unique?' },
  { video: 'https://cdn.rescouted.com/videos/questions/hls/04/playlist.m3u8', question: 'Why do you want to work here?' },
  { video: 'https://cdn.rescouted.com/videos/questions/hls/05/playlist.m3u8', question: 'What interests you about this role?' },
  { video: 'https://cdn.rescouted.com/videos/questions/hls/06/playlist.m3u8', question: 'What motivates you?' },
  { video: 'https://cdn.rescouted.com/videos/questions/hls/07/playlist.m3u8', question: 'What are you passionate about?' },
  { video: 'https://cdn.rescouted.com/videos/questions/hls/08/playlist.m3u8', question: 'Why are you leaving your current job?' },
  { video: 'https://cdn.rescouted.com/videos/questions/hls/09/playlist.m3u8', question: 'What are your greatest strengths?' },
  { video: 'https://cdn.rescouted.com/videos/questions/hls/10/playlist.m3u8', question: 'What are your greatest weaknesses?' },
  { video: 'https://cdn.rescouted.com/videos/questions/hls/11/playlist.m3u8', question: 'What are your goals for the future?' },
  { video: 'https://cdn.rescouted.com/videos/questions/hls/12/playlist.m3u8', question: 'Where do you see yourself in five years?' },
  {
    video: 'https://cdn.rescouted.com/videos/questions/hls/13/playlist.m3u8',
    question: 'Can you tell me about a difficult work situation and how you overcame it?'
  },
  { video: 'https://cdn.rescouted.com/videos/questions/hls/14/playlist.m3u8', question: 'What is your salary range expectation?' },
  { video: 'https://cdn.rescouted.com/videos/questions/hls/15/playlist.m3u8', question: 'Why should we hire you?' },
  { video: 'https://cdn.rescouted.com/videos/questions/hls/16/playlist.m3u8', question: 'Do you have any questions?' },
  { video: 'https://cdn.rescouted.com/videos/questions/hls/17/playlist.m3u8', question: 'What did you like most about your last position?' },
  { video: 'https://cdn.rescouted.com/videos/questions/hls/18/playlist.m3u8', question: 'What did you like least about your last position?' },
  { video: 'https://cdn.rescouted.com/videos/questions/hls/19/playlist.m3u8', question: 'How do you handle stress?' },
  { video: 'https://cdn.rescouted.com/videos/questions/hls/20/playlist.m3u8', question: 'What is your greatest accomplishment?' },
  { video: 'https://cdn.rescouted.com/videos/questions/hls/21/playlist.m3u8', question: 'What is your teaching philosophy?' },
  { video: 'https://cdn.rescouted.com/videos/questions/hls/22/playlist.m3u8', question: 'What does customer service mean to you?' },
  { video: 'https://cdn.rescouted.com/videos/questions/hls/23/playlist.m3u8', question: 'Tell me about your work experience.' },
  { video: 'https://cdn.rescouted.com/videos/questions/hls/24/playlist.m3u8', question: 'How do you define success?' },
  { video: 'https://cdn.rescouted.com/videos/questions/hls/25/playlist.m3u8', question: 'How do you work under pressure?' },
  { video: 'https://cdn.rescouted.com/videos/questions/hls/26/playlist.m3u8', question: 'What is your dream job?' },
  { video: 'https://cdn.rescouted.com/videos/questions/hls/27/playlist.m3u8', question: 'What can you bring to the company?' },
  { video: 'https://cdn.rescouted.com/videos/questions/hls/28/playlist.m3u8', question: 'How do you handle conflict at work?' },
  { video: 'https://cdn.rescouted.com/videos/questions/hls/29/playlist.m3u8', question: 'Why are you interested in this position?' },
  { video: 'https://cdn.rescouted.com/videos/questions/hls/30/playlist.m3u8', question: 'What skills would you bring to the job?' },
  { video: 'https://cdn.rescouted.com/videos/questions/hls/31/playlist.m3u8', question: 'Are you willing to travel?' },
  { video: 'https://cdn.rescouted.com/videos/questions/hls/32/playlist.m3u8', question: 'Are you overqualified for this role?' },
  { video: 'https://cdn.rescouted.com/videos/questions/hls/33/playlist.m3u8', question: 'Would you be willing to work nights and weekends?' },
  { video: 'https://cdn.rescouted.com/videos/questions/hls/34/playlist.m3u8', question: 'What qualities make a good leader?' },
  { video: 'https://cdn.rescouted.com/videos/questions/hls/35/playlist.m3u8', question: 'What is the name of our CEO?' },
  { video: 'https://cdn.rescouted.com/videos/questions/hls/36/playlist.m3u8', question: 'What questions haven’t I asked you?' },
  { video: 'https://cdn.rescouted.com/videos/questions/hls/37/playlist.m3u8', question: 'What do you know about our company?' },
  { video: 'https://cdn.rescouted.com/videos/questions/hls/38/playlist.m3u8', question: 'Why are you changing careers?' },
  { video: 'https://cdn.rescouted.com/videos/questions/hls/39/playlist.m3u8', question: 'Can you walk us through your resume?' },
  { video: 'https://cdn.rescouted.com/videos/questions/hls/40/playlist.m3u8', question: 'Why is our company interesting to you?' },
  { video: 'https://cdn.rescouted.com/videos/questions/hls/41/playlist.m3u8', question: 'Who was your favorite manager and why?' },
  { video: 'https://cdn.rescouted.com/videos/questions/hls/42/playlist.m3u8', question: 'Who are our competitors?' },
  { video: 'https://cdn.rescouted.com/videos/questions/hls/43/playlist.m3u8', question: 'Why are you the right person for this job?' },
  { video: 'https://cdn.rescouted.com/videos/questions/hls/44/playlist.m3u8', question: 'What is your greatest personal achievement?' },
  { video: 'https://cdn.rescouted.com/videos/questions/hls/45/playlist.m3u8', question: 'Where do you see yourself in 10 years?' },
  { video: 'https://cdn.rescouted.com/videos/questions/hls/46/playlist.m3u8', question: 'What do you know about our industry?' }
]
