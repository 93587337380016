import * as React from 'react'
import { createContext, useCallback, useContext, useEffect, useState } from 'react'
import * as firebase from 'firebase/app'
import 'firebase/auth'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import initFirebase from './initFirebase'
import { removeUserCookie, setUserCookie, getUserFromCookie } from './userCookies'
import { mapUserData } from './mapUserData'
import { setAxiosAuthToken } from './setAxiosAuthToken'
import { UserInterface } from '../../rescouted-interfaces/src/interfaces/user.interface'

initFirebase()

interface ContextInterface {
  user: UserInterface
  isAuthenticated: boolean
  logout?: () => void
}

export const AuthContext = createContext<ContextInterface>({ user: undefined, isAuthenticated: false })

export const AuthContextProvider = (props) => {
  const [user, setUser] = useState<UserInterface>()
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [isFetchedFromServer, setIsFetchedFromServer] = useState(false)

  const fetchUserFromServer = useCallback(async (u: UserInterface) => {
    if (u?.token) {
      const response = await axios.post('/me', user, { headers: { Authorization: u.token } })
      if (response && response.data && response.status === 200) {
        setIsFetchedFromServer(true)
        setUser(response.data)
      }
    }
  }, [])

  const history = useHistory()

  const logout = async () =>
    firebase
      .auth()
      .signOut()
      .then(() => {
        // Sign-out successful.
        setIsAuthenticated(false)
        history.push('/')
      })
      .catch((e) => {
        console.error(e)
      })

  useEffect(() => {
    // Firebase updates the id token every hour, this
    // makes sure the react state and the cookie are
    // both kept up to date
    const cancelAuthListener = firebase.auth().onIdTokenChanged(async (user) => {
      if (user) {
        setIsAuthenticated(true)
        const userData = await mapUserData(user)
        setAxiosAuthToken(userData.token)
        setUserCookie(userData)
        setUser(userData)
        if (!isFetchedFromServer && userData?.token) {
          await fetchUserFromServer(userData)
        }
      } else {
        setIsAuthenticated(false)
        removeUserCookie()
        setAxiosAuthToken(undefined)
        setUser(undefined)
      }
    })

    const userFromCookie = getUserFromCookie()
    if (!userFromCookie) {
      // void history.push('/')
      return
    }
    setUser(userFromCookie)

    return () => {
      cancelAuthListener()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // force refresh the token every 10 minutes
  useEffect(() => {
    const handle = setInterval(async () => {
      const user = firebase.auth().currentUser
      if (user) await user.getIdToken(true)
    }, 10 * 60 * 1000)

    // clean up setInterval
    return () => clearInterval(handle)
  }, [])

  // return { user, logout, isAuthenticated }
  return <AuthContext.Provider value={{ user, logout, isAuthenticated }}>{props.children}</AuthContext.Provider>
}

export const useUser = () => useContext(AuthContext)
