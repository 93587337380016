import { Box, makeStyles, Theme } from '@material-ui/core'
import React from 'react'
import { Rating } from './Rating.component'

interface Props {
  value?: number
  selectable?: boolean
  onSelect?: (val: number) => void
}

export const Ratings: React.FC<Props> = ({ value, onSelect, selectable }) => {
  const classes = useStyles()

  const handleClick = (v) => {
    if (selectable) {
      onSelect(v)
    }
  }

  return (
    <Box display="flex" flexDirection="row" alignContent="center">
      <Rating valuePosition={1} value={value} onClick={handleClick} />
      <Rating valuePosition={2} value={value} onClick={handleClick} />
      <Rating valuePosition={3} value={value} onClick={handleClick} />
      <Rating valuePosition={4} value={value} onClick={handleClick} />
      <Rating valuePosition={5} value={value} onClick={handleClick} />
    </Box>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  body: {}
}))
