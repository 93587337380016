import * as React from 'react'
import {
  AppBar, Box, Container, makeStyles, Tab, Tabs, useTheme
} from '@material-ui/core'
import SwipeableViews from 'react-swipeable-views'
import { useParams, useLocation, useHistory } from 'react-router-dom'
import { a11yProps } from '../../utils/a11yProps'
import { TabPanel } from '../TabPanel/TabPanel.component'
import { JobEditDetails } from './JobEditDetails.component'
import { JobEditQuestions } from './JobEditQuestions.component'
import PerfectScrollbar from 'react-perfect-scrollbar';
import { JobEditAIConfiguration } from './JobEditAIConfiguration.component'

interface Props {}

export const JobEdit: React.FC<Props> = () => {
  const classes = useStyles()
  const theme = useTheme()
  const history = useHistory()
  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const [tabIndex, setTabIndex] = React.useState(query.has('questions') ? 1 : 0)
  const { jobId } = useParams<{ jobId?: string }>()

  const handleChange = (event, newValue) => {
    setTabIndex(newValue)
  }

  const handleChangeIndex = (index) => {
    setTabIndex(index)
  }

  React.useEffect(() => {
    if (query.has('questions')) setTabIndex(1)
  }, [location])

  React.useEffect(() => {
    if (query.has('questions') && tabIndex === 0) {
      query.delete('questions')
      history.replace({
        search: query.toString()
      })
    }
  }, [tabIndex])

  return (
    <PerfectScrollbar>
      <div className={classes.root}>
        <Container maxWidth="md">
          <Box my={4}>
            {!!jobId && (
              <AppBar position="static" color="default">
                <Tabs
                  value={tabIndex}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                  aria-label="full width tabs example"
                >
                  <Tab label="Details" {...a11yProps(0)} />
                  <Tab label="Questions" {...a11yProps(1)} />
                  <Tab label="AI Configuration" {...a11yProps(2)} />
                </Tabs>
              </AppBar>
            )}
            <SwipeableViews axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'} index={tabIndex} onChangeIndex={handleChangeIndex}>
              <TabPanel value={tabIndex} index={0} dir={theme.direction} key="details">
                <JobEditDetails />
              </TabPanel>
              <TabPanel value={tabIndex} index={1} dir={theme.direction} key="questions">
                {jobId && (<JobEditQuestions />)}
              </TabPanel>
              <TabPanel value={tabIndex} index={2} dir={theme.direction} key="aiConfiguration">
                {jobId && (<JobEditAIConfiguration />)}
              </TabPanel>
            </SwipeableViews>
          </Box>
        </Container>
      </div>
    </PerfectScrollbar>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
  }
}))
