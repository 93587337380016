import * as React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';

interface Props {
  children: React.ReactNodeArray | React.ReactNode
  value: number
  index: number
  dir: 'rtl' | 'ltr'
}

export const TabPanel: React.FC<Props> = (props) => {
  const classes = useStyles()
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      className={classes.root}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    border: 'thin solid #d3d3d3'
  }
}));
