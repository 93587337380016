import { Box, Typography } from '@material-ui/core'
import React from 'react'
import { ApplicationInterface } from '../../rescouted-interfaces/src/interfaces/application.interface'
import { JobInterface } from '../../rescouted-interfaces/src/interfaces/job.interface'
import { NoteInterface } from '../../rescouted-interfaces/src/interfaces/note.interface'
import { Note } from '../Notes/Note.component'
import { MailInterface } from '../../rescouted-interfaces/src/interfaces/mail.interface'
import moment from 'moment-timezone'
import { Mail } from '../Mails/Mail.component'

interface Props {
  application: ApplicationInterface
  job: JobInterface
  notes: NoteInterface[]
  mails: MailInterface[]
}

export const CandidateNotesAndMessages: React.FC<Props> = ({ application, job, notes = [], mails = [] }) => {
  const items = [
    ...notes.map((n) => ({
      timestamp: moment(n.createdAt).unix(),
      type: 'note',
      itemNote: n,
      itemMail: undefined
    })),
    ...mails.map((m) => ({
      timestamp: moment(m.date).unix(),
      type: 'mail',
      itemNote: undefined,
      itemMail: m
    }))
  ].sort((a, b) => (a.timestamp - b.timestamp >= 0 ? -1 : 1))

  return (
    <Box>
      <Box mt={3} display="flex" flexDirection="row" style={{ gap: '16px' }}>
        <Typography variant="subtitle1">
          Notes <small>({notes.length})</small>
        </Typography>
        <Typography variant="subtitle1">
          Messages <small>({mails.length})</small>
        </Typography>
      </Box>
      <Box>
        {/*{notes.map((note) => (*/}
        {/*  <Note key={note._id} note={note} />*/}
        {/*))}*/}
        {items.map((item) => {
          if (item.type === 'note' && item.itemNote) {
            return <Note key={item.itemNote._id} note={item.itemNote} />
          }
          if (item.type === 'mail' && item.itemMail) {
            return <Mail key={item.itemMail._id} mail={item.itemMail} />
          }
          return undefined
        })}
      </Box>
      <Box py={3} />
    </Box>
  )
}
