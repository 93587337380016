import React from 'react'
import { ApplicationInterface } from '../../rescouted-interfaces/src/interfaces/application.interface'
import { Box, Chip, ListItem, makeStyles, Theme, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { Videocam as VideoIcon, ViewWeek as KanbanIcon, Star as StarIcon, StarBorder as EmptyStarIcon } from '@material-ui/icons'
import { _resolveQualification } from '../../utils/resolveQualification'
import { qualificationsData } from '../../rescouted-interfaces/src/data/qualifications.data'
import { Ratings } from '../../components/Ratings/Ratings.component'
import { JobInterface } from '../../rescouted-interfaces/src/interfaces/job.interface'
import { applicationStageTypeToLabel } from '../../rescouted-interfaces/src/helpers/applicationStageTypeToLabel'

interface Props {
  candidate: ApplicationInterface
  job: JobInterface
  isActive: boolean
  onClick: (a: ApplicationInterface) => void
  onStar?: (a: ApplicationInterface) => void
}

export const CandidateListItem: React.FC<Props> = ({ candidate, isActive, onClick, job }) => {
  const classes = useStyles()

  const qualification = _resolveQualification(candidate.qualification, qualificationsData.data)

  return (
    <ListItem
      button
      key={candidate._id.toString()}
      onClick={() => onClick(candidate)}
      className={clsx(classes.root, isActive ? classes.activeItem : undefined)}
    >
      <Box pr={1} py={1}>
        <div>
          <Box display="flex" alignItems="center">
            <Box mr={1}>{candidate.isStarred ? <StarIcon fontSize="small" /> : <EmptyStarIcon fontSize="small" />}</Box>
            <Typography variant="h6" className={classes.name}>
              {`${candidate.firstName} ${candidate.lastName}`}
            </Typography>
          </Box>
          <Box mt={1}>
            <Chip label={qualification.title} size="small" color={job.qualification === candidate.qualification ? 'primary' : 'default'} />
          </Box>
        </div>
        <Box display="flex" alignItems="center" mt={1}>
          <Box mr={1}>
            {candidate.step === 3 ? (
              <Chip icon={<VideoIcon fontSize="small" />} label={candidate._isRoomVideoProcessed ? 'Ready' : 'processing...'} size="small" />
            ) : undefined}
            {candidate.step < 3 ? <Chip icon={<VideoIcon fontSize="small" />} label="No video" size="small" variant="outlined" /> : undefined}
          </Box>
          <Box mr={1}>
            <Chip icon={<KanbanIcon fontSize="small" />} label={applicationStageTypeToLabel(candidate.stage)} size="small" />
          </Box>
          <Box>
            <Ratings selectable={false} value={candidate.rating} />
          </Box>
        </Box>
      </Box>
    </ListItem>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderLeftWidth: 6,
    borderLeftStyle: 'solid',
    borderLeftColor: 'transparent',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: '#f5f5f5'
  },
  activeItem: {
    borderLeftColor: theme.palette.primary.main,
    backgroundColor: '#f5f5f5'
  },
  name: {
    fontSize: 16
  }
}))
