import { Box, Grid, Switch, Container, Typography, Button, makeStyles, Theme } from '@material-ui/core'
import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { JobInterface } from '../../rescouted-interfaces/src/interfaces/job.interface'
import { useCompany } from '../../hooks/useCompany'
import axios from 'axios'
import { LoadingSpinner } from '../../components/loadingSpinner/LoadingSpinner.component'

export const JobEditAIConfiguration: React.FC = () => {
  const classes = useStyles()

  const { jobId } = useParams<{ jobId?: string }>()
  const [_job, setJob] = useState<JobInterface>()
  const { company } = useCompany()

  const [isFetching, setFetching] = useState(false)

  const fetchJob = useCallback(async () => {
    if (company?._id) {
      try {
        const response = await axios.get(`/job/${jobId}`)
        if (response && response.status === 200 && response.data) {
          console.log('fetched job', response.data)
          setJob(response.data)
        }
      } catch (err) {
        console.error(err)
      }
    }
  }, [jobId, company])

  useEffect(() => {
    if (jobId) {
      void fetchJob()
    }
  }, [fetchJob, jobId])

  const handleInitialCandidateReviewToggle = () => {
    setFetching(true)
    setJob({ ..._job, isAIConfigInitialCandidateReview: !_job.isAIConfigInitialCandidateReview })
  }

  const handleRejectAllCandidatesWithAIRatingLessThanOrEqualTo3Toggle = () => {
    setFetching(true)
    setJob({
      ..._job,
      isAIConfigRejectAllCandidatesWithAIRatingLessThanOrEqualTo3: !_job.isAIConfigRejectAllCandidatesWithAIRatingLessThanOrEqualTo3
    })
  }
  const handleAutoSendRejetionEmailsToggle = () => {
    setFetching(true)
    setJob({ ..._job, isAIConfigAutoSendRejetionEmails: !_job.isAIConfigAutoSendRejetionEmails })
  }
  const handleAutoSendAsyncVidInterviewQuestionsToggle = () => {
    setFetching(true)
    setJob({ ..._job, isAIConfigAutoSendAsyncVidInterviewQuestions: !_job.isAIConfigAutoSendAsyncVidInterviewQuestions })
  }

  const handleAutoEvalAsyncVideoInterviewResultsToggle = () => {
    setFetching(true)
    setJob({ ..._job, isAIConfigAutoEvalAsyncVideoInterviewResults: !_job.isAIConfigAutoEvalAsyncVideoInterviewResults })
  }

  const handleAutoProgressToTask1AndSendTaskInfoToggle = () => {
    setFetching(true)
    setJob({ ..._job, isAIConfigAutoProgressToTask1AndSendTaskInfo: !_job.isAIConfigAutoProgressToTask1AndSendTaskInfo })
  }

  const handleSaveClick = async () => {
    const uri = jobId ? `/job/${_job._id}` : '/job'

    const resp = await axios.post(uri, _job, { headers: { 'Content-Type': 'application/json' } })
    if (resp && resp.status === 200 && resp.data) {
      console.log(resp.data)
      if (jobId) setJob(resp.data)
      setFetching(false)
    }
    console.log('axios', axios.defaults.headers)
  }

  useEffect(() => {
    if (_job?._id) void handleSaveClick()
  }, [
    _job?.isAIConfigInitialCandidateReview,
    _job?.isAIConfigRejectAllCandidatesWithAIRatingLessThanOrEqualTo3,
    _job?.isAIConfigAutoSendRejetionEmails,
    _job?.isAIConfigAutoSendAsyncVidInterviewQuestions,
    _job?.isAIConfigAutoEvalAsyncVideoInterviewResults,
    _job?.isAIConfigAutoProgressToTask1AndSendTaskInfo
  ])

  if (_job) {
    return (
      <Container maxWidth="md">
        <Box my={4}>
          <Grid container>
            <Grid item sm={12}>
              <div className={classes.inputGroup}>
                <Box pr={1}>
                  <Switch
                    checked={_job.isAIConfigInitialCandidateReview}
                    edge="start"
                    name="isInitialCandidateReview"
                    onChange={handleInitialCandidateReviewToggle}
                    disabled={isFetching}
                  />
                </Box>
                <Box>
                  <Typography variant="body1" color="textPrimary">
                    Initial candidate review
                  </Typography>
                </Box>
              </div>
            </Grid>
            <Grid item sm={12}>
              <div className={classes.inputGroup}>
                <Box pr={1}>
                  <Switch
                    checked={_job.isAIConfigRejectAllCandidatesWithAIRatingLessThanOrEqualTo3}
                    edge="start"
                    name="isRejectAllCandidatesWithAIRatingLessThanOrEqualTo3"
                    onChange={handleRejectAllCandidatesWithAIRatingLessThanOrEqualTo3Toggle}
                    disabled={!_job.isAIConfigInitialCandidateReview || isFetching}
                  />
                </Box>
                <Box>
                  <Typography variant="body1" color="textPrimary">
                    Reject all candidates with AI rating &lt;= 3
                  </Typography>
                </Box>
              </div>
            </Grid>
            <Grid item sm={12}>
              <div className={classes.inputGroup}>
                <Box pr={1}>
                  <Switch
                    checked={_job.isAIConfigAutoSendRejetionEmails}
                    edge="start"
                    name="isAutoSendRejetionEmails"
                    onChange={handleAutoSendRejetionEmailsToggle}
                    disabled={!_job.isAIConfigInitialCandidateReview || isFetching}
                  />
                </Box>
                <Box>
                  <Typography variant="body1" color="textPrimary">
                    Automatically send rejection emails
                  </Typography>
                </Box>
              </div>
            </Grid>
            <Grid item sm={12}>
              <div className={classes.inputGroup}>
                <Box pr={1}>
                  <Switch
                    checked={_job.isAIConfigAutoSendAsyncVidInterviewQuestions}
                    edge="start"
                    name="isAutoSendAsyncVidInterviewQuestions"
                    onChange={handleAutoSendAsyncVidInterviewQuestionsToggle}
                    disabled={!_job.isAIConfigInitialCandidateReview || isFetching}
                  />
                </Box>
                <Box>
                  <Typography variant="body1" color="textPrimary">
                    Automatically send Async Video Interview invitation
                  </Typography>
                </Box>
              </div>
            </Grid>
            <Grid item sm={12}>
              <div className={classes.inputGroup}>
                <Box pr={1}>
                  <Switch
                    checked={_job.isAIConfigAutoEvalAsyncVideoInterviewResults}
                    edge="start"
                    name="isAutoEvalAsyncVideoInterviewResults"
                    onChange={handleAutoEvalAsyncVideoInterviewResultsToggle}
                    disabled={!_job.isAIConfigInitialCandidateReview || isFetching}
                  />
                </Box>
                <Box>
                  <Typography variant="body1" color="textPrimary">
                    Automatically evaluate Async Video Interview results
                  </Typography>
                </Box>
              </div>
            </Grid>
            <Grid item sm={12}>
              <div className={classes.inputGroup}>
                <Box pr={1}>
                  <Switch
                    checked={_job.isAIConfigAutoProgressToTask1AndSendTaskInfo}
                    edge="start"
                    name="isAutoProgressToTask1AndSendTaskInfo"
                    onChange={handleAutoProgressToTask1AndSendTaskInfoToggle}
                    disabled={!_job.isAIConfigInitialCandidateReview || isFetching}
                  />
                </Box>
                <Box>
                  <Typography variant="body1" color="textPrimary">
                    Automatically progress to Task 1 and send task information
                  </Typography>
                </Box>
              </div>
            </Grid>
          </Grid>
        </Box>
      </Container>
    )
  }

  return (
    <Box display="flex" justifyContent="center">
      <LoadingSpinner />
    </Box>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontWeight: 700,
    '& .MuiFormLabel-root:not(.MuiFormLabel-shrink)': {
      fontWeight: 700
    }
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18
    }
  },
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2)
  },
  checkboxesWrapper: {
    paddingTop: '11px',
    '& .MuiFormControlLabel-label': {
      color: theme.palette.grey['700']
    }
  },
  inputGroup: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1)
  },
  salaryExplanation: {
    display: 'block',
    marginTop: '0.5rem',
    color: theme.palette.grey['500']
  },
  hypen: {
    fontSize: '24px',
    color: theme.palette.grey['500']
  },
  mainActionButton: {
    marginLeft: theme.spacing(1)
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  },
  disabled: {
    opacity: 0.5
  },
  publishStatus: {
    padding: theme.spacing(0.5),
    textTransform: 'uppercase',
    fontSize: '16px',
    fontWeight: 500,
    marginLeft: theme.spacing(2),
    border: 'thin solid #dfdfdf',
    borderRadius: theme.spacing(0.5),
    verticalAlign: 'middle'
  },
  mediaInputGroup: {
    marginBottom: theme.spacing(4)
  },
  mediaInfoBlock: {
    color: theme.palette.grey['500']
  },
  removeMediaButton: {
    color: theme.palette.grey['500'],
    marginLeft: theme.spacing(2)
  }
}))
