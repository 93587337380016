import React, { FC, useState } from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, TextField, Tooltip } from '@material-ui/core'
import { Delete as DeleteIcon, Note as NoteIcon } from '@material-ui/icons'
import { JobInterface } from '../../rescouted-interfaces/src/interfaces/job.interface'
import { ApplicationInterface } from '../../rescouted-interfaces/src/interfaces/application.interface'
import axios from 'axios'
import { RTEditor } from '../../app/RTEditor/RTEditor.component'

interface Props {
  application: ApplicationInterface
  job: JobInterface
  onNoteAdded: () => void
}

export const CandidateActionNoteComponent: FC<Props> = ({ application, job, onNoteAdded }) => {
  const [isNotesOpen, setIsNotesOpen] = useState(false)

  const handleOpenNotes = () => setIsNotesOpen(true)
  const handleCloseNotes = () => setIsNotesOpen(false)

  const [rtKey, setRtKey] = useState<number>(1)
  const [headline, setHeadline] = useState<string>('')
  const [body, setBody] = useState<string>('')

  const handleClearNoteClick = () => {
    setBody('')
    setHeadline('')
    setRtKey(rtKey + 1)
  }

  const handleSaveNoteClick = async () => {
    const response = await axios.post(`/job/${job._id}/application/${application._id}/notes/post`, { headline, body })
    if (response && response.status === 200 && response.data) {
      handleCloseNotes()
      handleClearNoteClick()
      onNoteAdded()
    }
  }

  return (
    <>
      <Tooltip title="Add a note">
        <Button onClick={handleOpenNotes}>
          <NoteIcon />
        </Button>
      </Tooltip>

      <Dialog open={isNotesOpen} onClose={handleCloseNotes} aria-labelledby="form-dialog-title">
        <DialogTitle id="add-note">Add a Note</DialogTitle>
        <DialogContent>
          <Box display="flex" alignItems="center" mt={3}>
            <Box flexGrow={1} pr={2}>
              <TextField
                style={{ backgroundColor: 'white' }}
                color="primary"
                variant="outlined"
                label="Headline"
                fullWidth
                value={headline}
                onChange={(e) => setHeadline(e.target.value)}
              />
            </Box>
            <Box>
              <IconButton onClick={handleClearNoteClick} type="button">
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Box>
          </Box>
          <Box mt={3}>
            <RTEditor key={rtKey} value={body} onChange={(value) => setBody(value)} label="" height={body.length < 5 ? 120 : 250} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseNotes} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSaveNoteClick} color="primary">
            Add note
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
