import React, { createRef, useState } from 'react'
import {
  Box, Button, IconButton, makeStyles
} from '@material-ui/core';
import ReactPlayer from 'react-player';
import {
  PlayArrow as PlayIcon,
  Replay as ResetIcon
} from '@material-ui/icons'

interface Props {
  url: string
  onEnded?: () => void
  playing?: boolean
  children?: (ref: React.RefObject<ReactPlayer>) => React.ReactNode
}

export const VideoPlayer: React.FC<Props> = ({
  url, playing = false, onEnded, children
}) => {
  const classes = useStyles()

  const videoRef = createRef<ReactPlayer>()

  const [isPlaying, setIsPlaying] = useState(playing)

  const handlePlayClick = () => {
    if (videoRef?.current) {
      setIsPlaying(true)
    }
  }

  const handleResetClick = () => {
    if (videoRef?.current) {
      videoRef.current.seekTo(0)
      setIsPlaying(true)
    }
  }

  return (
    <Box display="block">
      <div className="player-wrapper">
        <ReactPlayer
          className="react-player"
          ref={videoRef}
          url={url}
          controls
          playing={isPlaying}
          width="100%"
          height="100%"
          onEnded={onEnded}
          onPlay={() => setIsPlaying(true)}
          onPause={() => setIsPlaying(false)}
        />
        {!isPlaying ? (
          <div className={classes.controls}>
            <Box display="flex" alignItems="center">
              <Box mr={2}>
                <Button color="primary" variant="contained" onClick={handlePlayClick}>
                  <PlayIcon fontSize="large" />
                </Button>
              </Box>
              <Box ml={2}>
                <Button color="primary" variant="contained" onClick={handleResetClick}>
                  <ResetIcon fontSize="large" />
                </Button>
              </Box>
            </Box>
          </div>
        ) : undefined}
      </div>
      {children && (
        <Box>
          {children(videoRef)}
        </Box>
      )}
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  controls: {
    backgroundColor: 'rgba(255,255,255,0.5)',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
}))
