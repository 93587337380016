import React, { FC } from 'react'
import { Box } from '@material-ui/core'

import { JobInterface } from '../../rescouted-interfaces/src/interfaces/job.interface'
import { ApplicationInterface } from '../../rescouted-interfaces/src/interfaces/application.interface'
import { CandidateActionNoteComponent } from './CandidateActionNote.component'
import { CandidateActionMailComponent } from './CandidateActionMail.component'

interface Props {
  application: ApplicationInterface
  job: JobInterface
  onNoteAdded: () => void
  onMailSent: () => void
}

export const CandidateActionsComponent: FC<Props> = ({ application, job, onNoteAdded, onMailSent }) => {
  return (
    <Box flexGrow={1} gridGap={3} display="flex" flexDirection="row" justifyContent="center">
      <CandidateActionNoteComponent application={application} job={job} onNoteAdded={onNoteAdded} />
      <CandidateActionMailComponent application={application} job={job} onMailSent={onMailSent} />
    </Box>
  )
}
