import * as React from 'react'

export const onImageDrop = async (dataTransfer, setMarkdown) => {
  const files = [];

  for (let index = 0; index < dataTransfer.items.length; index++) {
    const file = dataTransfer.files.item(index);
    if (file) files.push(file);
  }

  // await Promise.all(
  //   files.map(async (file) => onImageUpload_DnD(file, setMarkdown))
  // );
};

export const imgBtn = (inputRef, textApiRef) => ({
  name: 'Text To Image',
  keyCommand: 'text2image',
  render: (command, disabled, executeCommand) => {
    return (
      <button
        type="button"
        aria-label="Insert title3"
        disabled={disabled}
        onClick={() => {
          executeCommand(command, command.groupName);
        }}
      >
        <svg width="12" height="12" viewBox="0 0 20 20">
          <path
            fill="currentColor"
            d="M15 9c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm4-7H1c-.55 0-1 .45-1 1v14c0 .55.45 1 1 1h18c.55 0 1-.45 1-1V3c0-.55-.45-1-1-1zm-1 13l-6-5-2 2-4-5-4 8V4h16v11z"
          />
        </svg>
      </button>
    );
  },
  execute: (state, api) => {
    inputRef.current.click();
    textApiRef.current = api;
  }
});

export const editChoice = (inputRef, textApiRef) => [
  imgBtn(inputRef, textApiRef)
];
