import {
  Box,
  Button,
  Container,
  Grid,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Theme,
  ListItemSecondaryAction,
  IconButton,
  Chip,
  Typography
} from '@material-ui/core'
import { Delete as DeleteIcon, Add as AddIcon } from '@material-ui/icons'
import React, { useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { CompanyInterface } from '../../rescouted-interfaces/src/interfaces/company.interface'
import axios from 'axios'
import { LoadingOverlay } from '../../components/loadingSpinner/LoadingOverlay.component'
import { UserInterface } from '../../rescouted-interfaces/src/interfaces/user.interface'
import { UserInvitationInterface } from '../../rescouted-interfaces/src/interfaces/UserInvitation.interface' //
import validator from 'validator'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { toast } from 'react-toastify'

interface Props {}

export const CompanyTeamMembers: React.FC<Props> = () => {
  const classes = useStyles()
  const { companyId } = useParams<{ companyId?: string }>()
  const history = useHistory()

  const [isLoading, setIsLoading] = useState<boolean>(!!companyId)

  const [_company, setCompany] = useState<CompanyInterface>()
  const [members, setMembers] = useState<{ team: UserInterface[]; invitations: UserInvitationInterface[] }>({ team: [], invitations: [] })

  const fetchCompany = useCallback(async () => {
    if (companyId) {
      try {
        const response = await axios.get(`/my/companies/${companyId}`)
        if (response && response.status === 200 && response.data) {
          setCompany(response.data)
        }
        setIsLoading(false)
      } catch (err) {
        console.log(err)
      }
    }
  }, [companyId])

  const fetchTeam = useCallback(async () => {
    if (companyId) {
      try {
        const response = await axios.get(`/my/companies/${companyId}/team`)
        if (response && response.status === 200 && response.data) {
          const { team, invitations } = response.data
          setMembers({ team: team || [], invitations: invitations || [] })
        }
        setIsLoading(false)
      } catch (err) {
        console.log(err)
      }
    }
  }, [companyId])

  const fetchPostAddTeamMember = useCallback(async () => {
    if (companyId) {
      try {
        const email = window.prompt('Enter colleagues email address.')
        if (!email) return
        if (!validator.isEmail(email)) {
          toast.error('Email is not valid.')
          return
        }
        const response = await axios.post(`/my/companies/${companyId}/team/add`, { email })
        if (response && response.status === 200 && response.data) {
          setIsLoading(true)
          void (await fetchTeam())
        }
      } catch (err) {
        console.log(err)
      }
    }
  }, [companyId])

  useEffect(() => {
    if (companyId) {
      setIsLoading(true)
      void fetchCompany()
      void fetchTeam()
    } else {
      setCompany(undefined)
      setIsLoading(false)
    }
  }, [fetchCompany, companyId, history])

  const handleAddClick = () => {
    void fetchPostAddTeamMember()
  }

  const handleDeleteClick = async (user: UserInterface) => {
    if (window.confirm(`Do you really want to remove ${user.email} from ${_company.name} company?`)) {
      const response = await axios.post(`/my/companies/${companyId}/team/delete`, { email: user.email })
      if (response && response.status === 200 && response.data) {
        setIsLoading(true)
        void (await fetchTeam())
      }
    }
  }

  const handleDeleteInvitationClick = async (invitation: UserInvitationInterface) => {
    if (window.confirm(`Do you really want to remove ${invitation.email} from ${_company.name} company?`)) {
      const response = await axios.post(`/my/companies/${companyId}/team/delete`, { email: invitation.email })
      if (response && response.status === 200 && response.data) {
        setIsLoading(true)
        void (await fetchTeam())
      }
    }
  }

  if ((companyId && !_company) || isLoading) {
    return <LoadingOverlay />
  }

  return (
    <PerfectScrollbar>
      <Container maxWidth="md">
        <Box my={4}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
            <Box pl={2}>
              <Typography>Access to {_company.name} jobs and candidates</Typography>
            </Box>
            <Box pr={2}>
              <IconButton color="primary" onClick={handleAddClick}>
                <AddIcon />
              </IconButton>
            </Box>
          </Box>
          <Grid container>
            <Grid item sm={12}>
              <List>
                {members?.team &&
                  Array.isArray(members.team) &&
                  members.team.map((member, i) => (
                    <ListItem button key={member._id}>
                      <ListItemText primary={member.name} secondary={member.email} />

                      <ListItemSecondaryAction>
                        {i === 0 ? (
                          <Box mr={2}>
                            <Chip label="Creator" />
                          </Box>
                        ) : undefined}
                        {i !== 0 ? (
                          <Box>
                            <IconButton onClick={() => handleDeleteClick(member)}>
                              <DeleteIcon />
                            </IconButton>
                          </Box>
                        ) : undefined}
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                {members?.invitations &&
                  Array.isArray(members.invitations) &&
                  members.invitations.map((member, i) => (
                    <ListItem button key={member._id}>
                      <ListItemText primary="Invited" secondary={member.email} />
                      <ListItemSecondaryAction>
                        <Box>
                          <IconButton onClick={() => handleDeleteInvitationClick(member)}>
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
              </List>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </PerfectScrollbar>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {}
}))
