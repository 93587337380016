import React from 'react'
import ReactMarkdown from 'react-markdown'
// import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
// import dark from 'react-syntax-highlighter/src/styles/prism/dark'
import remarkGfm from 'remark-gfm'

interface Props {
  content: string
}

// const renderers = {
//   code: ({ language, value }) => <SyntaxHighlighter language={language} children={value} />
// }

export const Markdown: React.FC<Props> = ({ content }) => <ReactMarkdown remarkPlugins={[remarkGfm]} children={content} />
