import React from 'react'
import { Box, FormLabel, Grid, IconButton, Theme, makeStyles } from '@material-ui/core'
import { useMutation } from '@tanstack/react-query'
import { LoadingSpinner } from '../loadingSpinner/LoadingSpinner.component'
import ReactPlayer from 'react-player'
import axios, { AxiosResponse } from 'axios'
import { MediaInterface } from '../../rescouted-interfaces/src/interfaces/media.interface'
import { Delete } from 'react-feather'

async function uploadSingleImageFromInput(formData: FormData, companyId: string) {
  const response = await axios.post<any, AxiosResponse<MediaInterface>>(`/media/image/${companyId}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })

  return response.data
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: '2px'
  },
  label: {
    fontSize: '0.75rem',
    paddingBottom: '0.5rem'
  }
}))

export interface ImageFieldProps {
  value: string
  companyId: string
  onChange: (value: string | null) => void
  label?: string
}

export const ImageField = (props: ImageFieldProps) => {
  const { value, onChange, label, companyId } = props

  const fileInputRef = React.useRef<HTMLInputElement>(null)
  const classes = useStyles()
  const mutation = useMutation({
    mutationFn: (formData: FormData) => uploadSingleImageFromInput(formData, companyId),
    onSuccess: (data) => onChange(data.cdnUrl)
  })

  return (
    <Box display="flex" flexDirection="column">
      <Box mt={4} display="flex">
        {label && label !== '' ? <FormLabel className={classes.label}>{label}</FormLabel> : null}
        {value == null || value.trim() === '' ? null : (
          <Box ml={2}>
            <IconButton
              size="small"
              onClick={() => {
                mutation.reset()
                onChange(null)
                if (fileInputRef.current != null) {
                  fileInputRef.current.value = ''
                }
              }}
            >
              <Delete fontSize="small" />
            </IconButton>
          </Box>
        )}
      </Box>
      <Grid container>
        <Grid item md={12}>
          {mutation.isPending ? <LoadingSpinner /> : null}
          {value == null || value.trim() === '' ? null : <img src={value} alt={label} style={{ width: '100%' }} />}
        </Grid>
      </Grid>
      <input
        type="file"
        ref={fileInputRef}
        onChange={(event) => {
          if (event.target.files == null || event.target.files.length === 0) {
            return null
          }

          const Image = event.target.files[0]

          const formData = new FormData()
          formData.append('image', Image)

          mutation.mutate(formData)
        }}
        multiple={false}
        accept="image/*"
      />
    </Box>
  )
}
