import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { CompanyInterface } from '../rescouted-interfaces/src/interfaces/company.interface'

interface ContextInterface {
  company: CompanyInterface
  companies: CompanyInterface[]
  fetchCompanies?: () => void
  setActiveCompany: (company?: CompanyInterface) => void
}

export const CompanyContext = createContext<Partial<ContextInterface>>({ company: undefined, companies: undefined })

export const CompanyContextProvider = (props) => {
  const [companies, setCompanies] = useState<CompanyInterface[]>()
  const [company, setCompany] = useState<CompanyInterface>()
  const [isFetching, setIsFetching] = useState(false)

  const setActiveCompany = (c?: CompanyInterface) => {
    console.log('here', companies)
    if (!companies || companies.length < 1) return
    if (c) {
      setCompany(c)
      localStorage.setItem('cid', c._id)
    } else {
      const savedCompanyId = localStorage.getItem('cid')
      if (savedCompanyId) {
        const existingCompany = companies.find((comp) => comp._id.toString() === savedCompanyId)
        if (existingCompany) {
          setCompany(existingCompany)
        } else {
          setCompany(companies[0])
          localStorage.setItem('cid', undefined)
        }
      } else {
        setCompany(companies[0])
      }
    }
  }

  const fetchCompanies = useCallback(async () => {
    setIsFetching(true)
    const response = await axios.get('my/companies', { baseURL: process.env.REACT_APP_API_BASE_URL })
    if (response.data && response.status === 200) {
      setCompanies(response.data)
    }
    setIsFetching(false)
  }, [])

  useEffect(() => {
    if (!companies && !isFetching) {
      console.log('Fetch companies!')
      void fetchCompanies()
    }
  }, [companies, fetchCompanies, isFetching])

  useEffect(() => {
    if (companies && companies?.length > 0) {
      setActiveCompany()
    }
  }, [companies])

  return (
    <CompanyContext.Provider
      value={{
        company,
        companies: companies ?? [],
        fetchCompanies,
        setActiveCompany
      }}
    >
      {props.children}
    </CompanyContext.Provider>
  )
}

export const useCompany = () => useContext(CompanyContext)
