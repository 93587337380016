import React from 'react'
import { NoteInterface } from '../../rescouted-interfaces/src/interfaces/note.interface'
import { Box, makeStyles, Theme, Tooltip, Typography } from '@material-ui/core'
import moment from 'moment-timezone'
import { Markdown } from '../../components/Markdown/Markdown.component'
import { NoteTypes } from '../../rescouted-interfaces/src/types/Note.types'
import clsx from 'clsx'

interface Props {
  note: NoteInterface
}

export const Note: React.FC<Props> = ({ note }) => {
  const classes = useStyles()

  const authorName =
    (note.createdByUser as any) && (note.createdByUser as any)._id ? (note.createdByUser as any).name || (note.createdByUser as any).email : ''

  return (
    <Box mt={3} className={clsx(classes.root, note.type === NoteTypes.SYSTEM ? classes.systemRoot : undefined)}>
      <Box display="flex">
        <Box flexGrow={1}>
          <Typography variant="subtitle2">{note.headline}</Typography>
        </Box>
        <Box display="flex" ml={2} whiteSpace="nowrap">
          {note.type === NoteTypes.MANAGER && authorName ? <Typography variant="body2">{authorName}</Typography> : undefined}
          {note.type === NoteTypes.SYSTEM && !authorName ? <Typography variant="body2">Auto</Typography> : undefined}
          {note.type === NoteTypes.SYSTEM && authorName ? <Typography variant="body2">{authorName}</Typography> : undefined}
          <Box ml={2}>
            <Box whiteSpace="nowrap">
              <Tooltip title={`${moment(note.createdAt).tz(moment.tz.guess()).format('yyyy-MM-DD hh:mm')}`}>
                <Typography align="left" variant="body2">
                  {moment(note.createdAt).tz(moment.tz.guess()).fromNow(true)}
                </Typography>
              </Tooltip>
            </Box>
          </Box>
        </Box>
      </Box>
      {note.body ? (
        <Box mt={2} className={classes.body}>
          <article>
            <Markdown content={note.body} />
          </article>
        </Box>
      ) : undefined}
    </Box>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    border: 'thin solid #cecece',
    padding: theme.spacing(2),
    backgroundColor: '#ffffe1',
    boxShadow: '0px 5px 5px 0px rgba(0,0,0,0.14)'
  },
  systemRoot: {
    backgroundColor: '#eeeeee'
  },
  body: {}
}))
