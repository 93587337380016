import { Box, makeStyles, MenuItem, Select, Theme } from '@material-ui/core'
import React from 'react'
import { ApplicationStageTypes } from '../../rescouted-interfaces/src/types/ApplicationStage.types'

interface Props {
  value: ApplicationStageTypes
  onSelect?: (val: string) => void
  noAny?: boolean
}

export const ApplicationStageSelect: React.FC<Props> = ({ value, onSelect, noAny = false }) => {
  const classes = useStyles()

  return (
    <Box display="flex" flexDirection="row" alignContent="center">
      <Select className={classes.root} value={value} onChange={(e: React.ChangeEvent<{ value: ApplicationStageTypes }>) => onSelect(e.target.value)}>
        {!noAny && <MenuItem value={ApplicationStageTypes.ANY}>---</MenuItem>}
        <MenuItem value={ApplicationStageTypes.NEW}>#0 New</MenuItem>
        <MenuItem value={ApplicationStageTypes.IN_REVIEW}>#1 In review</MenuItem>
        <MenuItem value={ApplicationStageTypes.CALL_SENT}>#2 Call Sent</MenuItem>
        <MenuItem value={ApplicationStageTypes.CALL_COMPLETED}>#3 Call completed</MenuItem>
        <MenuItem value={ApplicationStageTypes.TASK_1_SENT}>#4 Task 1 sent</MenuItem>
        <MenuItem value={ApplicationStageTypes.TASK_1_COMPLETED}>#5 Task 1 completed</MenuItem>
        <MenuItem value={ApplicationStageTypes.TASK_2_SENT}>#6 Task 2 sent</MenuItem>
        <MenuItem value={ApplicationStageTypes.TASK_2_COMPLETED}>#7 Task 2 completed</MenuItem>
        <MenuItem value={ApplicationStageTypes.FINAL_CALL_SENT}>#8 Final call sent</MenuItem>
        <MenuItem value={ApplicationStageTypes.FINAL_CALL_SCHEDULED}>#9 Final call scheduled</MenuItem>
        <MenuItem value={ApplicationStageTypes.FINAL_CALL_COMPLETED}>#10 Final call completed</MenuItem>
        <MenuItem value={ApplicationStageTypes.PROPOSAL_SENT}>#11 Proposal sent</MenuItem>
        <MenuItem value={ApplicationStageTypes.HIRE}>Hire</MenuItem>
        <MenuItem value={ApplicationStageTypes.PROPOSAL_REJECTED}>Proposal rejected</MenuItem>
        <MenuItem value={ApplicationStageTypes.NO_HIRE}>No hire</MenuItem>
      </Select>
    </Box>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {}
}))
