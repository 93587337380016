import * as firebase from 'firebase'
import { UserInterface } from '../../rescouted-interfaces/src/interfaces/user.interface'

export const mapUserData = async (firebaseUser: firebase.User): Promise<UserInterface> => {
  const {
    uid, email, displayName, photoURL, phoneNumber
  } = firebaseUser
  const token = await firebaseUser.getIdToken(true)
  return {
    firebaseId: uid,
    email,
    token,
    name: displayName,
    picture: photoURL,
    phone: phoneNumber,
    disabled: false
  }
}
