import React, { useCallback, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Theme,
  Typography
} from '@material-ui/core'
import { Delete as ClearIcon } from '@material-ui/icons'
import { useHistory, useParams } from 'react-router-dom'
import axios from 'axios'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { ApplicationInterface } from '../../rescouted-interfaces/src/interfaces/application.interface'
import { LoadingOverlay } from '../../components/loadingSpinner/LoadingOverlay.component'
import { VideoPlayer } from '../../components/VideoPlayer/VideoPlayer.component'
import { QualificationInterface } from '../../rescouted-interfaces/src/interfaces/Qualification.interface'
import { CandidateListItem } from './CandidateListItem.component'
import { JobInterface } from '../../rescouted-interfaces/src/interfaces/job.interface'
import { CandidateQualificationChips } from './CandidateQualificationChips.component'
import { Ratings } from '../../components/Ratings/Ratings.component'
import { Starred } from '../../components/Starred/Starred.component'
import { ApplicationStageSelect } from '../../components/ApplicationStage/ApplicationStageSelect.component'
import { ApplicationStageTypes } from '../../rescouted-interfaces/src/types/ApplicationStage.types'
import { qualificationsData } from '../../rescouted-interfaces/src/data/qualifications.data'
import { LoadingSpinner } from '../../components/loadingSpinner/LoadingSpinner.component'
import { toast } from 'react-toastify'
import { CandidateActionsComponent } from '../../components/Candidate/CandidateActions.component'
import { NoteInterface } from '../../rescouted-interfaces/src/interfaces/note.interface'
import { CandidateNotesAndMessages } from './CandidateNotesAndMessages.component'
import { MailInterface } from '../../rescouted-interfaces/src/interfaces/mail.interface'
import { MailAttachmentInterface } from '../../rescouted-interfaces/src/interfaces/mailAttachment.interface'
import { PDFViewerComponent } from '../../components/PDFViewer/PDFViewer.component'
import { AsyncVideoCallInterface } from '../../rescouted-interfaces/src/interfaces/AsyncVideoCall.interface'

interface Props {}

const ratingFilters = ['=', '≥', '≤']

export const CandidatesDashboard: React.FC<Props> = () => {
  const classes = useStyles()

  const { jobId, applicationId } = useParams<{ jobId?: string; applicationId?: string }>()

  const history = useHistory()

  const [notes, setNotes] = useState<NoteInterface[]>([])
  const [mails, setMails] = useState<MailInterface[]>([])
  const [mailAttachments, setMailAttachments] = useState<MailAttachmentInterface[]>([])

  const [asyncVideoInvites, setAsyncVideoInvites] = useState<AsyncVideoCallInterface[]>([])

  const [job, setJob] = useState<JobInterface>()
  const [applications, setApplications] = useState<ApplicationInterface[]>()
  const [application, setApplication] = useState<ApplicationInterface>()
  const [qualifications] = useState<QualificationInterface[]>(qualificationsData.data)

  const [filterIsStarred, setFilterIsStarred] = useState(false)
  const [filterRating, setFilterRating] = useState<number>()
  const [filterStage, setFilterStage] = useState<ApplicationStageTypes>(ApplicationStageTypes.ANY)
  const [filterRatingStyle, setFilterRatingStyle] = useState<number>(Number(localStorage.getItem('frs') || '1'))

  const [isApplicationsLoading, setIsApplicationsLoading] = useState(false)

  const [isFetchingCandidate, setFetchingCandidate] = useState(true)

  const fetchJob = useCallback(async () => {
    const response = await axios.get(`/job/${jobId}`)
    if (response && response.status === 200 && response.data) {
      setJob(response.data)
    }
  }, [jobId])

  const fetchApplications = useCallback(async () => {
    setIsApplicationsLoading(true)
    const response = await axios.post(`/job/${jobId}/applications`, {
      filterIsStarred,
      filterRating,
      filterStage,
      direction: ratingFilters[filterRatingStyle]
    })
    if (response && response.status === 200 && response.data) {
      setApplications(response.data)
      setIsApplicationsLoading(false)
    }
  }, [jobId, filterIsStarred, filterRating, filterStage, filterRatingStyle])

  const fetchApplication = useCallback(
    async (applicationId: string) => {
      const response = await axios.get(`/job/${jobId}/application/${applicationId}`)
      if (response && response.status === 200 && response.data) {
        setApplication(response.data)
        setFetchingCandidate(false)
      }
    },
    [jobId]
  )

  useEffect(() => {
    void fetchJob()
    void fetchApplications()
  }, [])

  useEffect(() => {
    void fetchApplications()
  }, [filterIsStarred, filterRating, filterStage, filterRatingStyle])

  useEffect(() => {
    if (applicationId) {
      void fetchApplication(applicationId)
    }
  }, [applicationId])

  useEffect(() => {
    if (!applicationId && applications?.length > 0) {
      history.push(`/job/${jobId}/candidate/${applications[0]._id}`)
    }
  }, [applicationId, applications])

  const handleClickApplication = (a: ApplicationInterface) => {
    if (a._id.toString() !== application._id.toString()) {
      setFetchingCandidate(true)
      window.history.replaceState(null, 'Rescouted', `/#/job/${jobId}/candidate/${a._id.toString()}`)
      void fetchApplication(a._id.toString())
    }
  }

  const fetchApplicationNotes = useCallback(async () => {
    const response = await axios.get(`/job/${job._id}/application/${application._id}/notes`)
    if (response && response.status === 200 && response.data) {
      setNotes(response.data)
    }
  }, [application, job])

  const fetchApplicationMails = useCallback(async () => {
    const response = await axios.get(`/job/${job._id}/application/${application._id}/mails`)
    if (response && response.status === 200 && response.data) {
      setMails(response.data)
    }
  }, [application, job])

  const fetchApplicationMailAttachments = useCallback(async () => {
    const response = await axios.get(`/job/${job._id}/application/${application._id}/mails/attachments`)
    if (response && response.status === 200 && response.data) {
      setMailAttachments(response.data)
    }
  }, [application, job])

  const fetchApplicationAsyncVideoInvites = useCallback(async () => {
    const response = await axios.get(`/interview/job/${job._id}/application/${application._id}/invites`)
    if (response && response.status === 200 && response.data) {
      setAsyncVideoInvites(response.data)
    }
  }, [application, job])

  useEffect(() => {
    if (application?._id && job?._id) {
      void fetchApplicationNotes()
      void fetchApplicationMails()
      void fetchApplicationMailAttachments()
      void fetchApplicationAsyncVideoInvites()
    }
  }, [application, job])

  if (!job || !qualifications) {
    return <LoadingOverlay />
  }

  const handleInviteAsyncVideoCall = async () => {
    const response = await axios.post(`/interview/job/${job._id}/application/${application._id}/invite/async-video-call`, {})
    if (response && response.status === 200 && response.data) {
      setAsyncVideoInvites([response.data])
      toast.success(`${application.firstName} ${application.lastName} was invited to Async Video Interview successfully!`)
    }
  }

  const handleRatingClick = async (rating: number) => {
    const response = await axios.post(`/job/${job._id}/application/${application._id}/rating`, { rating })
    if (response && response.status === 200 && response.data) {
      const app = { ...application, rating }
      setApplication(app)
      setApplications(applications.map((a) => (a._id === app._id ? app : a)))
    }
  }

  const handleStarClick = async (isStarred: boolean) => {
    const response = await axios.post(`/job/${job._id}/application/${application._id}/star`, { isStarred })
    if (response && response.status === 200 && response.data) {
      const app = { ...application, isStarred }
      setApplication(app)
      setApplications(applications.map((a) => (a._id === app._id ? app : a)))
    }
  }

  const handleStageSelect = async (stage: ApplicationStageTypes) => {
    const response = await axios.post(`/job/${job._id}/application/${application._id}/stage`, { stage })
    if (response && response.status === 200 && response.data) {
      const app = { ...application, stage }
      setApplication(app)
      setApplications(applications.map((a) => (a._id === app._id ? app : a)))
    }
  }

  const handleDeleteApplicationClick = async () => {
    if (window.confirm(`Do you really want to delete ${application.firstName} ${application.lastName} application permanently?`)) {
      const response = await axios.delete(`/job/${job._id}/application/${application._id}/delete`)
      if (response && response.status === 200 && response.data) {
        toast.success(`${application.firstName} ${application.lastName} application has been deleted successfully.`)
        window.location.hash = `/candidates/${application.job}`
        void (await fetchApplications())
      }
    }
  }

  // if (applications?.length > 0) {
  //   document.addEventListener('keyup', function (event) {
  //     const currentIndex = applications.findIndex(a => a._id === application._id)
  //     if (event.ctrlKey && event.key === '.' && currentIndex + 1 < applications.length) {
  //       handleClickApplication(applications[currentIndex + 1])
  //     }
  //     if (event.ctrlKey && event.key === ',' && currentIndex - 1 > 0) {
  //       handleClickApplication(applications[currentIndex - 1])
  //     }
  //   }, { once: true });
  // }

  return (
    <Box display="flex" flexDirection="column" flexGrow={1} className={classes.root}>
      <Box display="flex" flexDirection="row" alignItems="center" flexGrow={0} py={2} px={2}>
        <Typography variant="h5">
          {job.title}
          {applications?.length > 0 ? (
            <Box ml={2} display="inline-block">
              <small>({applications.length})</small>
            </Box>
          ) : undefined}
        </Typography>
      </Box>
      <Box display="flex" flexDirection="row" flexGrow={1} overflow="hidden">
        <Box display="flex" flexDirection="column" flexGrow={0}>
          <Box flexGrow={0} py={1} px={2} className={classes.filters}>
            <Box display="flex" alignItems="center">
              <Box mr={2}>
                <IconButton
                  size="small"
                  onClick={() => {
                    setFilterIsStarred(undefined)
                    setFilterRating(undefined)
                    setFilterStage(ApplicationStageTypes.ANY)
                    setFilterRatingStyle(0)
                  }}
                >
                  <ClearIcon fontSize="small" />
                </IconButton>
              </Box>
              <Box mr={2}>
                <Starred isStarred={filterIsStarred} selectable onSelect={setFilterIsStarred} />
              </Box>
              <Box mr={2} display="flex" alignItems="center">
                <Box mr={1}>
                  <IconButton
                    size="small"
                    onClick={() => {
                      let i = 0
                      switch (filterRatingStyle) {
                        case 0:
                          i = 1
                          break
                        case 1:
                          i = 2
                          break
                        default:
                          i = 0
                          break
                      }
                      setFilterRatingStyle(i)
                      localStorage.setItem('frs', i.toString())
                    }}
                  >
                    <Typography variant="body2">{ratingFilters[filterRatingStyle]}</Typography>
                  </IconButton>
                </Box>
                <Ratings value={filterRating} selectable onSelect={setFilterRating} />
              </Box>
              <Box flexGrow={1} />
              <Box>
                <ApplicationStageSelect value={filterStage} onSelect={(st) => setFilterStage(st as ApplicationStageTypes)} />
              </Box>
            </Box>
          </Box>
          {isApplicationsLoading ? (
            <Box display="flex" justifyContent="center" py={5}>
              <LoadingSpinner />
            </Box>
          ) : undefined}
          {!isApplicationsLoading && (!applications || applications.length === 0) ? (
            <Box p={2}>
              <Typography variant="subtitle2">No applicants yet.</Typography>
            </Box>
          ) : undefined}
          {applications && applications.length > 0 ? (
            <Box pr={0} className={classes.applicants}>
              <PerfectScrollbar>
                <List>
                  {applications.map((candidate) => (
                    <CandidateListItem
                      key={candidate._id.toString()}
                      job={job}
                      candidate={candidate}
                      onClick={() => handleClickApplication(candidate)}
                      isActive={candidate._id.toString() === application?._id.toString()}
                    />
                  ))}
                </List>
              </PerfectScrollbar>
            </Box>
          ) : undefined}
        </Box>
        <Box display="flex" flexDirection="column" flexGrow={1} style={{ opacity: isFetchingCandidate ? '0.3' : '1' }}>
          <Box py={1} px={2} className={classes.actions} display="flex" alignItems="center">
            <Box display="flex" alignItems="center" mr={2}>
              <Typography>Actions</Typography>
            </Box>
            {application ? (
              <>
                <Box mr={2}>
                  <Starred isStarred={application.isStarred} selectable onSelect={handleStarClick} />
                </Box>
                <Box mr={2}>
                  <Ratings value={application.rating} selectable onSelect={handleRatingClick} />
                </Box>
                <Box pl={3}>
                  <ApplicationStageSelect value={application.stage} onSelect={handleStageSelect} noAny />
                </Box>
                <CandidateActionsComponent
                  application={application}
                  job={job}
                  onNoteAdded={() => {
                    void fetchApplicationNotes()
                  }}
                  onMailSent={() => {
                    void fetchApplicationMails()
                    void fetchApplicationMailAttachments()
                  }}
                />
                <Box>
                  {[ApplicationStageTypes.NEW, ApplicationStageTypes.IN_REVIEW].includes(application.stage) && (
                    <Button variant="contained" color="default" onClick={handleInviteAsyncVideoCall} disabled={asyncVideoInvites.length > 0}>
                      Send Async Video Interview Invite
                    </Button>
                  )}
                </Box>
              </>
            ) : undefined}
          </Box>
          {application?._id ? (
            <PerfectScrollbar>
              <Box mt={3} mb={1}>
                <Box px={2}>
                  {qualifications && application?.qualification && job?.qualification ? (
                    <CandidateQualificationChips qualifications={qualifications} application={application} job={job} />
                  ) : undefined}
                </Box>
              </Box>
              <Grid container>
                {application.step === 3 ? (
                  <Grid item md={5} sm={12}>
                    <Box p={2}>
                      {application._isRoomVideoProcessed ? (
                        <VideoPlayer url={application.answersVideoUrl}>
                          {(videoRef) => (
                            <>
                              <Box my={2}>
                                <Typography variant="subtitle1">Your questions</Typography>
                              </Box>
                              <List>
                                {application.questionsStatuses.map((q, index) => (
                                  <ListItem
                                    button
                                    key={q.index}
                                    onClick={() => {
                                      let from = 0
                                      for (let i = 0; i < index; i++) {
                                        from += application.questionsStatuses[i].duration
                                      }
                                      videoRef.current.seekTo(from)
                                    }}
                                  >
                                    {q.question.question}
                                  </ListItem>
                                ))}
                              </List>
                            </>
                          )}
                        </VideoPlayer>
                      ) : (
                        <Box
                          p={2}
                          style={{
                            border: 'thin solid #cecece'
                          }}
                        >
                          <Typography variant="h6">Video is being processed...</Typography>
                          <Typography>This might take up to 15 minutes.</Typography>
                        </Box>
                      )}
                    </Box>
                  </Grid>
                ) : undefined}
                <Grid item md={7} sm={12}>
                  <Box p={2} mt={2} mb={3}>
                    <TableContainer>
                      <Table>
                        <TableBody>
                          {[
                            ['Name', `${application.firstName} ${application.lastName}`],
                            ['Email', <a href={`mailto:${application.email}`}>{application.email}</a>],
                            ['Phone', <a href={`tel:${application.phone}`}>{application.phone}</a>],
                            ['Headline', application.headline],
                            ['Linkedin', application.linkedin],
                            ['Location', application.location],
                            ['Primary Qualification', application.qualification]
                          ].map((item) => (
                            <TableRow key={item[0].toString()}>
                              <TableCell component="th" scope="row" size="small">
                                {item[0]}
                              </TableCell>
                              <TableCell>{item[1]}</TableCell>
                            </TableRow>
                          ))}
                          <TableRow>
                            <TableCell component="th" scope="row" size="small">
                              CV
                            </TableCell>
                            <TableCell>
                              <a href={application.cv} target="_blank" rel="noreferrer">
                                {application.firstName} CV
                              </a>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                  {application?.cv && (
                    <Box>
                      {/*  CV preview pdf */}
                      <PDFViewerComponent url={application.cv} />
                    </Box>
                  )}
                </Grid>
              </Grid>
              <Grid container>
                <Grid item sm={12} style={{ backgroundColor: '#eeeeee' }}>
                  <Box mt={3} p={2}>
                    {application && job ? <CandidateNotesAndMessages notes={notes} mails={mails} application={application} job={job} /> : undefined}
                  </Box>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item sm={12} style={{ backgroundColor: '#ffffff' }}>
                  <Box py={5} display="flex" justifyContent="center">
                    <Button size="small" variant="text" onClick={handleDeleteApplicationClick}>
                      Delete candidate application
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </PerfectScrollbar>
          ) : undefined}
        </Box>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    // position: 'fixed',
    // bottom: 0,
    // top: '64px',
    // left: 0,
    // right: 0,
    // height: 'auto'
    overflow: 'hidden'
  },
  activeItem: {
    backgroundColor: theme.palette.primary.main
  },
  filters: {
    backgroundColor: '#cccccc',
    borderColor: '#cccccc',
    borderStyle: 'solid',
    borderWidth: 'thin',
    width: 353
  },
  actions: {
    borderColor: '#cccccc',
    borderStyle: 'solid',
    borderWidth: 'thin',
    borderRightColor: 'transparent',
    borderLeftColor: 'transparent'
  },
  applicants: {
    // boxShadow: 'inset 0px 0px 60px #00000020'
    borderRight: 'thin solid #cccccc',
    minWidth: 290,
    overflow: 'hidden'
  }
}))
