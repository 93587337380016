import React from 'react'
import ReactDOM from 'react-dom/client'
import { ToastContainer } from 'react-toastify'
import './index.css'
import 'react-toastify/dist/ReactToastify.css'
import { CssBaseline, ThemeProvider } from '@material-ui/core'
import axios from 'axios'
import Application from './Application'
import reportWebVitals from './reportWebVitals'
import { AuthContextProvider } from './utils/auth/useUser'
import theme from './theme'
import { QueryClientProvider } from '@tanstack/react-query'
import { queryClient } from './queryClient'

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  // <React.StrictMode>
  <ThemeProvider theme={theme}>
    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
    <CssBaseline />
    <AuthContextProvider>
      <QueryClientProvider client={queryClient}>
        <ToastContainer />
        <Application />
      </QueryClientProvider>
    </AuthContextProvider>
  </ThemeProvider>
  // </React.StrictMode>
)

// ReactDOM.render(
//   // <React.StrictMode>
//   <ThemeProvider theme={theme}>
//     {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
//     <CssBaseline />
//     <AuthContextProvider>
//       <Application />
//     </AuthContextProvider>
//   </ThemeProvider>, // </React.StrictMode>
//
//   document.getElementById('root')
// )

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
