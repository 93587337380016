import React, { Suspense, useEffect } from 'react'
import { HashRouter as Router, Switch, Route } from 'react-router-dom'
import { Box } from '@material-ui/core'
import { UserInterface } from '../rescouted-interfaces/src/interfaces/user.interface'
import { NavBar } from './NavBar/NavBar.component'
import { CompanyContextProvider } from '../hooks/useCompany'
import { JobsList } from './Jobs/JobsList.component'
import { useUser } from '../utils/auth/useUser'
import { LoadingOverlay } from '../components/loadingSpinner/LoadingOverlay.component'
import { JobEdit } from './Jobs/JobEdit.component'
import { CandidatesDashboard } from './Candidates/CandidatesDashboard.component'
import { CompanyTeamMembers } from './Companies/CompanyTeamMembers.component'
import 'react-perfect-scrollbar/dist/css/styles.css'
import { UserJourneyStepsKeys, userJourneySteps } from '../components/UserJourney/UserJourney.component'
import { CompanyPage } from './Companies/CompanyForm.component'

interface Props {
  user: UserInterface
}

export const App: React.FC<Props> = ({ user }) => {
  const { isAuthenticated } = useUser()
  const [userJourneyStep, setUserJourneyStep] = React.useState<UserJourneyStepsKeys>('create-account')

  // Every 3 seconds, increment the user journey step and cycle back to the first step.
  // Only to show the user journey component in action and should be removed after the backend is implemented.
  useEffect(() => {
    const interval = setInterval(() => {
      setUserJourneyStep((currentStep): UserJourneyStepsKeys => {
        const stepsKeys = Object.keys(userJourneySteps) as unknown as UserJourneyStepsKeys[]
        const currentStepIndex = userJourneySteps[currentStep].index
        const nextStepIndex = currentStepIndex + 1

        if (nextStepIndex === stepsKeys.length) {
          return stepsKeys[0]
        }

        return stepsKeys[nextStepIndex]
      })
    }, 3000)

    return () => clearInterval(interval)
  }, [])

  if (!isAuthenticated) {
    return <LoadingOverlay />
  }

  return (
    <Suspense fallback={<LoadingOverlay />}>
      <CompanyContextProvider>
        <Router>
          <>
            <NavBar />
            <Box display="flex" flexGrow={1} style={{ overflow: 'hidden' }}>
              <Box flexGrow={1} display="flex" flexDirection="column" style={{ overflow: 'hidden' }}>
                {/* <UserJourney currentStep={userJourneyStep} /> */}
                <Switch>
                  <Route path="/candidates/:jobId">
                    <CandidatesDashboard />
                  </Route>
                  <Route path="/job/:jobId/candidate/:applicationId">
                    <CandidatesDashboard />
                  </Route>
                  <Route path="/company/:companyId/team">
                    <CompanyTeamMembers />
                  </Route>
                  <Route path="/company/:companyId">
                    <CompanyPage />
                  </Route>
                  <Route path="/company">
                    <CompanyPage />
                  </Route>

                  <Route path="/job/:jobId">
                    <JobEdit />
                  </Route>
                  <Route path="/job">
                    <JobEdit />
                  </Route>
                  <Route path="/">
                    <JobsList />
                  </Route>
                </Switch>
              </Box>
            </Box>
          </>
        </Router>

        {/* <div style={{ height: 150 }} /> */}

        {/* <Typography variant="subtitle1">You're signed in. Email: {user.email} Name: {user.name}</Typography> */}
      </CompanyContextProvider>
    </Suspense>
  )
}
