export enum ApplicationStageTypes {
  // new/in review/call scheduled/home task/meeting/proposal sent/hired/proposal rejected
  ANY = '',
  NEW = 'NEW',
  IN_REVIEW = 'IN_REVIEW',
  CALL_SENT = 'CALL_SENT',
  CALL_COMPLETED = 'CALL_COMPLETED',
  TASK_1_SENT = 'TASK_1_SENT',
  TASK_1_COMPLETED = 'TASK_1_COMPLETED',
  TASK_2_SENT = 'TASK_2_SENT',
  TASK_2_COMPLETED = 'TASK_2_COMPLETED',
  FINAL_CALL_SENT = 'FINAL_CALL_SENT',
  FINAL_CALL_SCHEDULED = 'FINAL_CALL_SCHEDULED',
  FINAL_CALL_COMPLETED = 'FINAL_CALL_COMPLETED',
  PROPOSAL_SENT = 'PROPOSAL_SENT',
  HIRE = 'HIRE',
  PROPOSAL_REJECTED = 'PROPOSAL_REJECTED',
  NO_HIRE = 'NO_HIRE',
}
