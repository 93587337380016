import { ApplicationStageTypes } from '../types/ApplicationStage.types'
// import { MenuItem } from '@material-ui/core'
// import React from 'react'

const labels = {
  [ApplicationStageTypes.ANY]: '',
  [ApplicationStageTypes.NEW]: 'New',
  [ApplicationStageTypes.IN_REVIEW]: 'In review',
  [ApplicationStageTypes.CALL_SENT]: 'Async video call sent',
  [ApplicationStageTypes.CALL_COMPLETED]: 'Async video call completed',
  [ApplicationStageTypes.TASK_1_SENT]: 'Task 1 sent',
  [ApplicationStageTypes.TASK_1_COMPLETED]: 'Task 1 completed',
  [ApplicationStageTypes.TASK_2_SENT]: 'Task 2 sent',
  [ApplicationStageTypes.TASK_2_COMPLETED]: 'Task 2 completed',
  [ApplicationStageTypes.FINAL_CALL_SENT]: 'Final call invited',
  [ApplicationStageTypes.FINAL_CALL_SCHEDULED]: 'Final call scheduled',
  [ApplicationStageTypes.FINAL_CALL_COMPLETED]: 'Final call completed',
  [ApplicationStageTypes.PROPOSAL_SENT]: 'Proposal sent',
  [ApplicationStageTypes.HIRE]: 'Hire',
  [ApplicationStageTypes.PROPOSAL_REJECTED]: 'Proposal rejected',
  [ApplicationStageTypes.NO_HIRE]: 'No hire'
}

export const applicationStageTypeToLabel = (type: ApplicationStageTypes) => {
  if (type in labels) {
    return labels[type]
  }
  return ''
}
