import React, { FC, useRef, useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import { Box, Button, makeStyles, Theme, Typography } from '@material-ui/core'

import 'react-pdf/dist/Page/AnnotationLayer.css'
import 'react-pdf/dist/Page/TextLayer.css'

pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.mjs', import.meta.url).toString()

interface Props {
  url: string
}

export const PDFViewerComponent: FC<Props> = ({ url }) => {
  const classes = useStyles()

  const [pdfWidth, setPdfWidth] = useState(600)

  const [numPages, setNumPages] = useState<number>()
  const [pageNumber, setPageNumber] = useState<number>(1)

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }): void => {
    setNumPages(numPages)
    const wrp = document.getElementById('pdfWrapper').offsetWidth
    setPdfWidth(wrp - 32) // minus 32 because we have padding in the wrapper of 16 each side
  }

  return (
    <Box className={classes.root} id="pdfWrapper" my={3}>
      <Document file={url} onLoadSuccess={onDocumentLoadSuccess} externalLinkTarget="_blank">
        <Page pageNumber={pageNumber} canvasBackground="#fff" width={pdfWidth} />
      </Document>
      <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" style={{ gap: '16px' }}>
        <Button disabled={numPages <= 1} onClick={() => setPageNumber(pageNumber - 1)}>
          {'<'}
        </Button>
        <Typography>
          Page {pageNumber} of {numPages}
        </Typography>
        <Button disabled={pageNumber === numPages} onClick={() => setPageNumber(pageNumber + 1)}>
          {'>'}
        </Button>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: '#efefef',
    border: '3px solid #dedede',
    width: '100%',
    padding: '16px'
  }
}))
