import { QualificationInterface, QualificationPartialInterface } from '../rescouted-interfaces/src/interfaces/Qualification.interface'

export const _resolveQualification = (key: string, _qualifications: QualificationInterface[]): QualificationInterface => {
  if (!key) {
    return { key: '', title: '' }
  }
  return _qualifications?.filter((q) => q.key === key)[0] as QualificationInterface
}

export const _resolveQualificationPartials = (
  keysString: string,
  qualification: QualificationInterface,
  prop: string
): QualificationPartialInterface[] => {
  if (!keysString) {
    return []
  }
  if (
    qualification &&
    typeof qualification !== typeof undefined &&
    prop in qualification &&
    typeof qualification[prop] !== typeof undefined &&
    Array.isArray(qualification[prop])
  ) {
    return (qualification[prop] as QualificationPartialInterface[]).filter(
      (q) => q && keysString.split(',').includes(q.key)
    ) as QualificationPartialInterface[]
  }
  return []
}

export const _qualificationProps = [
  'skills',
  'managerialLevel',
  'department',
  'currentCompanyRevenue',
  'companySize',
  'industries',
  'programmingTools',
  'tools',
  'institutionTypes',
  'businessAreas',
  'averageDealValue',
  'storeTypes',
  'languages',
  'seniorityLevel'
]
