import { Box, makeStyles, Theme } from '@material-ui/core'
import React from 'react'
import clsx from 'clsx'

interface Props {
  hoverValue?: number
  value?: number
  valuePosition: number
  onClick: (v: number) => void
}

export const Rating: React.FC<Props> = ({ valuePosition, hoverValue, value, onClick }) => {
  const classes = useStyles()
  return <Box className={clsx(classes.square, valuePosition <= value && classes.value, `val${value}`)} onClick={() => onClick(valuePosition)} />
}

const useStyles = makeStyles((theme: Theme) => ({
  square: {
    width: theme.spacing(1.75),
    height: theme.spacing(1.75),
    border: 'thin solid black',
    marginRight: 2,
    borderRadius: 10,

    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#cecece'
    }
  },
  value: {
    '&.val1': {
      backgroundColor: 'red'
    },
    '&.val2': {
      backgroundColor: 'red'
    },
    '&.val3': {
      backgroundColor: 'yellow'
    },
    '&.val4': {
      backgroundColor: 'green'
    },
    '&.val5': {
      backgroundColor: 'green'
    }
  }
}))
