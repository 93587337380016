import * as React from 'react'
import { createRef, useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import axios, { AxiosResponse } from 'axios'
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  makeStyles,
  MenuItem,
  Select,
  Switch,
  TextField,
  Theme,
  Typography
} from '@material-ui/core'
import ReactPlayer from 'react-player'
import { JobInterface } from '../../rescouted-interfaces/src/interfaces/job.interface'
import { LoadingOverlay } from '../../components/loadingSpinner/LoadingOverlay.component'
import { useCompany } from '../../hooks/useCompany'
import { useStateCallback } from '../../hooks/useStateCallback'
import { QualificationInterface, QualificationPartialInterface } from '../../rescouted-interfaces/src/interfaces/Qualification.interface'
import { JobStatusTypes } from '../../rescouted-interfaces/src/types/JobStatus.types'
import { QualificationAutocomplete } from '../../components/QualificationAutocomplete/QualificationAutocomplete.component'
import { RTEditor } from '../RTEditor/RTEditor.component'
import { qualificationsData } from '../../rescouted-interfaces/src/data/qualifications.data'
import { SalaryTypes } from '../../rescouted-interfaces/src/types/Salary.types'
import { CurrencyTypes } from '../../rescouted-interfaces/src/types/Currency.types'
import { TimezoneInterface } from '../../rescouted-interfaces/src/interfaces/Timezone.interface'
import { timezonesData } from '../../rescouted-interfaces/src/data/timezones.data'
import { LoadingSpinner } from '../../components/loadingSpinner/LoadingSpinner.component'
import { MediaInterface } from '../../rescouted-interfaces/src/interfaces/media.interface'
import { Delete } from '@material-ui/icons'
import { toast } from 'react-toastify'

const defaultTimezoneFrom = {
  value: 'Dateline Standard Time',
  abbr: 'DST',
  offset: -12,
  isdst: false,
  text: '(UTC-12:00) International Date Line West',
  utc: ['Etc/GMT+12']
}

const defaultTimezoneTo = {
  value: 'Samoa Standard Time',
  abbr: 'SST',
  offset: 13,
  isdst: false,
  text: '(UTC+13:00) Samoa',
  utc: ['Pacific/Apia']
}

export const JobEditDetails: React.FC = () => {
  const richEditorHeight = 250

  const classes = useStyles()
  const history = useHistory()
  const { jobId } = useParams<{ jobId?: string }>()
  const [_job, setJob] = useState<JobInterface>()
  const [_qualifications, setQualifications] = useState<QualificationInterface[]>(qualificationsData.data)
  const { company } = useCompany()

  const [isVideoLoading, setIsVideoLoading] = useState(false)

  const fetchJob = useCallback(async () => {
    if (company?._id) {
      try {
        const response = await axios.get(`/job/${jobId}`)
        if (response && response.status === 200 && response.data) {
          setJob(response.data)
          console.log('fetched job', response.data)
        }
      } catch (err) {
        console.error(err)
      }
    }
  }, [jobId, company])

  useEffect(() => {
    if (jobId) {
      setIsFullyLoaded(false)
      void fetchJob()
    } else {
      setIsFullyLoaded(false, () => {
        setTitle('')
        setPublishStatus(JobStatusTypes.DRAFT)

        setVideo('')
        setLocation('')
        setDescription('')
        setResponsibilities('')
        setRequirements('')
        setBenefits('')
        setSalaryFrom(1000)
        setSalaryTo(6000)
        setSalaryType(SalaryTypes.MONTHLY)
        setCurrency(CurrencyTypes.EUR)
        setTimezoneFrom(defaultTimezoneFrom)
        setTimezoneTo(defaultTimezoneTo)
        setTags([])

        setIsFullTime(false)
        setIsPartTime(false)
        setIsContract(false)
        setIsFullyRemote(false)
        setIsPartiallyRemote(false)

        setQualification({ key: '', title: '' })
        resetQualificationSettings()
        setIsFullyLoaded(true)
      })
    }
  }, [fetchJob, jobId, history])

  const _resolveQualification = useCallback(
    (key: string): QualificationInterface => {
      if (!key) {
        return { key: '', title: '' }
      }
      return _qualifications?.filter((q) => q.key === key)[0] as QualificationInterface
    },
    [_qualifications]
  )

  const _resolveQualificationPartials = (
    keysString: string,
    qualification: QualificationInterface,
    prop: string
  ): QualificationPartialInterface[] => {
    if (!keysString) {
      return []
    }
    if (
      qualification &&
      typeof qualification !== typeof undefined &&
      prop in qualification &&
      typeof qualification[prop] !== typeof undefined &&
      Array.isArray(qualification[prop])
    ) {
      return (qualification[prop] as QualificationPartialInterface[]).filter(
        (q) => q && keysString.split(',').includes(q.key)
      ) as QualificationPartialInterface[]
    }
    return []
  }

  const [title, setTitle] = useState<string>('')
  const [location, setLocation] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [responsibilities, setResponsibilities] = useState<string>('')
  const [requirements, setRequirements] = useState<string>('')
  const [benefits, setBenefits] = useState<string>('')
  const [salaryFrom, setSalaryFrom] = useState<number>(1000)
  const [salaryTo, setSalaryTo] = useState<number>(6000)
  const [salaryType, setSalaryType] = useState<string>(SalaryTypes.MONTHLY)
  const [currency, setCurrency] = useState<string>(CurrencyTypes.EUR)
  const [timezoneFrom, setTimezoneFrom] = useState<TimezoneInterface>(defaultTimezoneFrom)
  const [timezoneTo, setTimezoneTo] = useState<TimezoneInterface>(defaultTimezoneTo)
  const [video, setVideo] = useState<string>('')
  const [tags, setTags] = useState<string[]>([])

  const [shareImage, setShareImage] = useState<string>('')

  const [isPublished, setIsPublished] = useState<boolean>()

  const [isFullTime, setIsFullTime] = useState<boolean>()
  const [isPartTime, setIsPartTime] = useState<boolean>()
  const [isContract, setIsContract] = useState<boolean>()
  const [isFullyRemote, setIsFullyRemote] = useState<boolean>()
  const [isPartiallyRemote, setIsPartiallyRemote] = useState<boolean>()

  const [qualification, setQualification] = useState<QualificationInterface>({ key: '', title: '' })
  const [skills, setSkills] = useState<QualificationPartialInterface[]>([])
  const [programmingTools, setProgrammingTools] = useState<QualificationPartialInterface[]>([])
  const [seniorityLevel, setSeniorityLevel] = useState<QualificationPartialInterface[]>([])
  const [managerialLevel, setManagerialLevel] = useState<QualificationPartialInterface[]>([])
  const [department, setDepartment] = useState<QualificationPartialInterface[]>([])
  const [currentCompanyRevenue, setCurrentCompanyRevenue] = useState<QualificationPartialInterface[]>([])
  const [companySize, setCompanySize] = useState<QualificationPartialInterface[]>([])
  const [industries, setIndustries] = useState<QualificationPartialInterface[]>([])
  const [tools, setTools] = useState<QualificationPartialInterface[]>([])
  const [institutionTypes, setInstitutionTypes] = useState<QualificationPartialInterface[]>([])
  const [businessAreas, setBusinessAreas] = useState<QualificationPartialInterface[]>([])
  const [averageDealValue, setAverageDealValue] = useState<QualificationPartialInterface[]>([])
  const [storeTypes, setStoreTypes] = useState<QualificationPartialInterface[]>([])
  const [languages, setLanguages] = useState<QualificationPartialInterface[]>([])

  const [publishStatus, setPublishStatus] = useState<JobStatusTypes>(JobStatusTypes.DRAFT)

  const [isFullyLoaded, setIsFullyLoaded] = useStateCallback(false)

  const setJobParams = useCallback(
    (__job: JobInterface) => {
      setTitle(__job.title!)
      setPublishStatus(__job.publishStatus!)

      setVideo(__job.video)
      setLocation(__job.location)
      setDescription(__job.description)
      setResponsibilities(__job.responsibilities)
      setRequirements(__job.requirements)
      setBenefits(__job.benefits)
      setSalaryFrom(__job.salaryFrom || 1000)
      setSalaryTo(__job.salaryTo || 6000)
      setSalaryType(__job.salaryType)
      setCurrency(__job.currency)
      setTimezoneFrom(__job.timezoneFrom)
      setTimezoneTo(__job.timezoneTo)
      setTags(__job.tags)

      setShareImage(__job.shareImage)

      setIsPublished(__job.publishStatus === JobStatusTypes.PUBLISHED)

      setIsFullTime(Boolean(__job.isFullTime))
      setIsPartTime(Boolean(__job.isPartTime))
      setIsContract(Boolean(__job.isContract))
      setIsFullyRemote(Boolean(__job.isFullyRemote))
      setIsPartiallyRemote(Boolean(__job.isPartiallyRemote))

      const __qualification = _resolveQualification(__job.qualification!)
      setQualification(__qualification)
      setSkills(_resolveQualificationPartials(__job.skills!, __qualification, 'skills'))
      setProgrammingTools(_resolveQualificationPartials(__job.programmingTools!, __qualification, 'programmingTools'))
      setSeniorityLevel(_resolveQualificationPartials(__job.seniorityLevel!, __qualification, 'seniorityLevel'))
      setManagerialLevel(_resolveQualificationPartials(__job.managerialLevel!, __qualification, 'managerialLevel'))
      setDepartment(_resolveQualificationPartials(__job.department!, __qualification, 'department'))
      setCurrentCompanyRevenue(_resolveQualificationPartials(__job.currentCompanyRevenue!, __qualification, 'currentCompanyRevenue'))
      setCompanySize(_resolveQualificationPartials(__job.companySize!, __qualification, 'companySize'))
      setIndustries(_resolveQualificationPartials(__job.industries!, __qualification, 'industries'))
      setTools(_resolveQualificationPartials(__job.tools!, __qualification, 'tools'))
      setInstitutionTypes(_resolveQualificationPartials(__job.institutionTypes!, __qualification, 'institutionTypes'))
      setBusinessAreas(_resolveQualificationPartials(__job.businessAreas!, __qualification, 'businessAreas'))
      setAverageDealValue(_resolveQualificationPartials(__job.averageDealValue!, __qualification, 'averageDealValue'))
      setStoreTypes(_resolveQualificationPartials(__job.storeTypes!, __qualification, 'storeTypes'))
      setLanguages(_resolveQualificationPartials(__job.languages!, __qualification, 'languages'))

      setIsFullyLoaded(true)
    },
    [_resolveQualification]
  )

  const resetQualificationSettings = () => {
    setSkills([])
    setProgrammingTools([])
    setSeniorityLevel([])
    setManagerialLevel([])
    setDepartment([])
    setCurrentCompanyRevenue([])
    setCompanySize([])
    setIndustries([])
    setTools([])
    setInstitutionTypes([])
    setBusinessAreas([])
    setAverageDealValue([])
    setStoreTypes([])
    setLanguages([])
    setIsPublished(false)
  }

  const getJobObject = () =>
    ({
      ...(jobId && { _id: _job._id.toString() }),

      company: company._id.toString(),

      video,
      title,
      description,
      requirements,
      benefits,

      shareImage,

      location,
      tags,
      responsibilities,

      salaryFrom,
      salaryTo,
      salaryType,
      currency,
      timezoneFrom,
      timezoneTo,

      publishStatus: isPublished ? JobStatusTypes.PUBLISHED : JobStatusTypes.UNPUBLISHED,

      isFullTime,
      isPartTime,
      isContract,
      isFullyRemote,
      isPartiallyRemote,

      qualification: qualification?.key || '',
      skills: skills.map((x) => x.key).join(','),
      programmingTools: programmingTools.map((x) => x.key).join(','),
      seniorityLevel: seniorityLevel.map((x) => x.key).join(','),
      managerialLevel: managerialLevel.map((x) => x.key).join(','),
      department: department.map((x) => x.key).join(','),
      currentCompanyRevenue: currentCompanyRevenue.map((x) => x.key).join(','),
      companySize: companySize.map((x) => x.key).join(','),
      industries: industries.map((x) => x.key).join(','),
      tools: tools.map((x) => x.key).join(','),
      institutionTypes: institutionTypes.map((x) => x.key).join(','),
      businessAreas: businessAreas.map((x) => x.key).join(','),
      averageDealValue: averageDealValue.map((x) => x.key).join(','),
      storeTypes: storeTypes.map((x) => x.key).join(','),
      languages: languages.map((x) => x.key).join(',')
    } as JobInterface)

  const shareImageInputRef = createRef<HTMLInputElement>()
  const videoInputRef = createRef<HTMLInputElement>()

  const handleShareImageFileInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setShareImage('')
    if (event.target.files && event.target.files.length > 0) {
      const image = event.target.files[0]
      const formData = new FormData()
      formData.append('image', image)
      const response = await axios.post<any, AxiosResponse<MediaInterface>>(`/media/image/${company._id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      if (response && response.data) {
        setShareImage(response.data.cdnUrl)
      }
    }
  }

  const handleVideoFileInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsVideoLoading(true)
    setVideo('')
    if (event.target.files && event.target.files.length > 0) {
      const v = event.target.files[0]
      const formData = new FormData()
      formData.append('video', v)
      const response = await axios.post<any, AxiosResponse<MediaInterface>>(`/media/video/${company._id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      if (response && response.data) {
        setVideo(response.data.hlsUrl || response.data.cdnUrl)
        setIsVideoLoading(false)
      }
    }
  }

  const setJobParamsFn = useCallback(() => {
    if (_job && _qualifications) {
      // @ts-ignore
      setJobParams(_job)
    }
  }, [_job, _qualifications, setJobParams])

  useEffect(() => {
    if (!jobId) {
      // creating a new job
      return setIsFullyLoaded(true)
    }
    setJobParamsFn()
  }, [setJobParamsFn])

  const handlePublishClick = async () => {
    const uri = jobId ? `/job/${_job._id}` : '/job'

    const resp = await axios.post(uri, getJobObject(), { headers: { 'Content-Type': 'application/json' } })
    if (resp && resp.status === 200 && resp.data) {
      console.log(resp.data)
      if (jobId) setJob(resp.data)
      else history.push(`/job/${resp.data._doc._id}?questions`)

      toast.success(`Job has been ${jobId ? 'updated' : 'created'} successfully.`)
      history.push('/jobs')
    }
    console.log('axios', axios.defaults.headers)
  }

  const handlePublishToggle = async () => {
    setIsPublished(!isPublished)
  }

  if ((jobId && !_job) || !company || !isFullyLoaded) {
    return <LoadingOverlay />
  }

  return (
    <Container maxWidth="md">
      <Box my={4}>
        <Box display="flex" justifyContent="space-between" mb={4}>
          <Box display="flex" alignItems="center">
            {_job?._id && (
              <>
                <Box pr={1}>
                  <Switch
                    checked={isPublished}
                    edge="start"
                    name="isPublished"
                    onChange={handlePublishToggle}
                    disabled={_job.questions.length === 0}
                  />
                </Box>
                <Box>
                  <Typography variant="body1" color="textPrimary">
                    Published
                  </Typography>
                </Box>
              </>
            )}
          </Box>
          <Button onClick={handlePublishClick} variant="contained" color="primary">
            Save
          </Button>
        </Box>
        {_job?._id && _job.questions.length === 0 && (
          <>
            <Typography variant="subtitle1" color="error">
              To publish the job, add the interview questions.
            </Typography>
          </>
        )}

        <Grid container>
          <Grid item sm={12}>
            <div className={classes.inputGroup}>
              <TextField fullWidth label="Job Title" className={classes.title} value={title || ''} onChange={(e) => setTitle(e.target.value)} />
            </div>
          </Grid>

          <Grid item sm={12}>
            <Grid item className={classes.checkboxesWrapper} sm={6}>
              <div className={classes.inputGroup}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={Boolean(isFullTime)}
                        onChange={(event, checked) => setIsFullTime(checked)}
                        value="fulltime"
                        color="primary"
                      />
                    }
                    label="Full-time"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={Boolean(isPartTime)}
                        onChange={(event, checked) => setIsPartTime(checked)}
                        value="parttime"
                        color="primary"
                      />
                    }
                    label="Part-time"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={Boolean(isContract)}
                        onChange={(event, checked) => setIsContract(checked)}
                        value="contract"
                        color="primary"
                      />
                    }
                    label="Contract"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={Boolean(isFullyRemote)}
                        onChange={(event, checked) => setIsFullyRemote(checked)}
                        value="fullyremote"
                        color="secondary"
                      />
                    }
                    label="Fully Remote"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={Boolean(isPartiallyRemote)}
                        onChange={(event, checked) => setIsPartiallyRemote(checked)}
                        value="partiallyremote"
                        color="secondary"
                      />
                    }
                    label="Partially Remote"
                  />
                </FormGroup>
              </div>
            </Grid>

            <Grid item sm={12}>
              <Box mb={4}>
                <Box display="flex" flexDirection="row">
                  <Box flexGrow={1}>
                    <div className={classes.inputGroup}>
                      <TextField
                        fullWidth
                        label="Salary From"
                        className={classes.title}
                        value={salaryFrom}
                        onChange={(e) => {
                          if (Number(e.target.value)) setSalaryFrom(Number(e.target.value))
                        }}
                      />
                    </div>
                  </Box>
                  <Box alignSelf="center" p={2}>
                    -
                  </Box>
                  <Box flexGrow={1}>
                    <div className={classes.inputGroup}>
                      <TextField
                        fullWidth
                        label="Salary To"
                        className={classes.title}
                        value={salaryTo}
                        onChange={(e) => {
                          if (Number(e.target.value)) setSalaryTo(Number(e.target.value))
                        }}
                      />
                    </div>
                  </Box>
                </Box>
                <Box display="flex" flexDirection="row">
                  <Box flexGrow={1}>
                    <div className={classes.inputGroup}>
                      <Select
                        value={salaryType}
                        onChange={(e: React.ChangeEvent<{ value: SalaryTypes }>) => setSalaryType(e.target.value)}
                        label="Payment type"
                      >
                        <MenuItem value={SalaryTypes.HOURLY}>per hour</MenuItem>
                        <MenuItem value={SalaryTypes.DAILY}>per day</MenuItem>
                        <MenuItem value={SalaryTypes.WEEKLY}>per week</MenuItem>
                        <MenuItem value={SalaryTypes.MONTHLY}>per month</MenuItem>
                        <MenuItem value={SalaryTypes.ANNUAL}>per year</MenuItem>
                      </Select>
                    </div>
                  </Box>
                  <Box alignSelf="center" p={2} />
                  <Box flexGrow={1}>
                    <div className={classes.inputGroup}>
                      <Select
                        value={currency}
                        onChange={(e: React.ChangeEvent<{ value: CurrencyTypes }>) => setCurrency(e.target.value)}
                        label="Currency"
                      >
                        <MenuItem value={CurrencyTypes.USD}>USD $</MenuItem>
                        <MenuItem value={CurrencyTypes.EUR}>EUR €</MenuItem>
                        <MenuItem value={CurrencyTypes.GBP}>GBP £</MenuItem>
                      </Select>
                    </div>
                  </Box>
                </Box>
              </Box>
            </Grid>

            <Grid item sm={6}>
              <div className={classes.inputGroup}>
                <TextField
                  fullWidth
                  label="Location"
                  className={classes.title}
                  value={location || ''}
                  onChange={(e) => setLocation(e.target.value)}
                />
              </div>
            </Grid>

            <Grid item sm={12}>
              <Box mb={5}>
                <Box>
                  <div className={classes.inputGroup}>
                    <Select
                      value={timezoneFrom.value}
                      onChange={(e: React.ChangeEvent<{ value: string }>) => setTimezoneFrom(timezonesData.find((tz) => tz.value === e.target.value))}
                      label="Timezone from preference"
                    >
                      {timezonesData
                        .filter((t) => t.offset <= timezoneTo.offset)
                        .map((tz: TimezoneInterface) => (
                          <MenuItem key={tz.value} value={tz.value}>
                            {tz.text}
                          </MenuItem>
                        ))}
                    </Select>
                  </div>
                </Box>
                <Box>
                  <div className={classes.inputGroup}>
                    <Select
                      value={timezoneTo.value}
                      onChange={(e: React.ChangeEvent<{ value: string }>) => setTimezoneTo(timezonesData.find((tz) => tz.value === e.target.value))}
                      label="Timezone to preference "
                    >
                      {timezonesData
                        .filter((t) => t.offset >= timezoneFrom.offset)
                        .map((tz: TimezoneInterface) => (
                          <MenuItem key={tz.value} value={tz.value}>
                            {tz.text}
                          </MenuItem>
                        ))}
                    </Select>
                  </div>
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Grid item sm={12}>
            <div className={classes.inputGroup}>
              <RTEditor value={description || ''} height={richEditorHeight} onChange={(value) => setDescription(value)} label="Job Description" />
            </div>
            <div className={classes.inputGroup}>
              <RTEditor value={requirements || ''} height={richEditorHeight} onChange={(value) => setRequirements(value)} label="Job Requirements" />
            </div>
            <div className={classes.inputGroup}>
              <RTEditor
                value={responsibilities || ''}
                height={richEditorHeight}
                onChange={(value) => setResponsibilities(value)}
                label="Key Responsibilities"
              />
            </div>
            <div className={classes.inputGroup}>
              <RTEditor value={benefits || ''} height={richEditorHeight} onChange={(value) => setBenefits(value)} label="Benefits" />
            </div>
          </Grid>

          <Grid item sm={12}>
            <Typography variant="h5">Targeting</Typography>

            {_qualifications && qualification && (
              <div className={classes.inputGroup}>
                <QualificationAutocomplete
                  onChange={(value) => setQualification(value as QualificationInterface)}
                  value={qualification}
                  label="Qualification"
                  multiple={false}
                  options={_qualifications}
                  placeholder={undefined}
                />
              </div>
            )}

            {qualification && qualification.skills && skills && (
              <div className={classes.inputGroup}>
                <QualificationAutocomplete
                  onChange={(value) => setSkills(value as QualificationPartialInterface[])}
                  onSelect={() => resetQualificationSettings()}
                  value={skills}
                  label="Skills"
                  multiple
                  options={qualification.skills}
                  placeholder={undefined}
                />
              </div>
            )}

            {qualification?.programmingTools && (
              <div className={classes.inputGroup}>
                <QualificationAutocomplete
                  onChange={(value) => setProgrammingTools(value as QualificationPartialInterface[])}
                  value={programmingTools}
                  label="Programming tools & languages"
                  multiple
                  options={qualification.programmingTools}
                  placeholder={undefined}
                />
              </div>
            )}

            {qualification?.managerialLevel && (
              <div className={classes.inputGroup}>
                <QualificationAutocomplete
                  onChange={(value) => setManagerialLevel(value as QualificationPartialInterface[])}
                  value={managerialLevel}
                  label="Managerial level"
                  multiple
                  options={qualification.managerialLevel}
                  placeholder={undefined}
                />
              </div>
            )}

            {qualification?.department && (
              <div className={classes.inputGroup}>
                <QualificationAutocomplete
                  onChange={(value) => setDepartment(value as QualificationPartialInterface[])}
                  value={department}
                  label="Department"
                  multiple
                  options={qualification.department}
                  placeholder={undefined}
                />
              </div>
            )}

            {qualification?.currentCompanyRevenue && (
              <div className={classes.inputGroup}>
                <QualificationAutocomplete
                  onChange={(value) => setCurrentCompanyRevenue(value as QualificationPartialInterface[])}
                  value={currentCompanyRevenue}
                  label="Current company revenue"
                  multiple
                  options={qualification.currentCompanyRevenue}
                  placeholder={undefined}
                />
              </div>
            )}

            {qualification?.companySize && (
              <div className={classes.inputGroup}>
                <QualificationAutocomplete
                  onChange={(value) => setCompanySize(value as QualificationPartialInterface[])}
                  value={companySize}
                  label="Company size"
                  multiple
                  options={qualification.companySize}
                  placeholder={undefined}
                />
              </div>
            )}

            {qualification?.industries && (
              <div className={classes.inputGroup}>
                <QualificationAutocomplete
                  onChange={(value) => setIndustries(value as QualificationPartialInterface[])}
                  value={industries}
                  label="Industries"
                  multiple
                  options={qualification.industries}
                  placeholder={undefined}
                />
              </div>
            )}

            {qualification?.tools && (
              <div className={classes.inputGroup}>
                <QualificationAutocomplete
                  onChange={(value) => setTools(value as QualificationPartialInterface[])}
                  value={tools}
                  label="Tools"
                  multiple
                  options={qualification.tools}
                  placeholder={undefined}
                />
              </div>
            )}

            {qualification?.institutionTypes && (
              <div className={classes.inputGroup}>
                <QualificationAutocomplete
                  onChange={(value) => setInstitutionTypes(value as QualificationPartialInterface[])}
                  value={institutionTypes}
                  label="Institution types"
                  multiple
                  options={qualification.institutionTypes}
                  placeholder={undefined}
                />
              </div>
            )}

            {qualification?.businessAreas && (
              <div className={classes.inputGroup}>
                <QualificationAutocomplete
                  onChange={(value) => setBusinessAreas(value as QualificationPartialInterface[])}
                  value={businessAreas}
                  label="Business areas"
                  multiple
                  options={qualification.businessAreas}
                  placeholder={undefined}
                />
              </div>
            )}

            {qualification?.averageDealValue && (
              <div className={classes.inputGroup}>
                <QualificationAutocomplete
                  onChange={(value) => setAverageDealValue(value as QualificationPartialInterface[])}
                  value={averageDealValue}
                  label="Average deal value"
                  multiple
                  options={qualification.averageDealValue}
                  placeholder={undefined}
                />
              </div>
            )}

            {qualification?.storeTypes && (
              <div className={classes.inputGroup}>
                <QualificationAutocomplete
                  onChange={(value) => setStoreTypes(value as QualificationPartialInterface[])}
                  value={storeTypes}
                  label="Store types"
                  multiple
                  options={qualification.storeTypes}
                  placeholder={undefined}
                />
              </div>
            )}

            {qualification?.languages && (
              <div className={classes.inputGroup}>
                <QualificationAutocomplete
                  onChange={(value) => setLanguages(value as QualificationPartialInterface[])}
                  value={languages}
                  label="Languages"
                  multiple
                  options={qualification.languages}
                  placeholder={undefined}
                />
              </div>
            )}

            {qualification?.seniorityLevel && (
              <div className={classes.inputGroup}>
                <QualificationAutocomplete
                  onChange={(value) => setSeniorityLevel(value as QualificationPartialInterface[])}
                  value={seniorityLevel}
                  label="Seniority level"
                  multiple
                  options={qualification.seniorityLevel}
                  placeholder={undefined}
                />
              </div>
            )}
          </Grid>

          {_job?._id ? (
            <Grid item md={12}>
              <Box className={classes.inputGroup}>
                <Typography variant="h5">Video</Typography>

                <Box mt={4} display="flex">
                  <Typography>Video explaining this job, requirements, team, culture, benefits...</Typography>

                  <Box ml={2}>
                    <IconButton size="small" onClick={() => setVideo('')}>
                      <Delete fontSize="small" />
                    </IconButton>
                  </Box>
                </Box>
                <Grid container>
                  <Grid item md={12} style={{ display: 'none' }}>
                    <TextField name="video" fullWidth label="Job Video" value={video || ''} onChange={(e) => setVideo(e.target.value)} />
                  </Grid>
                  <Grid item md={12}>
                    {isVideoLoading && <LoadingSpinner />}
                    {video ? (
                      <Box my={1}>
                        <div className="player-wrapper">
                          <ReactPlayer className="react-player" url={video} loop controls width="100%" height="100%" />
                        </div>
                      </Box>
                    ) : undefined}
                  </Grid>
                </Grid>
                <input type="file" ref={videoInputRef} onChange={handleVideoFileInputChange} multiple={false} accept="video/mp4" />
              </Box>
            </Grid>
          ) : undefined}

          {/*<Grid item md={12}>*/}
          {/*  <Box className={classes.inputGroup}>*/}
          {/*    <Box mt={4}>*/}
          {/*      <Typography>Picture that is visible when link is shared (on Facebook for example)</Typography>*/}
          {/*    </Box>*/}
          {/*    <Grid container>*/}
          {/*      <Grid item md={10} style={{ display: 'none' }}>*/}
          {/*        <TextField*/}
          {/*          name="shareImage"*/}
          {/*          fullWidth*/}
          {/*          label="Company Share Image"*/}
          {/*          value={shareImage || ''}*/}
          {/*          onChange={(e) => setShareImage(e.target.value)}*/}
          {/*        />*/}
          {/*      </Grid>*/}
          {/*      <Grid item md={2}>*/}
          {/*        {shareImage && <img src={shareImage} alt="" style={{ width: '100%' }} />}*/}
          {/*      </Grid>*/}
          {/*    </Grid>*/}
          {/*    <input type="file" ref={shareImageInputRef} onChange={handleShareImageFileInputChange} multiple={false} accept="image/*" />*/}
          {/*  </Box>*/}
          {/*</Grid>*/}
        </Grid>
      </Box>
    </Container>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontWeight: 700,
    '& .MuiFormLabel-root:not(.MuiFormLabel-shrink)': {
      fontWeight: 700
    }
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18
    }
  },
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2)
  },
  checkboxesWrapper: {
    paddingTop: '11px',
    '& .MuiFormControlLabel-label': {
      color: theme.palette.grey['700']
    }
  },
  inputGroup: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1)
  },
  salaryExplanation: {
    display: 'block',
    marginTop: '0.5rem',
    color: theme.palette.grey['500']
  },
  hypen: {
    fontSize: '24px',
    color: theme.palette.grey['500']
  },
  mainActionButton: {
    marginLeft: theme.spacing(1)
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  },
  disabled: {
    opacity: 0.5
  },
  publishStatus: {
    padding: theme.spacing(0.5),
    textTransform: 'uppercase',
    fontSize: '16px',
    fontWeight: 500,
    marginLeft: theme.spacing(2),
    border: 'thin solid #dfdfdf',
    borderRadius: theme.spacing(0.5),
    verticalAlign: 'middle'
  },
  mediaInputGroup: {
    marginBottom: theme.spacing(4)
  },
  mediaInfoBlock: {
    color: theme.palette.grey['500']
  },
  removeMediaButton: {
    color: theme.palette.grey['500'],
    marginLeft: theme.spacing(2)
  }
}))
