import React from 'react'
import {
  Checkbox, makeStyles, TextField, Theme
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import {
  QualificationInterface,
  QualificationPartialInterface
} from '../../rescouted-interfaces/src/interfaces/Qualification.interface';

type Props<T = any> = {
  options: T[]
  value: T | T[] | undefined
  onChange: (value: T | T[]) => void
  onSelect?: () => void
  multiple: boolean
  label: string
  placeholder?: string
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

export const QualificationAutocomplete = ({
  options,
  value,
  onChange,
  multiple = false,
  label,
  placeholder,
  onSelect
}: Props<QualificationInterface | QualificationPartialInterface>) => {
  const classes = useStyles()

  return multiple ? (
    <Autocomplete
      multiple
      options={options.filter(Boolean)}
      disableCloseOnSelect
      getOptionLabel={(option: QualificationPartialInterface) => option.title}
      groupBy={(option: QualificationPartialInterface) => option?.group || undefined}
      getOptionSelected={(option: QualificationPartialInterface, value: QualificationPartialInterface) => option.key === value.key}
      renderOption={(option: QualificationPartialInterface, { selected }) => (
        <>
          <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
          {option.title}
        </>
      )}
      onChange={(event, value: any) => {
        if (value) {
          onChange(value)
        }
      }}
      value={value as any}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          label={label}
          placeholder={placeholder}
          fullWidth
          inputProps={{
            ...params.inputProps,
            autoComplete: 'disabled'
          }}
          autoComplete="disabled"
          name={`${label}-entry-field`}
        />
      )}
      autoComplete={false}
      clearOnEscape={false}
    />
  ) : (
    <Autocomplete
      options={options.filter(Boolean)}
      classes={{
        option: classes.option
      }}
      autoHighlight
      getOptionLabel={(option: QualificationPartialInterface) => option.title}
      renderOption={(option: QualificationPartialInterface) => <>{option.title}</>}
      getOptionSelected={(option: QualificationPartialInterface) => option.key === (value as any).key}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="standard"
          placeholder={placeholder}
          fullWidth
          inputProps={{
            ...params.inputProps,
            autoComplete: 'disabled'
          }}
          autoComplete="disabled"
          name={`${label}-entry-field`}
        />
      )}
      value={value}
      defaultValue={{ key: '', title: '' }}
      onChange={(event, value: any) => {
        if (Boolean(value) && Boolean(value.key)) {
          onChange(value)
        }
      }}
      autoComplete={false}
      clearOnEscape={false}
      onSelect={() => onSelect && onSelect()}
    />
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18
    }
  }
}))
