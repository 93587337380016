import React from 'react'
import { Box, makeStyles, Theme } from '@material-ui/core'
import { StarBorder as EmptyStarIcon, Star as StarIcon } from '@material-ui/icons'

interface Props {
  isStarred: boolean
  selectable?: boolean
  onSelect?: (val: boolean) => void
}

export const Starred: React.FC<Props> = ({ isStarred, onSelect, selectable }) => {
  const classes = useStyles()

  const handleClick = (v) => {
    if (selectable) {
      onSelect(v)
    }
  }

  return (
    <Box display="flex" flexDirection="row" alignContent="center" className={classes.root} onClick={() => onSelect(!isStarred)}>
      {!isStarred ? <EmptyStarIcon fontSize="small" /> : undefined}
      {isStarred ? <StarIcon color="primary" fontSize="small" /> : undefined}
    </Box>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    cursor: 'pointer'
  },
  body: {}
}))
