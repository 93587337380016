import * as React from 'react'
import { Box, makeStyles, Theme, Typography } from '@material-ui/core'

// eslint-disable-next-line import/no-extraneous-dependencies
import MDEditor from '@uiw/react-md-editor'
import * as editHelper from './editorImageUpload';
import { useCallback, useRef, useState } from 'react';

interface Props {
  value: string
  label: string
  keyId?: string
  height?: number
  onChange: (text: string) => void
  onChangeFile: (file: FileList) => void
}

export const RTEditorCustom = ({ label, value, onChange, onChangeFile, keyId, height = 600 }: Props) => {
  const inputRef = useRef(null);
  const textApiRef = useRef(null);
  const [isDrag, setIsDrag] = useState(false);

  const onSomethingChange = (value) => {
    onChange(value)
  }

  const inputImageHandler = useCallback(async (event) => {
    console.log({ event })
    if (event.target.files && event.target.files.length > 0) {
      onChangeFile(event.target.files)
      // await editHelper.onImageUpload(event.target.files[0], textApiRef.current);
    }
  }, []);

  // Drag and Drop
  const startDragHandler = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (event.type === 'dragenter') setIsDrag(true);
  };

  const dragHandler = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (event.type === 'dragenter' || event.type === 'dragover') {
      setIsDrag(true);
    } else if (event.type === 'dragleave') setIsDrag(false);
  };

  const dropHandler = useCallback(async (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDrag(false);
  }, []);

  return (
    <Box display="flex" flexDirection="column">
      {label && label !== '' ? <Typography variant="subtitle1">{label}</Typography> : undefined}

      <input
        ref={inputRef}
        style={{ visibility: 'hidden' }}
        className="hidden"
        type="file"
        accept=".jpg,.png,.jpeg,.jfif,.gif"
        name="avatar"
        onChange={inputImageHandler}
        multiple
      />
      <div data-color-mode="light" onDragEnter={startDragHandler} className="reactive">
        <MDEditor key={keyId} height={height} value={value} onChange={onSomethingChange} preview="edit" commands={editHelper.editChoice(inputRef, textApiRef)} />
        {isDrag && (
          <div
            className="absolute bottom-0 left-0 right-0 top-0 h-full w-full bg-red-400 bg-opacity-20 "
            onDrop={dropHandler}
            onDragEnter={dragHandler}
            onDragOver={dragHandler}
            onDragLeave={dragHandler}
          />
        )}
        {/*<MDEditor.Markdown source={value} style={{ whiteSpace: 'pre-wrap' }} />*/}
      </div>
    </Box>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: '2px'
  },
  editor: {
    marginTop: '21px',
    width: '100%',
    '& .ql-editor': {
      fontFamily: 'Roboto',
      fontSize: '16px',
      paddingBottom: theme.spacing(3)
    }
  }
}))
