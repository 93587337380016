import React, { useState } from 'react'
import { CompanyInterface } from '../../rescouted-interfaces/src/interfaces/company.interface'
import { Box, IconButton, List, ListItem, ListItemText, Typography } from '@material-ui/core'
import { ArrowDropUp as OpenIcon, ArrowDropDown as CloseIcon } from '@material-ui/icons'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Link, useHistory } from 'react-router-dom'
import { useUser } from '../../utils/auth/useUser'
import { useCompany } from '../../hooks/useCompany'

interface Props {}

export const UserNav: React.FC<Props> = () => {
  const classes = useStyles()

  const [isOpen, setIsOpen] = useState(false)
  const [isSwitchCompanyOpen, setIsSwitchCompanyOpen] = useState(false)

  const { user, logout } = useUser()

  const { company, companies, setActiveCompany } = useCompany()

  const history = useHistory()

  const handleCompanyClick = (c: CompanyInterface) => {
    setActiveCompany(c)
    setIsOpen(false)
    history.push('/')
  }

  return (
    <Box className={classes.root} pr={0}>
      <Box display="flex" justifyContent="center" pr={1}>
        <Box display="flex" flexDirection="column">
          <Box>
            <Typography align="right" style={{ fontWeight: 700, fontSize: '16px' }}>
              {user.name}
            </Typography>
          </Box>
          <Box>
            <Typography align="right" style={{ fontSize: '12px' }}>
              {!!company && company.name}
            </Typography>
          </Box>
        </Box>
        <Box mx={1} display="flex" alignItems="center">
          <IconButton color="inherit" size="small" onClick={() => setIsOpen(!isOpen)}>
            {!isOpen ? <CloseIcon /> : <OpenIcon />}
          </IconButton>
        </Box>
      </Box>
      <Box className={classes.dropdown} display={isOpen ? 'flex' : 'none'} flexDirection="column" boxShadow={3} borderRadius="0 0 0 15px">
        <List>
          <ListItem button component={Link} to="#" style={{ paddingBottom: '0px' }}>
            <Box className={classes.borderBottom}>
              <ListItemText primary="Profile" />
            </Box>
          </ListItem>
          <ListItem button onClick={() => setIsSwitchCompanyOpen(!isSwitchCompanyOpen)} style={{ paddingBottom: '0px' }}>
            <Box display="flex" alignItems="center" className={classes.borderBottom}>
              <ListItemText primary="Switch company" />
              {!isSwitchCompanyOpen ? <CloseIcon /> : <OpenIcon />}
            </Box>
          </ListItem>
          {isSwitchCompanyOpen && (
            <>
              <List className={classes.switchCompany}>
                {companies.length === 0
                  ? null
                  : companies.map((company) => (
                      <ListItem key={company._id} button onClick={() => handleCompanyClick(company)}>
                        <ListItemText primary={company.name} />
                      </ListItem>
                    ))}

                <ListItem
                  button
                  component={Link}
                  to="/company"
                  onClick={() => {
                    setIsOpen(false)
                    setIsSwitchCompanyOpen(false)
                  }}
                >
                  <ListItemText primary="[+] Create new company" />
                </ListItem>
              </List>
            </>
          )}
          {company == null ? null : (
            <>
              <ListItem button component={Link} to={`/company/${company._id}/team`} style={{ paddingBottom: '0px' }} onClick={() => setIsOpen(false)}>
                <Box className={classes.borderBottom}>
                  <ListItemText primary="Manage team" />
                </Box>
              </ListItem>
              <ListItem button component={Link} to={`/company/${company._id}`} style={{ paddingBottom: '0px' }} onClick={() => setIsOpen(false)}>
                <Box className={classes.borderBottom}>
                  <ListItemText primary="Company settings" />
                </Box>
              </ListItem>
            </>
          )}
          <ListItem button onClick={() => logout()}>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative'
  },
  dropdown: {
    width: '290px',
    position: 'absolute',
    backgroundColor: '#F4F6F8',
    color: '#637381',
    top: '52px',
    right: '0px'
  },
  borderBottom: {
    borderBottom: 'solid 2px #DFE3E8',
    paddingBottom: '4px',
    width: '100%'
  },
  switchCompany: {
    paddingTop: '0px',
    paddingBottom: '0px',
    backgroundColor: '#637381',
    color: '#F9FAFB'
  }
}))
