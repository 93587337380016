import React, { useEffect } from 'react'
import { LinearProgress, Box, Typography, withStyles, Button } from '@material-ui/core'
import { Link } from 'react-router-dom'

export const userJourneySteps = {
  'create-account': { index: 0, label: 'Create account', location: null },
  'register-company': { index: 1, label: 'Register company', location: '/company' },
  'create-job': { index: 2, label: 'Create a job', location: '/job' },
  'setup-interview': { index: 3, label: 'Setup interview', location: '/interview' },
  'publish-job': { index: 4, label: 'Publish job', location: "/job/should-be-last-job's-id-here" },
  'share-link': { index: 5, label: 'Share link', location: "/job/should-be-last-job's-id-here/share-instructions" }
} as const

export type UserJourneySteps = typeof userJourneySteps
export type UserJourneyStepsKeys = keyof UserJourneySteps
type UserJourneyStep = UserJourneySteps[keyof UserJourneySteps]

interface Props {
  currentStep: UserJourneyStepsKeys
}

const getProgressValue = (currentStepIndex: number, totalSteps: number) => {
  const progress = currentStepIndex / totalSteps
  return progress * 100
}

interface StepLabelProps {
  currentStepIndex: number
  step: UserJourneyStep
}

const StepLabel = ({ step, currentStepIndex }: StepLabelProps) => {
  const { label } = step

  const Label = withStyles((theme) => {
    let color: string = theme.palette.text.secondary
    let fontWeight = 500

    if (step.index < currentStepIndex) {
      color = theme.palette.primary.main
    }

    if (step.index === currentStepIndex) {
      color = theme.palette.success.main
      fontWeight = 600
    }

    return {
      root: {
        color,
        fontWeight
      }
    }
  })(Typography)

  return (
    <Button component={Link} to={step.location} disabled={step.index !== currentStepIndex}>
      <Label variant="h6">{label.toUpperCase()}</Label>
    </Button>
  )
}

export const UserJourney = ({ currentStep }: Props) => {
  const [currentStepIndex, setCurrentStepIndex] = React.useState(-1)

  // Delay setting setCurrentStepIndex(steps[currentStep].index) to allow the progress bar to animate.
  useEffect(() => {
    setTimeout(() => {
      setCurrentStepIndex(userJourneySteps[currentStep].index + 1)
    }, 600)
  }, [currentStep])

  return (
    <Box paddingTop={3}>
      <Box display="flex" justifyContent="space-around">
        {Object.keys(userJourneySteps).map((key) => (
          <StepLabel key={key} currentStepIndex={currentStepIndex} step={userJourneySteps[key]} />
        ))}
      </Box>
      <LinearProgress variant="determinate" value={getProgressValue(currentStepIndex, Object.keys(userJourneySteps).length)} color="primary" />
    </Box>
  )
}
