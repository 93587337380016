import React from 'react'
import { Box, makeStyles, Theme, Tooltip, Typography } from '@material-ui/core'
import sanitizeHtml from 'sanitize-html'
import clsx from 'clsx'
import { MailInterface } from '../../rescouted-interfaces/src/interfaces/mail.interface'
import moment from 'moment-timezone'
import { MailAttachmentInterface } from '../../rescouted-interfaces/src/interfaces/mailAttachment.interface'
import { UserInterface } from '../../rescouted-interfaces/src/interfaces/user.interface'

interface Props {
  mail: MailInterface
}

export const Mail: React.FC<Props> = ({ mail }) => {
  const classes = useStyles()

  const user = mail.user as unknown as UserInterface
  const authorName = user?._id ? user.name ?? user.email : ''

  return (
    <Box mt={3} className={clsx(classes.root)}>
      <Box display="flex">
        <Box flexGrow={1}>
          <Typography variant="subtitle2">{mail.subject}</Typography>
        </Box>
        <Box display="flex" ml={2} whiteSpace="nowrap">
          <Typography variant="body2">{authorName ?? 'Unknown'}</Typography>
          <Box ml={2}>
            <Box whiteSpace="nowrap">
              <Tooltip title={`${moment(mail.date).tz(moment.tz.guess()).format('yyyy-MM-DD hh:mm')}`}>
                <Typography align="left" variant="body2">
                  {moment(mail.date).tz(moment.tz.guess()).fromNow(true)}
                </Typography>
              </Tooltip>
            </Box>
          </Box>
        </Box>
      </Box>
      {mail.bodyHtml ? (
        <Box mt={2} className={classes.body}>
          <article
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(mail.bodyHtml.replace('\n', '<br/>'), {
                // allowedTags: ['b', 'i', 'em', 'strong', 'a', 'p', 'br', 'quot'],
                allowedAttributes: {
                  a: ['href', 'target']
                }
              })
            }}
          />
        </Box>
      ) : undefined}
      {mail.attachmentCount > 0 && (
        <Box>
          {mail.attachments?.map((attachment) => {
            const att = attachment as unknown as MailAttachmentInterface
            return (
              <Box key={att.path}>
                <a href={att.path} target="_blank" rel="noreferrer">
                  <Typography>
                    {att.name} ({att.size / 1024}kb)
                  </Typography>
                </a>
              </Box>
            )
          })}
        </Box>
      )}
    </Box>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    border: 'thin solid #cecece',
    padding: theme.spacing(2),
    backgroundColor: '#e1f7ff',
    boxShadow: '0px 5px 5px 0px rgba(0,0,0,0.14)'
  },
  systemRoot: {
    backgroundColor: '#eeeeee'
  },
  body: {
    '& blockquote': {
      opacity: 0.3
    }
  }
}))
