import * as React from 'react'
import { useParams } from 'react-router-dom'
import {
  Box,
  Button,
  List,
  ListItem,
  TextField,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Switch,
  makeStyles,
  Typography,
  IconButton
} from '@material-ui/core'
import { DragHandle as DragHandleIcon, Delete as DeleteIcon, Edit as EditIcon } from '@material-ui/icons'
import { useCallback, useEffect, useState } from 'react'
import { Container, Draggable } from 'react-smooth-dnd'
import axios from 'axios'
import arrayMove from 'array-move'
import ReactPlayer from 'react-player'
import { QuestionEdit } from '../Question/QuestionEdit.component'
import { QuestionUpdate } from '../Question/QuestionUpdate.component'
import { QuestionInterface } from '../../rescouted-interfaces/src/interfaces/question.interface'
import { QuestionsPicker } from '../QuestionsPicker/QuestionsPicker.component'

interface Props {}

export const JobEditQuestions: React.FC<Props> = () => {
  const classes = useStyles()

  const { jobId } = useParams<{ jobId?: string }>()

  const [questions, setQuestions] = useState<QuestionInterface[]>([])

  const [isQuestionPickerOpen, setIsQuestionPickerOpen] = useState(false)

  const [updatingQuestionId, setUpdatingQuestionId] = useState('')

  const fetchQuestions = useCallback(async () => {
    const response = await axios.get<QuestionInterface[]>(`/job/${jobId}/questions`)
    if (response && response.status === 200) {
      setQuestions(response.data)
    }
  }, [jobId])

  useEffect(() => {
    if (jobId) {
      void fetchQuestions()
    }
  }, [fetchQuestions, jobId])

  const onDrop = async ({ removedIndex, addedIndex }) => {
    setQuestions((items) => arrayMove(items, removedIndex, addedIndex))
    await axios.post<QuestionInterface>(`/job/${jobId}/questions/sort`, { removedIndex, addedIndex })
    await fetchQuestions()
  }

  const handleAddQuestion = async (question: QuestionInterface) => {
    await axios.post<QuestionInterface>(`/job/${jobId}/questions`, question)
    await fetchQuestions()
  }

  const handleUpdateQuestion = async (question: QuestionInterface, questionId: string) => {
    await axios.post<QuestionInterface>(`/job/${jobId}/questions/${questionId}`, question)
    await fetchQuestions()
  }

  const handleToggleEnabled = async (questionIndex: number, enabled: boolean) => {
    await axios.post<QuestionInterface>(`/job/${jobId}/questions/${questionIndex}/enabled`, { enabled })
    await fetchQuestions()
  }

  const handleDeleteClick = async (questionIndex: number) => {
    if (window.confirm('Are you sure you want to delete this question?')) {
      await axios.post<QuestionInterface>(`/job/${jobId}/questions/${questionIndex}/delete`, { questionIndex })
      await fetchQuestions()
    }
  }

  return (
    <Box>
      <List style={{ minHeight: 500 }}>
        {/* @ts-ignore */}
        <Container dragHandleSelector=".drag-handle" lockAxis="y" onDrop={onDrop}>
          {questions.map((question, i) => (
            // @ts-ignore
            <Draggable key={question.video}>
              <ListItem className={classes.root}>
                <Box pb={2} style={{ display: updatingQuestionId === question.id ? 'none' : 'block' }}>
                  <ListItemText primary={question.question} secondary={question.video} />
                  {question.video ? (
                    <div className="player-wrapper" style={{ maxWidth: 250 }}>
                      <ReactPlayer className="react-player" url={question.video} loop controls width="100%" height="100%" />
                    </div>
                  ) : undefined}
                  {question.what_youre_looking_for ? (
                    <ListItemText primary="Expected Answer" secondary={question.what_youre_looking_for} />
                  ) : undefined}
                </Box>
                <ListItemSecondaryAction style={{ display: updatingQuestionId === question.id ? 'none' : 'block' }}>
                  <Box display="flex" alignItems="center">
                    <Box mr={1}>
                      <IconButton onClick={() => setUpdatingQuestionId(question.id)}>
                        <EditIcon />
                      </IconButton>
                    </Box>
                    <Box mr={1}>
                      <IconButton onClick={() => handleDeleteClick(i)}>
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                    <Box mr={2}>
                      <Switch onChange={(event) => handleToggleEnabled(i, event.target.checked)} checked={question.enabled} />
                    </Box>
                    <ListItemIcon className="drag-handle">
                      <DragHandleIcon />
                    </ListItemIcon>
                  </Box>
                </ListItemSecondaryAction>

                {updatingQuestionId === question.id ? (
                  <QuestionUpdate onSubmit={handleUpdateQuestion} onCancel={() => setUpdatingQuestionId('')} currentQuestion={question} />
                ) : null}
              </ListItem>
            </Draggable>
          ))}
        </Container>
      </List>

      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography>Add new question</Typography>
        <Box>
          <Button onClick={() => setIsQuestionPickerOpen(true)} variant="outlined">
            Add predefined
          </Button>
          <QuestionsPicker
            isOpen={isQuestionPickerOpen}
            onClose={() => setIsQuestionPickerOpen(false)}
            onSelect={(video, question) => {
              void handleAddQuestion({
                question,
                video,
                enabled: true,
                what_youre_looking_for: ''
              })
              setIsQuestionPickerOpen(false)
            }}
          />
        </Box>
      </Box>

      <QuestionEdit onSubmit={handleAddQuestion} />
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    borderTop: 'thin solid #dedede'
  }
}))
