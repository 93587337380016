import React, { FC, useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Tooltip, Typography } from '@material-ui/core'
import { Mail as MailIcon } from '@material-ui/icons'
import { JobInterface } from '../../rescouted-interfaces/src/interfaces/job.interface'
import { ApplicationInterface } from '../../rescouted-interfaces/src/interfaces/application.interface'
import axios from 'axios'
import { useUser } from '../../utils/auth/useUser'
import { useCompany } from '../../hooks/useCompany'
import { RTEditorCustom } from '../../app/RTEditor/RTEditorCustom.component'

const initialMailText = (applicantFirstName: string, signatureFullName: string, signatureCompany: string) => `Hello ${applicantFirstName} 👋😊

...

Kind regards,
${signatureFullName}
${signatureCompany}
`

interface Props {
  application: ApplicationInterface
  job: JobInterface
  onMailSent: () => void
}

export const CandidateActionMailComponent: FC<Props> = ({ application, job, onMailSent }) => {
  const { user } = useUser()
  const { company } = useCompany()
  const [isMailOpen, setIsMailOpen] = useState(false)
  const [subject, setSubject] = useState(`Regarding job ${job.title}`)
  const [files, setFiles] = useState<FileList | null>(null)
  const [text, setText] = useState(initialMailText(application.firstName, user.name, company.name))

  const handleOpenMail = () => {
    setText(initialMailText(application.firstName, user.name, company.name))
    setIsMailOpen(true)
  }
  const handleCloseMail = () => setIsMailOpen(false)

  const handleClearMailClick = () => {
    setSubject('')
    setText('')
  }

  const handleSendEmailClick = async () => {
    const formData = new FormData()

    if (files != null) {
      for (let i = 0; i < files.length; i++) {
        formData.append('files', files[i])
      }
    }

    formData.append('subject', subject)
    formData.append('text', text)

    const response = await axios.post(`/job/${job._id}/application/${application._id}/mails/post`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    if (response && response.status === 200 && response.data) {
      handleCloseMail()
      handleClearMailClick()
      onMailSent()
    }
  }

  return (
    <>
      <Tooltip title={`Send email to ${application.firstName} ${application.lastName}`}>
        <Button onClick={handleOpenMail}>
          {/*<Badge badgeContent={4} color="error">*/}
          <MailIcon />
          {/*</Badge>*/}
        </Button>
      </Tooltip>

      <Dialog open={isMailOpen} onClose={handleCloseMail} aria-labelledby="form-dialog-title">
        <DialogTitle id="send-mail">
          Send email to {application.firstName} {application.lastName}
        </DialogTitle>
        <DialogContent>
          <TextField autoFocus margin="dense" id="to" label="To" variant="outlined" type="email" fullWidth disabled value={application.email} />
          <TextField
            autoFocus
            margin="dense"
            id="subject"
            label="Subject"
            variant="outlined"
            type="text"
            fullWidth
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          />
          {/* <TextField
            autoFocus
            margin="dense"
            id="text"
            label="Message"
            variant="outlined"
            type="text"
            fullWidth
            multiline
            rows={10}
            value={text}
            onChange={(e) => setText(e.target.value)}
          /> */}
          <RTEditorCustom value={text} onChange={(value) => setText(value)} onChangeFile={(value) => setFiles(value)} label="" />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMail} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSendEmailClick} color="primary" variant="contained">
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
