import * as React from 'react'
import { Box, makeStyles, Theme, FormLabel } from '@material-ui/core'

// eslint-disable-next-line import/no-extraneous-dependencies
import MDEditor from '@uiw/react-md-editor'

interface Props {
  value: string
  label: string
  keyId?: string
  height?: number
  onChange: (text: string) => void
}

export const RTEditor = ({ label, value, onChange, keyId, height = 600 }: Props) => {
  const classes = useStyles()

  return (
    <Box display="flex" flexDirection="column">
      {label && label !== '' ? <FormLabel className={classes.label}>{label}</FormLabel> : null}

      <div data-color-mode="light">
        <MDEditor key={keyId} height={height} value={value} onChange={onChange} preview="edit" />
        {/*<MDEditor.Markdown source={value} style={{ whiteSpace: 'pre-wrap' }} />*/}
      </div>
    </Box>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: '2px'
  },
  label: {
    fontSize: '0.75rem',
    paddingBottom: '0.5rem'
  },
  editor: {
    marginTop: '21px',
    width: '100%',
    '& .ql-editor': {
      fontFamily: 'Roboto',
      fontSize: '16px',
      paddingBottom: theme.spacing(3)
    }
  }
}))
