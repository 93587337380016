import React from 'react'
import { LoadingSpinner } from './LoadingSpinner.component'

export const LoadingOverlay = () => (
  <div
    style={{
      position: 'fixed',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      background: 'white',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <LoadingSpinner />
  </div>
)
