import React from 'react'
import { _qualificationProps, _resolveQualification, _resolveQualificationPartials } from '../../utils/resolveQualification'
import { Box, Chip, Typography } from '@material-ui/core'
import { Done as DoneIcon } from '@material-ui/icons'
import { ApplicationInterface } from '../../rescouted-interfaces/src/interfaces/application.interface'
import { JobInterface } from '../../rescouted-interfaces/src/interfaces/job.interface'
import { QualificationInterface } from '../../rescouted-interfaces/src/interfaces/Qualification.interface'
import _ from 'lodash'

interface Props {
  application: ApplicationInterface
  job: JobInterface
  qualifications: QualificationInterface[]
}

export const CandidateQualificationChips: React.FC<Props> = ({ application, job, qualifications }) => {
  const qualification = _resolveQualification(application.qualification, qualifications)

  console.log(qualification)

  const qualificationPropsIntersectionArr = Object.keys(qualification).filter((key) => _qualificationProps.includes(key))

  const QualificationPartials = ({
    application,
    qualification,
    prop
  }: {
    application: ApplicationInterface
    qualification: QualificationInterface
    prop: string
  }) => {
    const combinedProp = [...job[prop].split(','), ...application[prop].split(',')]
    const joinedProp = combinedProp.filter((item, index) => combinedProp.indexOf(item) === index)
    const partials = _resolveQualificationPartials(joinedProp.join(','), qualification, prop)

    return (
      <Box component="div" display="flex" alignItems="center" flexWrap="wrap" mb={1}>
        <Box pt={1}>
          <Typography variant="subtitle2">{_.startCase(prop)}</Typography>
        </Box>
        {partials?.map((q) => {
          const jobIncludesKey = job[prop].includes(q.key)
          const applicationIncludesKey = application[prop].includes(q.key)
          const isIncluded = jobIncludesKey && applicationIncludesKey
          const isExcluded = jobIncludesKey && !applicationIncludesKey
          const customStyle = isExcluded ? { backgroundColor: '#c12c2c', color: '#fff' } : {}

          return (
            <Box pl={1} mt={1} key={q.key}>
              <Chip
                label={q.title}
                color={isIncluded ? 'primary' : 'default'}
                clickable={false}
                deleteIcon={isIncluded ? <DoneIcon /> : undefined}
                style={customStyle}
              />
            </Box>
          )
        })}
      </Box>
    )
  }

  return (
    <Box>
      <Box display="flex" alignItems="center" flexWrap="wrap" mb={1}>
        <Box pr={1}>
          <Typography variant="subtitle2">Qualification</Typography>
        </Box>
        <Chip
          label={qualification.title}
          color={application.qualification === job.qualification ? 'primary' : 'default'}
          clickable={false}
          deleteIcon={application.qualification === job.qualification ? <DoneIcon /> : undefined}
        />
      </Box>
      {qualificationPropsIntersectionArr?.map((prop) => (
        <QualificationPartials key={prop} application={application} prop={prop} qualification={qualification} />
      ))}
    </Box>
  )
}
